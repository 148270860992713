import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { ApiClient } from '../../services/ApiClient';
import { Systematic } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import SystematicDetails from '../../components/systematics/detail/SystematicDetails';
import useErrorHandling from '../../utils/hooks/useErrorHandling';

function SystematicDetail() {
  const { systematicId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [httpError, setHttpError] = useState<any>(null);
  useErrorHandling(httpError, '/systematics')
  const [systematic, setSystematic] = useState<Systematic | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, userHasPermission, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Systematics, 'read');

      if (hasPermission) {
        systematicId && fetchSystematic(systematicId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [systematicId, permissionsLoaded]);

  const fetchSystematic = async (systematicId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/systematics/${systematicId}`);
      const settingResponse = response.data;
      setSystematic(settingResponse);
      setIsLoading(false);
    } catch (error) { 
      setIsLoading(false);
      setHttpError(error)
    }
  };

  const handleModalSubmit = () => {
    setSystematic(null)
    systematicId && fetchSystematic(systematicId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <SystematicDetails systematic={systematic} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></SystematicDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default SystematicDetail;
