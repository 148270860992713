import {
  memo,
  Fragment,
  useState,
  useEffect,
} from 'react';
import {
  Row,
  Col,
  Table,
  Form,
  Button,
} from 'react-bootstrap';
import {
  PermissionsEnum,
  PublicationStatus,
} from '../../utils/enum';

import Card from '../../components/bootstrap/card';
import { useSortableData } from '../../utils/hooks/useSortableData';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';
import SelectionActions from '../../components/products/SelectionActions';
import { useSelection } from '../../utils/hooks/useSelection';
import AddProductCertificationModal from '../../components/products/modal/AddEditProductCertificationModal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ComboButtonId } from '../../components/ComboButtonGroup';
import { ColumnMapping, FieldConfig, Product } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import SearchInput from '../../components/SearchInput';
import ColumnSelection from '../../components/ColumnSelection';
import ViewSelector from '../../components/ViewSelector';
import useResetUrlParams from '../../utils/hooks/useResetUrlParams';
import CertificationStatusDropdown from '../../components/CertificationStatusDropdown';
import PublicationStatusDropdown from '../../components/PublicationStatusDropdown';
import { defaultRenderHeader } from '../../components/table/utils';
import ResetFiltersButton from '../../components/ResetFilterButton';
import SaveCancelPopup from '../../components/table/SaveCancelPopup';
import { DefaultColumnRender } from '../../components/table/DefaultColumnRender';
import GenericDropdownFilter from '../../components/filter/GenericDropdownFilter';
import { productTypeColorMap, productTypeIconMap, statusIconMap, statusColorMap, statusCertificationProductsIconMap, statusCertificationProductsColorMap } from '../../components/filter/iconAndColorMappings';
import { fetchAndCombineFieldConfigs, getFieldConfigByResourceName } from '../../utils/utils';

export interface ProductFilters {
  type: number | null;
  statusCertification: number | null;
  status: number | null;
}

interface ProductResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  searchFilters: string[];
  list: Product[];
}

const Products = memo(() => {
  const { companyId = 'oc' } = useParams();
  const [products, setProducts] = useState<Product[]>([]);
  const [module, setModule] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [selectedSearchColumn, setSelectedSearchColumn] = useState<ComboButtonId | ''>('all');
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [lastQueryParams, setLastQueryParams] = useState<string>('');
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const { requestSort, sortConfig, setSortConfig, getSortCaret } = useSortableData(products, showPopup);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();
  const resetUrlParams = useResetUrlParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [pendingChanges, setPendingChanges] = useState<{ [key: string]: any }>({});
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const [showAddEditCertificationModal, setShowAddEditCertificationModal] = useState<boolean>(false);
  const [editableCell, setEditableCell] = useState<{ rowId: number | null; columnKey: string | null }>({
    rowId: null,
    columnKey: null,
  });
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<ProductFilters>({
    type: null,
    statusCertification: null,
    status: null,
  });
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const {
    selectedItems: selectedProducts,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(products);

  // Fetches products based on filters, sort, pagination, and search criteria.
  const fetchProducts = async () => {
    setProducts([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`

    if (selectedFilters?.statusCertification?.toString()) {
      queryParams += `&products.statusCertification=${selectedFilters.statusCertification}`;
    }
    if (selectedFilters.status?.toString()) {
      queryParams += `&products.status=${selectedFilters.status}`;
    }
    if (selectedFilters.type?.toString()) {
      queryParams += `&products.type=${selectedFilters.type}`;
    }
    if (limit.toString()) {
      queryParams += `&limit=${limit}`;
    }
    if (searchQuery) {
      queryParams += `&search=${encodeURIComponent(searchQuery)}`;
      if (selectedSearchColumn !== 'all') {
        queryParams += `&column=${encodeURIComponent(selectedSearchColumn)}`;
      }
    }

    if (sortConfig?.field) {
      queryParams += `&sort[field]=${encodeURIComponent(sortConfig.field)}`;
      queryParams += `&sort[type]=${sortConfig.type}`;
    }

    try {
      const response = await ApiClient.get(`/products${queryParams}`);
      setLastQueryParams(queryParams)
      const productResponse = response.data as ProductResponse;
      setTotalPages(productResponse.amountPages);
      setProducts(productResponse.list);
      setCurrentPage(productResponse.page);
      setLimit(productResponse.itemsPerPage);
      setTotalEntries(productResponse.amountAllItems);
      setAvailableFilter(productResponse.searchFilters);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };


  // Effect hook to load products and field configurations when permissions are loaded.
  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Products, 'read');
      if (hasPermission) {
        fetchProducts();
        if (!fieldConfigs || Object.keys(fieldConfigs).length === 0) {
          fetchAndCombineFieldConfigs(['products'], setFieldConfigs, setModule);
        }
      } else {
        navigate('/errors/error404');
      }
    }
  }, [selectedFilters, currentPage, searchQuery, selectedSearchColumn, selectedColumns, limit, sortConfig]);

  // Resets the search input field when triggered.
  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);

  // Handles click events on table cells for editing purposes.
  const handleCellClick = (rowId: number | null, columnKey: string | null) => {
    setEditableCell({ rowId, columnKey });
  };

  // Handles changes made to editable table cells.
  const handleFieldChange = (rowId: number, columnKey: string, value: any) => {
    setPendingChanges(prevChanges => ({
      ...prevChanges,
      [`${rowId}-${columnKey}`]: { rowId, columnKey, value },
    }));
    setShowPopup(true);
  };

  // Saves all pending changes to the server.
  const handleSaveChange = async () => {
    const changesToSubmit = Object.values(pendingChanges);
    try {
      const updatePromises = changesToSubmit.map(change => {
        if (typeof change.value === 'object' && change.value !== null) {
          const relation = change.columnKey.split('.')[0] // e.g. bdks
          return ApiClient.put(`/products/${change.rowId}`, { [`${relation}_id`]: change.value.value });
        } else {
          return ApiClient.put(`/products/${change.rowId}`, { [getFieldConfigByResourceName(fieldConfigs, change.columnKey)?.fieldName as string]: change.value });
        }
      });
      await Promise.all(updatePromises);
      setPendingChanges({});
      setEditableCell({ rowId: null, columnKey: null });
      await fetchProducts();
      showToast('Erfolgreich gespeichert', false);
      setShowPopup(false);
    } catch (error) {
      showToast('Fehler beim Speichern', true);
      console.error('Error saving the changes:', error);
    }
  };

  // Reverts a single pending change.
  const handleRevertChange = () => {
    const { rowId, columnKey } = editableCell;
    if (rowId === null || columnKey === null) return;

    const newPendingChanges = { ...pendingChanges };
    delete newPendingChanges[`${rowId}-${columnKey}`];

    setPendingChanges(newPendingChanges);
    setEditableCell({ rowId: null, columnKey: null });
    setShowPopup(Object.keys(newPendingChanges).length > 0);
  };

  // Reverts all pending changes.
  const handleRevertChanges = () => {
    setPendingChanges({});
    setEditableCell({ rowId: null, columnKey: null });
    setShowPopup(false);
  };

  // Handle search input change
  const handleSearch = (data: { query: string; filter?: string }) => {
    const { query, filter } = data;

    if (query) {
      setSearchQuery(query);
      setSelectedSearchColumn(filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  // Updates the certification or publication status of a product.
  const handleStatusUpdate = async (
    productId: number,
    statusKey: 'statusCertification' | 'status',
    newStatus: number | PublicationStatus
  ) => {
    try {
      await ApiClient.put(`/products/${productId}`, {
        [statusKey]: newStatus,
      });
      await fetchProducts();
      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      showToast('Fehler beim Speichern', true);
    }
  };

  // Resets all search filters and input fields to their default states.
  const resetSearch = () => {
    resetUrlParams();
    setSearchQuery('');
    setSelectedSearchColumn('all');
    setCurrentPage(1);
    setResetSearchInput(true);
  };

  // Navigates to the product edit page when a product is added/edited.
  const handleModalSubmit = (productsId: number) => {
    navigate(`/${companyId}/products/${productsId}`);
  };

  // Refetches products after an update action and optionally shows a notification.
  const handleProductsUpdateSubmit = (message?: string, isError?: boolean) => {
    fetchProducts();
    if (message) {
      showToast(message, isError);
    }
  };

  // Navigates to global product settings.
  const handleGlobalDataClick = async () => {
    const res = await ApiClient.get('/settings?ident=globalProductFields');
    const settingsId = res.data.list[0].id;
    navigate(`/${companyId}/settings/${settingsId}`);
  };

  // Handles view selection changes from the view selection component.
  const handleSelectionChange = (
    selectedColumns: string[],
    selectedFilters: any,
    selectedSortConfig: any,
    selectedLimit: number,
    selectedSearchTerm: string,
    selectedSearchColumn: string
  ) => {
    resetSearch()
    setSelectedColumns(selectedColumns);
    setSelectedFilters(selectedFilters);
    setSortConfig(selectedSortConfig);
    setLimit(selectedLimit);

    if (selectedSearchTerm || selectedSearchColumn) {
      setSearchQuery(selectedSearchTerm);
      setSelectedSearchColumn(selectedSearchColumn);
    }
  };

  // Column mapping for rendering table headers and body content dynamically.
  const columnMapping: { [key: string]: ColumnMapping<Product> } = {
    'products.title': {
      label: 'Titel',
      renderHeader: (key: any) => (
        <th
          key={key}
          className="sticky-col cursor-pointer"
          scope="col"
          title="Titel"
          onClick={() => requestSort('products.title')}
        >
          <div className="d-flex align-items-center position-relative table-cell-wrap max-w-100">
            <Form.Check
              disabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write') && !userHasPermissionByRight(PermissionsEnum.Products, 'delete')}
              className="me-3"
              type="checkbox"
              checked={isAllSelected}
              onChange={handleSelectAll}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            Titel <div className="position-absolute" style={{ right: 0 }}>{getSortCaret('products.title')}</div>
          </div>
        </th>
      ),

      render: (product: Product) => (
        <td key={product.id} className="sticky-col">
          <div className="d-flex align-items-center justify-content-start">
            <Form.Check
              disabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write') && !userHasPermissionByRight(PermissionsEnum.Products, 'delete')}
              className="me-3"
              type="checkbox"
              checked={selectedProducts[product.id] ?? false}
              onChange={() => { }}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectRow(product.id, e);
              }}
            />
            <Link
              to={`/${companyId}/products/${product.id}`}
              className="btn btn-link ps-0 text-start table-cell-wrap max-w-table-title"
              title={product.title ?? ''}
            >
              {product.title}
            </Link>
          </div>
        </td>
      ),
    },
    'products.statusCertification': {
      label: 'Zertifizierung Status',
      render: (product: Product) => (
        <td key={`${product.id}-statusCertification`}>
          <CertificationStatusDropdown
            statusCertification={product.statusCertification}
            handleCertificationStatusUpdate={(status: number) =>
              handleStatusUpdate(product.id, 'statusCertification', status)
            }
            statusEnum={getFieldConfigByResourceName(fieldConfigs, 'products.statusCertification')?.options ?? {}}
            iconMap={statusCertificationProductsIconMap}
            colorMap={statusCertificationProductsColorMap}
            isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write')}
          />
        </td>
      ),
    },
    'products.status': {
      label: 'Veröffentlichung Status',
      render: (product: Product) => (
        <td key={`${product.id}-status`}>
          <PublicationStatusDropdown
            status={product.status}
            handlePublicationStatusUpdate={(status: PublicationStatus) =>
              handleStatusUpdate(product.id, 'status', status)
            }
            isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write')}
            style={{}}
          />
        </td>
      ),
    },
  };
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>Produkte</h3>
        <div className="d-flex">
          <Button disabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write')} className="btn btn-soft-primary me-4" onClick={handleGlobalDataClick}>
            Globale Daten bearbeiten
          </Button>

          <Button disabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write')} variant="primary" onClick={() => setShowAddEditCertificationModal(true)}>
            Produkt hinzufügen
          </Button>
        </div>
      </div>
      <Card className="card-block card-stretch card-height">
        <Card.Body>
          <Row className="d-flex justify-content-between mb-3">
            <Col md={9}>
              {searchQuery &&
                <div className="d-flex align-items-baseline mb-3">
                  <h4 className="m-0">Suchergebnisse</h4>
                  <span className="ms-3 d-flex align-items-baseline">
                    <Button
                      className="m-0 p-0 fs-6"
                      variant="link"
                      onClick={resetSearch}
                    >
                      Suche beenden
                    </Button>
                  </span>
                </div>
              }
              <ViewSelector
                selectedSortConfig={sortConfig}
                selectedFilters={selectedFilters}
                selectedColumns={selectedColumns}
                selectedLimit={limit}
                selectedSearchColumn={selectedSearchColumn}
                selectedSearchTerm={searchQuery}
                entityType="products"
                onSelectionChange={handleSelectionChange}
              />
            </Col>
            <Col md={3}>
              <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} initialSearchTerm={searchQuery} initialSearchColumn={selectedSearchColumn} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-start align-items-center">
                <div className="d-flex custom-scrollbar-x horizontal-scroll">

                  {/* StatusCertification Filter */}
                  <GenericDropdownFilter
                    selectedFilter={selectedFilters.statusCertification ?? null}
                    handleFilterChange={(statusCertification) => {
                      setSelectedFilters(filters => ({ ...filters, statusCertification }));
                      setCurrentPage(1);
                    }}
                    filterEnum={getFieldConfigByResourceName(fieldConfigs, 'products.statusCertification')?.options ?? {}}
                    iconMap={statusCertificationProductsIconMap}
                    colorMap={statusCertificationProductsColorMap}
                    titlePlaceholder="Zertifizierung Status"
                  />


                  {/* Status Filter */}
                  <GenericDropdownFilter
                    selectedFilter={selectedFilters.status ?? null}
                    handleFilterChange={(status) => {
                      setSelectedFilters(filters => ({ ...filters, status }));
                      setCurrentPage(1);
                    }}
                    filterEnum={getFieldConfigByResourceName(fieldConfigs, 'products.status')?.options ?? {}}
                    iconMap={statusIconMap}
                    colorMap={statusColorMap}
                    titlePlaceholder="Veröffentlichung Status"
                  />

                  {/* Type (Product) Filter */}
                  <GenericDropdownFilter
                    selectedFilter={selectedFilters.type ?? null}
                    handleFilterChange={(type) => {
                      setSelectedFilters(filters => ({ ...filters, type }));
                      setCurrentPage(1);
                    }}
                    filterEnum={getFieldConfigByResourceName(fieldConfigs, 'products.type')?.options ?? {}}
                    iconMap={productTypeIconMap}
                    colorMap={productTypeColorMap}
                    titlePlaceholder="Produktart"
                  />
                </div>
                <div className="sticky-right-reset-filter">
                  <ResetFiltersButton filters={selectedFilters} setFilters={(newFilters: ProductFilters) => {
                    setSelectedFilters(newFilters);
                    setCurrentPage(1);
                  }} />
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {selectedCount > 0 && (
        <SelectionActions
          selectedProducts={selectedProducts}
          selectedCount={selectedCount}
          handleDeSelectAll={handleDeSelectAll}
          onSubmitSuccess={handleProductsUpdateSubmit}
          handleSelectAll={handleSelectAll}
          publicationStatus={selectedFilters.status}
          amountAllItems={totalEntries}
          fieldConfigs={fieldConfigs}
          queryParams={lastQueryParams}
        ></SelectionActions>
      )}

      <div id="scroll-container" style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm" style={{ overflow: 'auto' }}>
          <thead>
            <tr>
              {selectedColumns.map((columnKey) =>
                columnMapping[columnKey]?.renderHeader
                  ? columnMapping[columnKey].renderHeader!(columnKey)
                  : defaultRenderHeader(columnKey, fieldConfigs, requestSort, getSortCaret)
              )}

              <th className="cursor-pointer text-end align-top sticky-right bg-grey w-40-px" scope="col">
                <ColumnSelection
                  selectedColumns={selectedColumns}
                  onSelectionChange={columns => setSelectedColumns(columns)}
                  fieldConfigs={fieldConfigs}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={`skeleton-row-${index}`} columnCount={selectedColumns.length + 1} />
              ))
              : products.map((product) => (
                <tr key={product.id} className="bg-white">
                  {selectedColumns.map((columnKey) =>
                    columnMapping[columnKey]
                      ? columnMapping[columnKey].render(product)
                      : <DefaultColumnRender
                        key={`${product.id}-${columnKey}`}
                        item={product}
                        columnKey={columnKey}
                        editableCell={editableCell}
                        handleCellClick={handleCellClick}
                        handleFieldChange={handleFieldChange}
                        handleRevertChange={handleRevertChange}
                        fieldConfigs={fieldConfigs}
                        pendingChanges={pendingChanges}
                        activeTooltip={activeTooltip}
                        setActiveTooltip={setActiveTooltip}
                        module={module}
                      />
                  )}
                  <td className="sticky-right bg-white" key="unique"></td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && products.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Keine Produkte gefunden</p>
          </div>
        )}
      </div>

      {totalEntries > 0 && (
        <Row>
          <Col>
            <PaginationInfo
              currentPage={currentPage}
              limit={limit}
              totalEntries={totalEntries}
              onLimitChange={(size) => {
                setLimit(size);
                setCurrentPage(1);
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <DynamicPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}

      {showAddEditCertificationModal && (
        <AddProductCertificationModal
          modalTitle="Zertifizierung hinzufügen"
          onSubmitSuccess={handleModalSubmit}
          onModalClose={() => setShowAddEditCertificationModal(false)}
        ></AddProductCertificationModal>
      )}

      <NotificationToast show={show} onClose={hideToast} message={message} error={error} />

      {showPopup && (
        <SaveCancelPopup
          onSave={handleSaveChange}
          onAbort={handleRevertChanges}
          pendingChangesCount={Object.keys(pendingChanges).length}
        />
      )}
    </Fragment>
  );
});

export default Products;
