import React from 'react';
import { Accordion } from 'react-bootstrap';

export interface ContentItem {
  id: string;
  ue?: string;
  children?: ContentItem[];
}

interface NestedAccordionProps {
  contentItem: ContentItem;
  eventKeyPrefix?: string;
}

const NestedAccordion: React.FC<NestedAccordionProps> = ({
  contentItem,
  eventKeyPrefix = '',
}) => {
  const renderNestedAccordions = (item: ContentItem, prefix: string) => {
    const isTopLevel = prefix === eventKeyPrefix;
    const hasChildren = item.children && item.children.length > 0;
    if (hasChildren) {
      return (
        <Accordion.Item eventKey={`${prefix}0`} key={prefix} className='mt-3'>
          <Accordion.Header>
            {item.id}{' '}
            {!isTopLevel && <>{item.ue ? `(${item.ue} UE)` : '(- UE)'}</>}
          </Accordion.Header>
          <Accordion.Body>
            <Accordion>
              {item.children?.map((child, index) =>
                renderNestedAccordions(child, `${prefix}${index}-`)
              )}
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
      );
    } else {
      return (
        <h2 key={prefix} className="accordion-header mt-3">
          <div className="accordion-button no-arrow">
            {item.id}{' '}
            {!isTopLevel && <>{item.ue ? `(${item.ue} UE)` : '(- UE)'}</>}
          </div>
        </h2>
      );
    }
  };

  return (
    <Accordion defaultActiveKey="">
      {renderNestedAccordions(contentItem, eventKeyPrefix)}
    </Accordion>
  );
};

export default NestedAccordion;
