import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import SelectGroup from '../../form/SelectGroup';
import FormGroup from '../../form/FormGroup';
import { formatDate, formatEuro } from '../../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { RentIncrease } from '../../../interfaces';
import ConfirmationModal from '../../ConfirmationModal';

type RentIncreaseListProps = {
    initialRentIncreases: RentIncrease[];
    onRentIncreasesUpdate: (rentIncreases: RentIncrease[]) => void;
};

/**
 * RentIncreaseList Component
 * 
 * This component manages a list of rent increase entries for a property. Each entry can either be a
 * recurring or a one-time increase, and users can add, update, or remove entries. The component
 * supports both percentage-based and amount-based increases, with conditional display and input
 * restrictions to ensure only one type (percentage or amount) can be entered at a time.
 * 
 * Props:
 * - `initialRentIncreases`: An array of rent increase objects used to initialize the component state.
 * - `onRentIncreasesUpdate`: Callback function to pass the updated list of rent increases to the parent component.
 */
const RentIncreaseList: React.FC<RentIncreaseListProps> = ({ initialRentIncreases, onRentIncreasesUpdate }) => {
    const [rentIncreases, setRentIncreases] = useState<RentIncrease[]>(initialRentIncreases);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

    useEffect(() => {
        onRentIncreasesUpdate(rentIncreases);
    }, [rentIncreases, onRentIncreasesUpdate]);

    const addRentIncrease = () => {
        setRentIncreases([...rentIncreases, { type: 'recurring' }]);
    };

    const confirmDelete = (index: number) => {
        setDeleteIndex(index);
        setShowConfirmationModal(true);
    };

    const handleConfirmDelete = () => {
        if (deleteIndex !== null) {
            setRentIncreases(rentIncreases.filter((_, i) => i !== deleteIndex));
            setDeleteIndex(null);
            setShowConfirmationModal(false);
        }
    };

    const handleCloseModal = () => {
        setDeleteIndex(null);
        setShowConfirmationModal(false);
    };

    const updateRentIncrease = (index: number, field: string, value: any) => {
        const updatedIncreases = [...rentIncreases];
        updatedIncreases[index] = { ...updatedIncreases[index], [field]: value };
        setRentIncreases(updatedIncreases);
    };

    const renderHeader = (increase: RentIncrease) => {
        if (increase.type === 'onetime' && increase.oneTimePaymentDate) {
            // Display for one-time increase
            return (
                <h5>
                    {increase.percentage ? `${increase.percentage} %` : `${formatEuro(increase.amount ?? 0)}`} Steigerung
                    <span className="text-muted"> einmalig am {formatDate(increase.oneTimePaymentDate, 'd.m.Y')}</span>
                </h5>
            );
        } else if (increase.type === 'recurring' && increase.startDate && (increase.percentage || increase.amount)) {
            // Display for recurring increase
            const intervalText = increase.interval ? `alle ${increase.interval} Monate` : 'jährlich';
            return (
                <h5>
                    {increase.percentage ? `${increase.percentage} %` : `${formatEuro(increase.amount ?? 0)}`} Steigerung
                    <span className="text-muted"> {intervalText} ab dem {formatDate(increase.startDate, 'd.m.Y')}</span>
                </h5>
            );
        }
        return null;
    };

    return (
        <>
            {rentIncreases.length === 0 ? (
                <h5>Keine Mietsteigerung</h5>
            ) : (
                rentIncreases.map((increase, index) => (
                    <Card key={index} className="border rounded mb-3 shadow-none">
                        <Card.Header className='d-flex justify-content-between'>
                            <div>
                                {renderHeader(increase)}
                            </div>
                            <Button onClick={() => confirmDelete(index)} className='round-button btn btn-soft-primary'>
                                <FontAwesomeIcon fontSize={17} className='cursor-pointer text-primary' icon={faTrash} />
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <SelectGroup<RentIncrease>
                                        id={`type`}
                                        label="Art"
                                        options={[
                                            { value: 'recurring', label: 'Wiederholend' },
                                            { value: 'onetime', label: 'Einmalig' },
                                        ]}
                                        value={increase.type}
                                        onChange={(e) => updateRentIncrease(index, 'type', e.target.value)}
                                    />
                                </Col>

                                {increase.type === 'recurring' && <>
                                    <Col md={6}>
                                        <FormGroup<RentIncrease>
                                            id={`interval`}
                                            label="Intervall (in Monaten)"
                                            value={increase.interval?.toString() || ''}
                                            onChange={(e) => updateRentIncrease(index, 'interval', parseInt(e.target.value, 10))}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup<RentIncrease>
                                            id={`startDate`}
                                            label="Startzeitpunkt"
                                            type='date'
                                            value={increase.startDate || ''}
                                            onChange={(e) => updateRentIncrease(index, 'startDate', e.target.value)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup<RentIncrease>
                                            id={`endDate`}
                                            label="Endzeitpunkt"
                                            type='date'
                                            value={increase.endDate || ''}
                                            onChange={(e) => updateRentIncrease(index, 'endDate', e.target.value)}
                                        />
                                    </Col>
                                </>}

                                {increase.type === 'onetime' && (
                                    <Col md={6}>
                                        <FormGroup<RentIncrease>
                                            id={`oneTimePaymentDate`}
                                            label="Zeitpunkt"
                                            type='date'
                                            value={increase.oneTimePaymentDate || ''}
                                            onChange={(e) => updateRentIncrease(index, 'oneTimePaymentDate', e.target.value)}
                                        />
                                    </Col>
                                )}

                                <Col md={6}>
                                    <FormGroup<RentIncrease>
                                        id={`percentage`}
                                        label="Anstieg"
                                        infoLabel='(in Prozent)'
                                        type='number'
                                        disabled={!!increase.amount}
                                        value={increase.percentage}
                                        onChange={(e) => updateRentIncrease(index, 'percentage', e.target.value)}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormGroup<RentIncrease>
                                        id={`amount`}
                                        label="Anstieg"
                                        infoLabel='(in Euro)'
                                        type='number'
                                        disabled={!!increase.percentage}
                                        value={increase.amount}
                                        onChange={(e) => updateRentIncrease(index, 'amount', e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                ))
            )}
            <Button className='btn btn-soft-primary me-4' onClick={addRentIncrease}>
                Mietsteigerung hinzufügen
            </Button>

            <ConfirmationModal
                show={showConfirmationModal}
                handleClose={handleCloseModal}
                handleConfirm={handleConfirmDelete}
                title="Mietsteigerung löschen?"
                message="Bist du dir sicher, dass du die Mietsteigerung löschen willst?"
            />
            {showConfirmationModal && <div className="custom-backdrop"></div>}
        </>
    );
};

export default RentIncreaseList;
