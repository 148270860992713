import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { ApiClient } from '../../services/ApiClient';
import { Webhook } from '../../interfaces';
import { usePermissions } from '../../utils/hooks/usePermissions';
import WebhookDetails from '../../components/webhooks/detail/WebhookDetails';
import useErrorHandling from '../../utils/hooks/useErrorHandling';

function WebhookDetail() {
  const { webhookId } = useParams();
  const [webhook, setWebhook] = useState<Webhook | null>();
  const [httpError, setHttpError] = useState<any>(null);
  useErrorHandling(httpError, '/webhooks')
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Webhooks, 'read');

      if (hasPermission) {
        webhookId && fetchWebhook(webhookId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [webhookId, permissionsLoaded]);

  const fetchWebhook = async (webhookId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/webhooks/${webhookId}`);
      const settingResponse = response.data;
      setWebhook(settingResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setHttpError(error)
    }
  };

  const handleModalSubmit = () => {
    setWebhook(null)
    webhookId && fetchWebhook(webhookId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <WebhookDetails webhook={webhook} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></WebhookDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default WebhookDetail;
