import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import KeywordDetails from '../../components/keywords/detail/KeywordDetails';
import { Keyword } from '../../interfaces';
import { mockKeywords } from './keywords';
import useErrorHandling from '../../utils/hooks/useErrorHandling';

function KeywordDetail() {
  const { keywordId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [httpError, setHttpError] = useState<any>(null);
  useErrorHandling(httpError, '/keywords')
  const [keyword, setKeyword] = useState<Keyword>();
  const [isLoading, setIsLoading] = useState(false);

  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Keywords, 'read');

      if (hasPermission) {
        keywordId && fetchKeyword(keywordId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [keywordId, permissionsLoaded]);

  const fetchKeyword = async (keywordId: string) => {
    setIsLoading(true);
    try {
      // const response = await ApiClient.get(`/keywords/${keywordId}`);
      // const keywordResponse = response.data;
      setKeyword(mockKeywords[0]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false)
      setHttpError(error)
     }
  };

  const handleModalSubmit = () => {
    setKeyword(undefined)
    keywordId && fetchKeyword(keywordId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <KeywordDetails keyword={keyword} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></KeywordDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default KeywordDetail;
