import React, { useState } from 'react';
import { ListGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faLink } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';

import { Location } from '../../../interfaces';
import StatusIndicator from '../../StatusIndicator';
import { formatDateWithoutTime } from '../../../utils/utils';

interface CertificateDetailsProps {
    location: Location;
    companyId: string;
    handleDelete: (mediaId: number) => void;
}

/**
 * CertificateDetails Component
 * 
 * This component displays details of a certificate associated with a location, including a clickable area
 * to navigate to the certificate page, a status indicator, and a dropdown menu with additional actions.
 * 
 * Props:
 * - `location`: The `Location` object containing certificate details (if assigned).
 * - `companyId`: The ID of the company, used to construct the certificate navigation link.
 * - `handleDelete`: A callback function to handle the deletion (or removal) of the certificate link, 
 *   taking the certificate's ID as an argument.
 */

const CertificateDetails: React.FC<CertificateDetailsProps> = ({ location, companyId, handleDelete }) => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleNavigateToCertificate = () => {
        navigate(`/${companyId}/certificates/${location.certificate_id}`);
    };

    const toggleDropdown = (isOpen: boolean) => {
        setIsDropdownOpen(isOpen);
    };

    return (
        !location?.certificate ?
            <div className="d-flex justify-content-center align-items-center border rounded py-3">
                <p className="p-0 m-0">Keine Zertifikat zugewiesen</p>
            </div>
            : (
                <ListGroup>
                    <ListGroup.Item
                        className="d-flex justify-content-between align-items-center mb-3 border rounded certificate-item media-item"
                        onMouseLeave={() => toggleDropdown(false)}
                    >
                        <div className="p-1 flex-grow-1 cursor-pointer" onClick={handleNavigateToCertificate}>
                            <div className="mb-1">Zertifikat Nr. {location.certificate.number}</div>
                            <div>
                                <StatusIndicator isActive={true} activeText="Gültig" inactiveText="Ungültig" />
                                <span className="text-muted">
                                    {` · ${formatDateWithoutTime(location.certificate.approvalPeriodStart)} - ${formatDateWithoutTime(
                                        location.certificate.approvalPeriodStart
                                    )}`}
                                </span>
                            </div>
                        </div>
                        <Dropdown align="end" className="ms-auto" show={isDropdownOpen} onToggle={toggleDropdown}>
                            <Dropdown.Toggle
                                as="span"
                                bsPrefix="btn"
                                className="hover-button no-border"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleDropdown(!isDropdownOpen);
                                }}
                            >
                                <FontAwesomeIcon className="text-primary" icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => { e.stopPropagation(); toggleDropdown(!isDropdownOpen); handleDelete(location.certificate.id); }}>
                                    <FontAwesomeIcon className="text-primary" width={30} icon={faLink} />Verlinkung entfernen
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ListGroup.Item>
                </ListGroup>
            )
    );
};

export default CertificateDetails;
