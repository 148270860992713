import {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Row, Col, Table, Form, Button, } from 'react-bootstrap';
import {
  getEnumValue,
  PermissionsEnum,
  YesNoMapping,
} from '../../utils/enum';

import { useSortableData } from '../../utils/hooks/useSortableData';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Task, } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import Card from '../../components/bootstrap/card';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import { useSelection } from '../../utils/hooks/useSelection';
import SearchInput from '../../components/SearchInput';
import AddEditTaskModal from '../../components/tasks/modal/AddEditTaskModal';
import useResetUrlParams from '../../utils/hooks/useResetUrlParams';
import { getTaskStatusIcon } from '../../utils/tasks/utils';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';

interface TasksResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: Task[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];

const Tasks = memo(() => {
  const navigate = useNavigate();
  const resetUrlParams = useResetUrlParams();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();

  // State management for tasks and UI controls
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [lastQueryParams, setLastQueryParams] = useState<string>('');
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const { items: sortedTasks, requestSort } = useSortableData(tasks, false, { field: 'className', type: 'asc' });
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const getId = useCallback((task: Task) => task.id, []);

  // Selection management for tasks
  const {
    selectedItems: selectedTasks,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(tasks, getId);

  // Handle search functionality
  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchFilter(data.filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  // Fetch tasks from the API based on current state
  const fetchTasks = async () => {
    setTasks([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&status=${selectedCombo}`;
    } else {
      if (limit.toString()) {
        queryParams += `&limit=${limit}`;
      }
      if (searchQuery) {
        queryParams += `&search=${encodeURIComponent(searchQuery)}`;
        if (selectedSearchFilter !== 'all') {
          queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`;
        }
      }
    }

    try {
      const response = await ApiClient.get(`/tasks${queryParams}`);
      setLastQueryParams(queryParams)
      const tasksResponse = response.data as TasksResponse
      setTotalPages(tasksResponse.amountPages);
      setCurrentPage(tasksResponse.page);
      setLimit(tasksResponse.itemsPerPage);
      setTotalEntries(tasksResponse.amountAllItems);
      setAvailableFilter(tasksResponse.searchFilters);
      setTasks(tasksResponse.list);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* 
 * Fetch tasks if permissions are loaded and user has the necessary rights.
 * Redirect to a 404 page if permissions are not sufficient.
 */
  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Tasks, 'read');

      if (hasPermission) {
        fetchTasks();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    searchQuery,
    selectedCombo,
    permissionsLoaded,
    limit
  ]);

  // Re-fetch tasks after a successful update or creation
  const handleTaskUpdateSubmit = () => {
    fetchTasks();
  };

  // Reset the search input and related states
  const resetSearch = () => {
    resetUrlParams()
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  // Clear the reset search input flag once it's processed
  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>CronTask</h3>
        <Button disabled={!userHasPermissionByRight(PermissionsEnum.Tasks, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
          CronTask hinzufügen
        </Button>
      </div>
      <Card className="card-block card-stretch card-height">
        <Card.Body>
          <Row className="d-flex justify-content-between mb-4">
            <Col md={6}>
              {searchQuery ?
                <div className="d-flex align-items-baseline">
                  <h4 className="m-0">Suchergebnisse</h4>
                  <span className="ms-3 d-flex align-items-baseline">
                    <Button
                      className="m-0 p-0 fs-6"
                      variant="link"
                      onClick={resetSearch}
                    >
                      Suche beenden
                    </Button>
                  </span>
                </div>

                : <ComboButtonGroup
                  buttons={comboButtons}
                  selectedCombo={selectedCombo}
                  setSelectedCombo={setSelectedCombo}
                  borderRadius="normal"
                ></ComboButtonGroup>}
            </Col>
            <Col md={3}>
              <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex align-items-center cursor-pointer"
                scope="col"
                onClick={() => requestSort('className')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.Tasks, 'write') && !userHasPermissionByRight(PermissionsEnum.Tasks, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                PHP Klassenname
              </th>

              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('crontab')}
              >
                Crontab
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('isActive')}
              >
                Aktiv
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('runOnlyOnce')}
              >
                Nur einmal ausführen?
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <GeneralSelectionActions
                selectedItems={selectedTasks}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                handleSelectAll={handleSelectAll}
                onSubmitSuccess={handleTaskUpdateSubmit}
                amountAllItems={totalEntries}
                entityType='tasks'
                queryParams={lastQueryParams}
              ></GeneralSelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedTasks.map((task) => (
                <tr key={task.id} className="bg-white">
                  <td className="d-flex align-items-center">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.Tasks, 'write') && !userHasPermissionByRight(PermissionsEnum.Tasks, 'delete')}
                      className="me-3"
                      type="checkbox"
                      checked={
                        selectedTasks[task.id] ?? false
                      }
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(task.id, e)
                      }}
                    />
                    <Link
                      to={`/${companyId}/tasks/${task.id}`}
                      className="btn btn-link ps-0 text-start text-break flex-grow-1"
                    >
                      {task.className}
                    </Link>
                  </td>

                  <td>
                    {task.crontab}
                  </td>

                  <td className='text-center'>
                    {getTaskStatusIcon(task.isActive)}
                  </td>

                  <td>
                    {getEnumValue(YesNoMapping, task.runOnlyOnce)}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && sortedTasks.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Keine CronTasks gefunden</p>
          </div>
        )}
      </div>

      {totalEntries > 0 && (
        <Row>
          <Col>
            <PaginationInfo
              currentPage={currentPage}
              limit={limit}
              totalEntries={totalEntries}
              onLimitChange={(size) => {
                setLimit(size);
                setCurrentPage(1);
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <DynamicPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}

      {showAddEditModal && <AddEditTaskModal
        modalTitle={`CronTask hinzufügen`}
        onSubmitSuccess={handleTaskUpdateSubmit}
        onModalClose={() => setShowAddEditModal(false)}
      >
      </AddEditTaskModal>}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

export default Tasks;
