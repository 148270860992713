import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { faEnvelope, faPhone, faCopy } from '@fortawesome/pro-regular-svg-icons';
import InfoDisplay, { RowConfig } from '../../InfoDisplay';
import { FieldConfig, Location } from '../../../interfaces';
import SkeletonRows from '../../SkeletonRows';
import { formatDateWithoutTime, getFieldConfigByResourceName, } from '../../../utils/utils';
import { getEnumValue, PermissionsEnum } from '../../../utils/enum';
import StatusIndicator from '../../StatusIndicator';
import { usePermissions } from '../../../utils/hooks/usePermissions';

type LocationContractProps = {
    location?: Location;
    fieldConfigs: FieldConfig[]
};

/**
 * LocationContract Component
 * 
 * Displays a detailed view of a location’s contractual and contact information, using `InfoDisplay`
 * to organize details within specific categories. This includes contractual status, rent duration, 
 * termination conditions, and landlord contact information. If data is unavailable or loading, 
 * `SkeletonRows` placeholders are shown to maintain layout consistency.
 * 
 * Props:
 * - `location`: Optional `Location` object with details such as rental status, contract dates, and contact info.
 * - `fieldConfigs`: Array of `FieldConfig` objects for dynamically rendering fields.
 * 
 * Permissions:
 * - Restricts viewing of the contact person details based on `LocationsContactPerson` read permission.
 * 
 * Example usage:
 * ```tsx
 * <LocationContract location={locationData} fieldConfigs={fieldConfigs} />
 * ```
 */
const LocationContract: React.FC<LocationContractProps> = ({ location, fieldConfigs }) => {
    const { userHasPermissionByRight } = usePermissions();

    const canViewContactPerson = userHasPermissionByRight(PermissionsEnum.LocationsContactPerson, 'read');

    const contractualInfo: RowConfig[] = [
        { label: "Miet-Status", value: <StatusIndicator isActive={!!location?.contractRentalStatus}></StatusIndicator> },
        { label: "Autom. Mietverläng.", value: <StatusIndicator isActive={!!location?.contractAutoRenewal}></StatusIndicator> },
        { label: "Mietbeginn", value: location?.contractStart && location?.contractStart !== '0000-00-00 00:00:00' ? formatDateWithoutTime(location?.contractStart) : '-' },
        { label: "Mindestmietzeit", value: location?.contractMinRentalPeriod ? `${location?.contractMinRentalPeriod} Monate` : '-' },
        { label: "Schlüssel", value: getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'locations.keyType')?.options ?? {}, location?.keyType.toString()) },
        { label: "E-Mail (Vermieter)", value: location?.landlordEmail || '-', buttons: [{ type: 'mailto', icon: faEnvelope }, { type: 'copy', icon: faCopy }] },
    ];

    const terminationInfo: RowConfig[] = [
        { label: "Kündigungsfrist", value: location?.contractNoticePeriod ? `${location?.contractNoticePeriod} Monate` : '-' },
        { label: "Datum der Kündigung", value: location?.contractDateOfTermination && location?.contractDateOfTermination !== '0000-00-00 00:00:00' ? formatDateWithoutTime(location?.contractDateOfTermination) : '-' },
        { label: "Vertragsende", value: location?.contractEnd && location?.contractEnd !== '0000-00-00 00:00:00' ? formatDateWithoutTime(location?.contractEnd) : '-' },
    ];

    const contactInfo: RowConfig[] = [
        { label: "Name", value: location?.landlordContactPerson || '-', },
        { label: "E-Mail-Adresse", value: location?.email || '-', buttons: [{ type: 'mailto', icon: faEnvelope }, { type: 'copy', icon: faCopy }] },
        { label: "Festnetz Telefonnr.", value: location?.telephone || '-', buttons: [{ type: 'call', icon: faPhone }, { type: 'copy', icon: faCopy }] },
        { label: "Fax", value: location?.fax || '-', buttons: [{ type: 'copy', icon: faCopy }] },
        { label: "Mobile Telefonnr.", value: location?.mobile || '-', buttons: [{ type: 'call', icon: faPhone }, { type: 'copy', icon: faCopy }] },
    ];

    return (
        <Row>
            <Col md={6} >
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Allgemein' config={contractualInfo} />
                        )}
                    </Card.Body>
                </Card>
            </Col>

            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Kündigung' config={terminationInfo} />
                        )}
                    </Card.Body>
                </Card>
            </Col>

            {canViewContactPerson && <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Ansprechpartner' config={contactInfo} />
                        )}
                    </Card.Body>
                </Card>
            </Col>}
        </Row>
    );
};

export default LocationContract;
