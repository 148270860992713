import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import GenericInfosCard from '../products/GenericInfosCard';
import { MeinNowAccounts, Setting } from '../../interfaces';
import SkeletonCard from '../locations/skeleton/SkeletonCard';
import Card from '../bootstrap/card';
import MeinNowAccountsModal from './modal/MeinNowAccountsModal';
import { PermissionsEnum } from '../../utils/enum';
import { usePermissions } from '../../utils/hooks/usePermissions';

interface MeinNowDetailsProps {
    isLoading?: boolean;
    setting?: Setting | null
    onSubmitSuccess: (settingsId: number) => void;
}

const MeinNowDetails: React.FC<MeinNowDetailsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const meinNowAccounts = setting?.details as MeinNowAccounts

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
        return info1;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.Settings, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Allgemein"
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    <Card className={`card-block card-stretch card-height`}>
                        <Card.Body>
                            <h5>Accounts</h5>
                            <ul className='list-no-dots'>
                                {meinNowAccounts && Object.keys(meinNowAccounts).length > 0
                                    ? Object.entries(meinNowAccounts).map(([key, value]) => (
                                        <li key={key}>{value}</li>
                                    ))
                                    : <li>Keine Accounts vorhanden</li>}
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {setting && showAddEditModal && <MeinNowAccountsModal
                modalTitle={`${setting.title} bearbeiten`}
                setting={setting}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            >
            </MeinNowAccountsModal>
            }
        </>
    );
};

export default MeinNowDetails;
