import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

interface SwitchGroupProps {
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
    options?: [string, string];
    disabled?: boolean;
}

// SwitchGroup is a toggle component that displays two options as buttons
// (defaulted to "Ja" and "Nein"). It allows users to switch between
// the two options, updating the parent component through the onChange callback.
// Props:
// - label: The text label displayed above the button group.
// - value: A boolean indicating the currently selected option.
// - onChange: A function called with the new value when an option is selected.
// - options: An optional array to customize the text of the two options.
// - disabled: An optional boolean to disable the buttons.
const SwitchGroup: React.FC<SwitchGroupProps> = ({
    label,
    value,
    onChange,
    options = ["Ja", "Nein"],
    disabled = false,
}) => {
    return (
        <div className="mb-3 w-100">
            <label className="form-label text-black">{label}</label>
            <ButtonGroup className="d-flex bg-grey p-1" aria-label={label}>
                <Button
                    variant={'light'}
                    className={`switch-option no-border ${value ? "active bg-white border" : ""}`}
                    onClick={() => onChange(true)}
                    disabled={disabled}
                >
                    {options[0]}
                </Button>
                <Button
                    variant={'light'}
                    className={`switch-option no-border ${!value ? "active bg-white border" : ""}`}
                    onClick={() => onChange(false)}
                    disabled={disabled}
                >
                    {options[1]}
                </Button>
            </ButtonGroup>
        </div>
    );
};

export default SwitchGroup;
