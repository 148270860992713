import { Form, Spinner } from 'react-bootstrap';
import Autocomplete from 'react-google-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useLoadScript, Libraries } from '@react-google-maps/api';
import { Countries, GermanStates } from '../utils/enum';

const libraries: Libraries = ['places'];

interface AddressAutocompleteProps {
    apiKey: string;
    onAddressSelect: (address: {
        street: string;
        streetNumber: string;
        zipcode: string;
        city: string;
        state: string;
        country: string;
    }) => void;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ apiKey, onAddressSelect }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });

    const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
        if (!place || !place.address_components) {
            console.error('Place details are incomplete or invalid.');
            return;
        }

        const addressComponents = place.address_components;
        let street = '';
        let streetNumber = '';
        let zipcode = '';
        let city = '';
        let state = '';
        let country = '';

        addressComponents.forEach((component) => {
            if (component.types.includes('route')) {
                street = component.long_name;

                if (street.endsWith("Straße") || street.endsWith("Strasse")) {
                    street = street.replace(/(Straße|Strasse)$/, 'Str.');
                } else if (street.endsWith("straße") || street.endsWith("strasse")) {
                    street = street.replace(/(straße|strasse)$/, 'str.');
                }
            } else if (component.types.includes('street_number')) {
                streetNumber = component.long_name;
            } else if (component.types.includes('postal_code')) {
                zipcode = component.long_name;
            } else if (component.types.includes('locality')) {
                city = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
                const stateLongName = component.long_name;
                state = Object.keys(GermanStates).find(key => GermanStates[key as keyof typeof GermanStates] === stateLongName) || '';
            } else if (component.types.includes('country')) {
                const countryShortName = component.short_name.toLowerCase();
                country = Object.keys(Countries).find(key => key === countryShortName) || '';
            }
        });

        onAddressSelect({
            street,
            streetNumber,
            zipcode,
            city,
            state,
            country,
        });
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    if (loadError) {
        return <div>Error loading Google Maps API</div>;
    }

    if (!isLoaded) {
        return <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>;
    }

    return (
        <Form.Group className="mb-3 w-100">
            <Form.Label className="text-black">Google Maps Live Suche</Form.Label>
            <div className="position-relative w-100">
                <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="icon-position-address-autocomplete"
                />
                <Autocomplete
                    libraries={libraries}
                    apiKey={apiKey}
                    onPlaceSelected={handlePlaceSelected}
                    options={{
                        types: ['address'],
                        componentRestrictions: { country: 'de' },
                    }}
                    onKeyDown={handleKeyDown}
                    className="bg-grey text-black w-100 default-input-border p-2 rounded"
                    placeholder="Geben Sie eine Adresse ein"
                />
            </div>
        </Form.Group>
    );
};

export default AddressAutocomplete;
