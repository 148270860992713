import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Table,
  Card,
} from 'react-bootstrap';
import { MediaItem, MediaItemWithProgress } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiClient } from '../../../services/ApiClient';
import { PermissionsEnum } from '../../../utils/enum';
import { useSelection } from '../../../utils/hooks/useSelection';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { useSortableData } from '../../../utils/hooks/useSortableData';
import { useNavigate } from 'react-router-dom';
import PaginationInfo from '../../table/PaginationInfo';
import DynamicPagination from '../../table/DynamicPagination';
import MediaDetails from '../MediaDetails';
import MediumEditModal from './MediumEditModal';
import { formatSizeUnits, getMediaIcon, getMediaStatusIcons, getMediaUrl } from '../../../utils/media/utils';
import SkeletonRow from '../../table/skeletonRow/SkeletonRow';
import SelectionActions from '../SelectionActions';
import UploadMediaModal from './UploadMediaModal';
import NewFolderModal from './NewFolderModal';
import Breadcrumbs from '../Breadcrumbs';
import { faDownload, faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import PortalWrapper from '../../PortalWrapper';
import TableWithFileDropzone from '../TableWithFileDropzone';
import TemporaryFiles from '../../table/temporaryFileRows';
import DeleteModal from '../../DeleteModal';
import { faPen, faXmark } from '@fortawesome/pro-regular-svg-icons';

interface MediaResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: MediaItem[];
}

type MediaPickerMultiModalProps = {
  onSelectMedia: (selectedMedia: MediaItem[]) => void;
  initialMedia?: MediaItem[]
  initialFolderId?: number
  allowedMimeTypesUpload?: string[]
  allowedMimeTypesSelection?: string[]
  convention?: string
  onModalClose: () => void;
};

const MediaPickerMultiModal: React.FC<MediaPickerMultiModalProps> = ({
  onSelectMedia,
  initialMedia,
  initialFolderId,
  allowedMimeTypesUpload = [],
  allowedMimeTypesSelection = [],
  convention,
  onModalClose
}) => {
  const [dropdownSelectedMedia, setDropdownSelectedMedia] = useState<MediaItem | null>();
  const [showMediumEditModal, setShowMediumEditModal] = useState(false);
  const navigate = useNavigate();
  const [selectedFolderId, setSelectedFolderId] = useState(initialFolderId ?? 0)
  const { show, message, error, showToast, hideToast } = useToast();
  const [media, setMedia] = useState<MediaItem[]>([]);
  const [selectedMediaForDetailView, setSelectedMediaForDetailView] = useState<MediaItem | null>(null);
  const [selectedMediaForMultiSelect, setSelectedMediaForMultiSelect] = useState<MediaItem[]>(initialMedia ?? []);
  const [detailViewOpen, setDetailViewOpen] = useState<boolean>(initialMedia ? true : false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastQueryParams, setLastQueryParams] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const { items: sortedMedia, requestSort } = useSortableData(media);
  const [breadcrumbs, setBreadcrumbs] = useState<MediaItem[]>([]);
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const [temporaryFiles, setTemporaryFiles] = useState<MediaItemWithProgress[]>([]);

  // Update breadcrumbs for folder navigation
  const handleBreadcrumbs = (newBreadcrumbs: MediaItem[]) => {
    setBreadcrumbs(newBreadcrumbs);
  };

  // Function to get the ID of a media item
  const getId = useCallback((media: MediaItem) => media.id, []);

  // Hook to manage selection of media items
  const {
    selectedItems: selectedMedia,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(media, getId);

  // Effect to synchronize selected media items across multi-selection
  useEffect(() => {
    const allSelected = Object.entries(selectedMedia)
      .filter(([_, isSelected]) => isSelected)
      .map(([mediaId]) => media.find((m) => m.id === Number(mediaId)));

    const selected = Array.from(
      new Set([...initialMedia ?? [], ...allSelected].map(item => item?.id))
    )
      .map(id => [...initialMedia ?? [], ...allSelected].find(item => item?.id === id))
      .filter(item => item !== undefined && item?.mimeType !== "inode/directory");

    setSelectedMediaForMultiSelect(selected as MediaItem[]);
  }, [selectedMedia, media, initialMedia]);

  // Function to fetch media items from the server
  const fetchMedia = async () => {
    setMedia([]);
    setIsLoading(true);

    let queryParams = `?page=${currentPage}&folder_id=${Number(selectedFolderId) === 0 ? 'IS%20NULL' : selectedFolderId}`;

    if (limit.toString()) {
      queryParams += `&limit=${limit}`;
    }

    allowedMimeTypesSelection.forEach((type: string, index: number) => {
      queryParams += `&mimeType[${index}]=${encodeURIComponent(type)}`;
    });

    try {
      // API call to fetch media items
      const response = await ApiClient.get(`/media${queryParams}`);
      setLastQueryParams(queryParams)
      const mediaResponse = response.data as MediaResponse;
      setTotalPages(mediaResponse.amountPages);
      setMedia(mediaResponse.list ?? []);
      setCurrentPage(mediaResponse.page);
      setLimit(mediaResponse.itemsPerPage);
      setTotalEntries(mediaResponse.amountAllItems);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // Effect to fetch media items based on various dependencies
  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Media, 'read');

      if (hasPermission) {
        fetchMedia();
      } else {
        navigate('/errors/error404')
      }
    }
  }, [
    currentPage,
    permissionsLoaded,
    selectedFolderId,
    limit
  ]);

  // Handle the creation of a new folder
  const handleNewFolderSubmit = (mediaId: number | null, message?: string, isError?: boolean) => {
    if (message) {
      showToast(message, isError);
    }

    if (mediaId) {
      setSelectedFolderId(mediaId)
      fetchMedia()
    }
  };

  // Handle media updates (e.g., after editing)
  const handleMediaUpdateSubmit = (message?: string, isError?: boolean) => {
    if (message) {
      showToast(message, isError);
    }
    fetchMedia();
  };

  // Check if the media item can be selected based on allowed MIME types
  const extensionAllowed = (media: MediaItem) => {
    if (media.mimeType === 'inode/directory') return true
    return allowedMimeTypesSelection.length === 0 || allowedMimeTypesSelection.includes(media.mimeType)
  }

  // Handle clicks on media items or folders
  const handleMediumOrFolderClick = async (media: MediaItem) => {
    if (extensionAllowed(media)) {
      if (media.mediaType === 'inode') {
        // If it's a folder, navigate into it
        setDetailViewOpen(false);
        setSelectedFolderId(media.id);
        setCurrentPage(1)
      } else {
        setSelectedMediaForDetailView(null);

        if (selectedCount >= 1) {
          setDetailViewOpen(false);
        } else {
          setDetailViewOpen(true);
        }

        setSelectedMediaForDetailView(media);
      }
    }
  }

  // Handle the closure of the preview pane
  const handlePreviewCloseClick = () => {
    setDetailViewOpen(false)
    setSelectedMediaForDetailView(null)
  }

  // Handle the selection of multiple media items
  const handleSelectMedia = () => {
    if (selectedMediaForMultiSelect) {
      onSelectMedia(selectedMediaForMultiSelect)
      onModalClose()
    }
  }

  // Handle file uploads
  const handleFilesChange = async (newFiles: File[]) => {
    if (!newFiles.length) {
      return;
    }

    // Filter files based on allowed MIME types, if specified
    const allowedFiles = allowedMimeTypesUpload.length === 0
      ? newFiles
      : newFiles.filter(file => allowedMimeTypesUpload.includes(file.type));

    const disallowedFiles = allowedMimeTypesUpload.length === 0
      ? []
      : newFiles.filter(file => !allowedMimeTypesUpload.includes(file.type));

    if (disallowedFiles.length > 0) {
      showToast(
        `Einige Dateien haben nicht unterstützte Formate und wurden nicht hochgeladen. Erlaubte Formate: ${allowedMimeTypesUpload}.`,
        true
      );
    }

    if (!allowedFiles.length) {
      return;
    }

    // Create temporary file items to show upload progress
    const tempFiles = allowedFiles.map((file, index) => ({
      media_id: `temp-${index}`,
      title: file.name,
      size: file.size,
      mediaType: 'file',
      progress: 0,
    }));

    setTemporaryFiles(tempFiles as any);

    try {
      // Upload files to the server
      for (let index = 0; index < allowedFiles.length; index++) {
        const file = allowedFiles[index];
        const formData = new FormData();
        formData.append('file', file);

        if (convention) {
          formData.append('convention', convention);
        }

        formData.append('folder_id', selectedFolderId?.toString());

        await ApiClient.post('/upload', formData, {
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total ? progressEvent.total : progressEvent.loaded;
            const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
            setTemporaryFiles(prevFiles => {
              const newFiles = [...prevFiles];
              newFiles[index].progress = percentCompleted;
              return newFiles;
            });
          },
        });
      }
      showToast(`Erfolgreich gespeichert`, false);
      await fetchMedia();
    } catch (error) {
      showToast('Fehler beim Speichern', true);
      console.error('Error during file upload:', error);
    } finally {
      setTemporaryFiles([]);
    }
  };

  return (
    <>
      <Modal
        centered
        fullscreen
        show
        onHide={onModalClose}
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <h3>Medien auswählen</h3>
          <div className="d-flex">

            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button"
                style={{ padding: 0 }}
              >
                <span className="visually-hidden">More options</span>
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <NewFolderModal
                  modalTitle="Neuer Ordner"
                  buttonName="Neuer Ordner"
                  onSubmitSuccess={handleNewFolderSubmit}
                  selectedFolderId={selectedFolderId}
                  onFolderModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.Media, 'write')}
                >
                </NewFolderModal>

                <UploadMediaModal
                  modalTitle="Hochladen"
                  buttonName="Hochladen"
                  onSubmitSuccess={handleMediaUpdateSubmit}
                  selectedFolderId={selectedFolderId}
                  onUploadMediaModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.Media, 'write')}
                  convention={convention}
                ></UploadMediaModal>
                <Dropdown.Item onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button variant="btn btn-primary" disabled={false} onClick={handleSelectMedia}>
              Auswählen
            </Button>

          </div>
        </Modal.Header>

        <Modal.Body>
          <Fragment>
            <Row>
              <Col md={(detailViewOpen && selectedMediaForDetailView) ? 9 : 12}>
                <Card className="card-block ">
                  <Card.Body>
                    <Row className="d-flex justify-content-between">
                      <Col md={6}>
                        <Breadcrumbs
                          isMediaPicker={true}
                          onBreadcrumbsClick={(id) => {
                            setSelectedFolderId(id);
                            setCurrentPage(1);
                          }}
                          onBreadcrumbsChange={handleBreadcrumbs}
                          currentFolderId={selectedFolderId}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <TableWithFileDropzone isDisabled={!userHasPermissionByRight(PermissionsEnum.Media, 'write')} onFilesChange={handleFilesChange}>
                  <div style={{ overflowX: 'auto' }}>
                    <Table responsive="md" size="sm">
                      <thead>
                        <tr>
                          <th
                            className="d-flex align-items-center"
                            style={{ cursor: 'pointer' }}
                            scope="col"
                            onClick={() => requestSort('title')}
                          >
                            <div className='d-flex'>
                              <label className="d-flex align-items-center w-100 ps-2">
                                <Form.Check
                                  className="me-3"
                                  type="checkbox"
                                  checked={isAllSelected}
                                  onChange={handleSelectAll}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                />

                              </label>
                              Name
                            </div>
                          </th>
                          <th
                            style={{ cursor: 'pointer', width: '80px' }}
                            scope="col"
                            onClick={() => requestSort('size')}
                          >
                            Dateigröße
                          </th>
                          <th
                            style={{ cursor: 'pointer', width: '80px' }}
                            scope="col"
                          >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedCount > 0 && (
                          <SelectionActions
                            media={sortedMedia}
                            selectedMedia={selectedMedia}
                            selectedCount={selectedCount}
                            handleDeSelectAll={handleDeSelectAll}
                            handleSelectAll={handleSelectAll}
                            onSubmitSuccess={handleMediaUpdateSubmit}
                            amountAllItems={totalEntries}
                            queryParams={lastQueryParams}
                          ></SelectionActions>
                        )}
                        {temporaryFiles.length > 0 ? (
                          <TemporaryFiles temporaryFiles={temporaryFiles} />
                        ) : (
                          isLoading
                            ? Array.from({ length: 8 }).map((_, index) => (
                              <SkeletonRow key={index} columnCount={3} />
                            ))
                            : sortedMedia.map((media) => (
                              <tr
                                key={media.id}
                                className={`${selectedMedia[media.id] ? 'bg-light-blue' : 'bg-white'} cursor-pointer`}>
                                <td onClick={(e) => {
                                  handleMediumOrFolderClick(media)
                                  handleSelectRow(media.id, e)
                                }}
                                >
                                  <div className={`d-flex align-items-center ${!extensionAllowed(media) ? 'text-gray' : 'text-black'}`}>
                                    <div className="d-flex align-items-center">
                                      <label className="d-flex align-items-center w-100 ps-2" onClick={(event) => event.stopPropagation()}>
                                        <Form.Check
                                          className="me-3"
                                          type="checkbox"
                                          checked={selectedMedia[media.id] ?? false}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleSelectRow(media.id, e)
                                          }}
                                          onChange={() => { }}
                                        />
                                      </label>
                                      {getMediaIcon(media)} {getMediaStatusIcons(media)} {media.title}
                                    </div>
                                  </div>
                                </td>
                                <td className='text-center'>{media.size ? formatSizeUnits(media.size) : '-'}</td>
                                <td>
                                  <Dropdown className="me-2">
                                    <Dropdown.Toggle as="span"
                                      bsPrefix="btn"
                                      className="no-border">
                                      <FontAwesomeIcon icon={faEllipsisVertical} />
                                    </Dropdown.Toggle>
                                    <PortalWrapper>
                                      <Dropdown.Menu>

                                        {media.mediaType !== 'inode' &&
                                          <a href={getMediaUrl(media)} target="_blank" rel="noopener noreferrer">
                                            <Dropdown.Item as="div">
                                              <FontAwesomeIcon width={30} icon={faDownload} />
                                              Herunterladen
                                            </Dropdown.Item>
                                          </a>
                                        }

                                        <Dropdown.Item
                                          disabled={!userHasPermissionByRight(PermissionsEnum.Media, 'write')}
                                          onClick={() => {
                                            setDropdownSelectedMedia(media);
                                            setShowMediumEditModal(true)
                                            setIsTopLevelModalOpen(true);
                                          }}>
                                          <FontAwesomeIcon width={30} icon={faPen} />Bearbeiten
                                        </Dropdown.Item>

                                        <DeleteModal
                                          modalTitle="Löschen?"
                                          buttonName="Löschen"
                                          entityIds={[media.id]}
                                          entityType='media'
                                          onSubmit={handleMediaUpdateSubmit}
                                          onDeleteModalChange={setIsTopLevelModalOpen}
                                          isDropdownItem={true}>
                                        </DeleteModal>

                                      </Dropdown.Menu>
                                    </PortalWrapper>
                                  </Dropdown>
                                </td>
                              </tr>
                            )))}
                      </tbody>
                    </Table>
                    {!isLoading && sortedMedia.length === 0 && (
                      <div
                        className="d-flex justify-content-center align-items-center border rounded my-3"
                        style={{ height: '50px' }}
                      >
                        <p className="p-0 m-0">Keine Medien in Ordner <b>{breadcrumbs[breadcrumbs.length - 1]?.title}</b> gefunden</p>
                      </div>
                    )}
                  </div>
                </TableWithFileDropzone>

                {totalEntries > 0 && (
                  <Row>
                    <Col>
                      <PaginationInfo
                        currentPage={currentPage}
                        limit={limit}
                        totalEntries={totalEntries}
                        onLimitChange={(size) => {
                          setLimit(size);
                          setCurrentPage(1);
                        }}
                      />
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <DynamicPagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </Col>
                  </Row>
                )}</Col>
              <Col md={(detailViewOpen && selectedMediaForDetailView) ? 3 : 0}>
                {(detailViewOpen && selectedMediaForDetailView) && <MediaDetails media={selectedMediaForDetailView} onClose={handlePreviewCloseClick}></MediaDetails>}
              </Col>
            </Row>
            <NotificationToast
              show={show}
              onClose={hideToast}
              message={message}
              error={error}
            />
          </Fragment>
        </Modal.Body>
      </Modal >
      {showMediumEditModal && dropdownSelectedMedia && <MediumEditModal
        modalTitle={`${dropdownSelectedMedia.mediaType === 'inode' ? 'Ordner' : "Datei"} bearbeiten`}
        media={dropdownSelectedMedia}
        onSubmit={handleMediaUpdateSubmit}
        onModalClose={() => {
          setDropdownSelectedMedia(null);
          setShowMediumEditModal(false)
          setIsTopLevelModalOpen(false)
        }}
      >
      </MediumEditModal>
      }
    </>
  );
};

export default MediaPickerMultiModal;
