import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GenericInfosCard from '../../products/GenericInfosCard';
import { Bdks, } from '../../../interfaces';

import SkeletonCard from '../../locations/skeleton/SkeletonCard';
import { formatEuro } from '../../../utils/utils';

interface BdksDetailsProps {
    isLoading?: boolean;
    bdks?: Bdks | null
    onSubmitSuccess: (bdksId: number) => void;
}

const BdksDetails: React.FC<BdksDetailsProps> = ({
    isLoading,
    bdks,
    onSubmitSuccess
}) => {
    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: bdks?.title || '-',
            },
            {
                label: 'Systematikposition',
                value: bdks?.bdksId || '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Niveaustufe',
                value: bdks?.level || '-',
            },
            {
                label: 'Wert in Euro',
                value: bdks?.costs ? formatEuro(bdks?.costs) : '-',
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{bdks?.title}</h4>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default BdksDetails;
