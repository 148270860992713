import React, { useState } from 'react';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/tomorrow_night';

interface JsonViewerProps {
  data: string;
}

const JsonViewer: React.FC<JsonViewerProps> = ({ data }) => {
  const [height, setHeight] = useState('300px'); 
  const jsonData = JSON.parse(data);
  const jsonString = JSON.stringify(jsonData, null, 2); 

  const adjustHeight = (editor: any) => {
    const newHeight = editor.getSession().getScreenLength() * editor.renderer.lineHeight + editor.renderer.scrollBar.getWidth();
    //setHeight(newHeight.toString() + 'px');
  };

  return (
    <AceEditor
      mode="json"
      theme="tomorrow_night"
      name="json-editor"
      value={jsonString}
      readOnly={true}
      showPrintMargin={false}
      showGutter={true}
      highlightActiveLine={true}
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        useWorker: false,
        showLineNumbers: true,
        tabSize: 2,
      }}
      onLoad={adjustHeight}
      onChange={(newValue, editor) => adjustHeight(editor)}
      style={{
        width: '100%',
        height: height,
        fontFamily: 'monospace',
        backgroundColor: '#1e1e1e',
        color: '#d4d4d4',
      }}
    />
  );
};

export default JsonViewer;
