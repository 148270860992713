import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import GenericInfosCard from '../products/GenericInfosCard';
import { GlobalDataCompany, Setting } from '../../interfaces';
import SkeletonCard from '../locations/skeleton/SkeletonCard';
import EditGlobalDataCompaniesModal from './modal/EditGlobalDataCompaniesModal';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { PermissionsEnum } from '../../utils/enum';

interface GlobalDataCompaniesDetailsProps {
    isLoading?: boolean;
    setting?: Setting | null;
    onSubmitSuccess: (settingsId: number) => void;
}

const GlobalDataCompaniesDetails: React.FC<GlobalDataCompaniesDetailsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const globalDataCompany = setting?.details as GlobalDataCompany;
    function getInfo1() {
        return [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
    }

    function getInfo2() {
        return globalDataCompany ? [
            {
                label: 'Dokumentenersteller (Anrede)',
                value: globalDataCompany.documentCreatorSalutation || '-',
            },
            {
                label: 'Dokumentenersteller (Vorname)',
                value: globalDataCompany.documentCreatorFirstName || '-',
            },
            {
                label: 'Dokumentenersteller (Nachname)',
                value: globalDataCompany.documentCreatorLastName || '-',
            },
            {
                label: 'Dokumentenersteller (Telefonnummer)',
                value: globalDataCompany.documentCreatorPhone || '-',
            },
            {
                label: 'Dokumentenersteller (E-Mail Adresse)',
                value: globalDataCompany.documentCreatorEmail || '-',
            },
            {
                label: 'Dokumentenersteller (Anschrift - Name)',
                value: globalDataCompany.documentCreatorAddressName || '-',
            },
            {
                label: 'Dokumentenersteller (Anschrift - Straße + Hausnummer)',
                value: globalDataCompany.documentCreatorAddressStreet || '-',
            },
            {
                label: 'Dokumentenersteller (Anschrift - PLZ)',
                value: globalDataCompany.documentCreatorAddressZip || '-',
            },
            {
                label: 'Dokumentenersteller (Anschrift - Stadt)',
                value: globalDataCompany.documentCreatorAddressCity || '-',
            },
            {
                label: 'Dokumentenersteller (Anschrift - Bundesland)',
                value: globalDataCompany.documentCreatorAddressState || '-',
            },
            {
                label: 'Dokumentenersteller (Anschrift - URL)',
                value: globalDataCompany.documentCreatorAddressUrl || '-',
            },
        ] : [];
    }

    function getInfo3() {
        return globalDataCompany ? [
            {
                label: 'Anbieter (ID)',
                value: globalDataCompany.companyId || '-',
            },
            {
                label: 'Anbieter (Keyword)',
                value: globalDataCompany.supplierKeyword || '-',
            },
            {
                label: 'Anbieter (Name)',
                value: globalDataCompany.supplierName || '-',
            },
            {
                label: 'Anbieter (Anschrift)',
                value: globalDataCompany.supplierAddressName || '-',
            },
            {
                label: 'Anbieter (Straße + Hausnummer)',
                value: globalDataCompany.supplierAddressStreet || '-',
            },
            {
                label: 'Anbieter (PLZ)',
                value: globalDataCompany.supplierAddressZip || '-',
            },
            {
                label: 'Anbieter (Stadt)',
                value: globalDataCompany.supplierAddressCity || '-',
            },
            {
                label: 'Anbieter (Bundesland)',
                value: globalDataCompany.supplierAddressState || '-',
            },
            {
                label: 'Anbieter (Telefon)',
                value: globalDataCompany.supplierAddressPhone || '-',
            },
            {
                label: 'Anbieter (E-Mail Adresse)',
                value: globalDataCompany.supplierAddressEmail || '-',
            },
            {
                label: 'Anbieter (Faxnummer)',
                value: globalDataCompany.supplierAddressFax || '-',
            },
            {
                label: 'Anbieter (URL)',
                value: globalDataCompany.supplierAddressUrl || '-',
            },
        ] : [];
    }

    function getInfo4() {
        return globalDataCompany ? [
            {
                label: 'Organisation (Formtyp)',
                value: globalDataCompany.organizationFormType || '-',
            },
            {
                label: 'Organisation (Form)',
                value: globalDataCompany.organizationForm || '-',
            },
        ] : [];
    }

    function getInfo5() {
        return globalDataCompany ? [
            {
                label: 'Kontakt (Anrede)',
                value: globalDataCompany.contactSalutation || '-',
            },
            {
                label: 'Kontakt (Vorname)',
                value: globalDataCompany.contactFirstName || '-',
            },
            {
                label: 'Kontakt (Nachname)',
                value: globalDataCompany.contactLastName || '-',
            },
            {
                label: 'Kontakt (Telefon)',
                value: globalDataCompany.contactPhone || '-',
            },
            {
                label: 'Kontakt (E-Mail Adresse)',
                value: globalDataCompany.contactEmail || '-',
            },
        ] : [];
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.Settings, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo4()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo3()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={6}
                        />
                    )}
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo5()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            {setting && showAddEditModal && <EditGlobalDataCompaniesModal
                modalTitle={`${setting.title} bearbeiten`}
                setting={setting}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            >
            </EditGlobalDataCompaniesModal>
            }
        </>
    );
};

export default GlobalDataCompaniesDetails;
