import React, { useState } from 'react';
import { Row, Col, Card, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import GenericInfosCard from '../../products/GenericInfosCard';
import { Task } from '../../../interfaces';
import SkeletonCard from '../../locations/skeleton/SkeletonCard';
import { getEnumValue, PermissionsEnum, YesNoMapping } from '../../../utils/enum';
import AddEditTaskModal from '../modal/AddEditTaskModal';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import TaskLogAccordion from '../../TaskLogAccordion';

/* Mapping of timeframe options to their display labels */
const timeframeLabels: { [key: string]: string } = {
    today: "Heute",
    last_7_days: "Letzte 7 Tage",
    last_30_days: "Letzte 30 Tage",
    all: "Alle"
};

interface TaskDetailsProps {
    isLoading?: boolean;
    task?: Task | null;
    onSubmitSuccess: (taskId: number) => void;
    onTimeframeChange: (timeframe: string) => void;
}

const TaskDetails: React.FC<TaskDetailsProps> = ({
    isLoading,
    task,
    onSubmitSuccess,
    onTimeframeChange
}) => {
    const { userHasPermissionByRight } = usePermissions();
    const [selectedTimeframe, setSelectedTimeframe] = useState<string>('today');
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    /* Handling for timeframe selection in dropdown, defaults to 'today' */
    const handleSelectTimeframe = (eventKey: string | null) => {
        const timeFrame = eventKey || 'today'
        setSelectedTimeframe(timeFrame)
        onTimeframeChange(timeFrame);
    }

    /* Information for the first info card */
    function getInfo1() {
        const info1 = [
            {
                label: 'PHP-Klassenname',
                value: task?.className || '-',
            },
            {
                label: 'Beschreibung',
                value: task?.description || '-',
            },
            {
                label: 'Aktiv',
                value: getEnumValue(YesNoMapping, task?.isActive),
            },
        ];
        return info1;
    }

    /* Information for the second info card */
    function getInfo2() {
        const info2 = [
            {
                label: 'CronTab',
                value: task?.crontab || '-',
            },
            {
                label: 'Nur Einmal Ausführen?',
                value: getEnumValue(YesNoMapping, task?.runOnlyOnce),
            },
        ];
        return info2;
    }

    return (
        <>
            {/* Header with the task name and edit button */}
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{task?.className}</h4>
                <Button
                    disabled={!userHasPermissionByRight(PermissionsEnum.Tasks, 'write')}
                    variant="primary"
                    onClick={() => setShowAddEditModal(true)}
                >
                    Bearbeiten
                </Button>
            </div>

            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            {/* Dropdown for selecting log timeframe */}
                            <div className='d-flex justify-content-between w-100'>
                                <h5 className="mb-3">Logs</h5>
                                <DropdownButton
                                    id="dropdown-timeframe"
                                    title={timeframeLabels[selectedTimeframe]}
                                    onSelect={handleSelectTimeframe}
                                    className="mb-3"
                                >
                                    <Dropdown.Item eventKey="today">{timeframeLabels['today']}</Dropdown.Item>
                                    <Dropdown.Item eventKey="last_7_days">{timeframeLabels['last_7_days']}</Dropdown.Item>
                                    <Dropdown.Item eventKey="last_30_days">{timeframeLabels['last_30_days']}</Dropdown.Item>
                                    <Dropdown.Item eventKey="all">{timeframeLabels['all']}</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            {task?.logs && task.logs.length > 0 ? (
                                <TaskLogAccordion logs={task.logs} />
                            ) : (
                                <div>Keine Logs gefunden</div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Modal for editing the task */}
            {task && showAddEditModal && (
                <AddEditTaskModal
                    modalTitle={`${task.className} bearbeiten `}
                    task={task}
                    onSubmitSuccess={onSubmitSuccess}
                    onModalClose={() => setShowAddEditModal(false)}
                />
            )}
        </>
    );
};

export default TaskDetails;
