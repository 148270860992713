import React, { useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Spinner,
  Dropdown,
} from 'react-bootstrap';
import FileDropzone from '../FileDropZone';
import { ApiClient } from '../../../services/ApiClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import { Accept } from 'react-dropzone';

type UploadMediaModalProps = {
  modalTitle: string;
  buttonName: string;
  onSubmitSuccess: (message?: string, isError?: boolean) => void;
  selectedFolderId?: number | null
  onUploadMediaModalChange: (open: boolean) => void;
  isDropdownItem?: boolean
  isDisabled?: boolean
  convention?: string
  accept?: Accept;
}

const UploadMediaModal: React.FC<UploadMediaModalProps> = ({
  modalTitle,
  buttonName,
  onSubmitSuccess,
  selectedFolderId,
  onUploadMediaModalChange,
  isDropdownItem,
  isDisabled,
  convention,
  accept
}) => {
  const { folderId = 0 } = useParams();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    onUploadMediaModalChange(false);
    setShowModal(false);
  };

  const handleShow = () => {
    onUploadMediaModalChange(true);
    setShowModal(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [files, setFiles] = useState<File[]>([]);

  const handleFilesChange = (newFiles: File[]) => {
    setFiles([...files, ...newFiles]);
  };

  const handleRemoveFile = (fileName: string) => {
    setFiles(files.filter(file => file.name !== fileName));
  };

  const handleUpload = async () => {
    setIsLoading(true);

    try {
      for (let file of files) {
        const formData = new FormData();
        convention && formData.append('convention', convention)
        formData.append('file', file);
        formData.append('folder_id', selectedFolderId?.toString() ? selectedFolderId.toString() : folderId.toString());
        await ApiClient.post('/upload', formData);
      }
      onSubmitSuccess(`Erfolgreich gespeichert`, false);
      setFiles([])
    } catch (error) {
      onSubmitSuccess('Fehler beim Speichern', true);
      console.error('Error during file upload:', error);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };


  return (
    <>
      {isDropdownItem ?
        <Dropdown.Item disabled={isDisabled} onClick={handleShow}><FontAwesomeIcon width={30} icon={faUpload}/>{buttonName}</Dropdown.Item>
        :
        <Button disabled={isDisabled} variant={selectedFolderId?.toString() ? "btn btn-soft-primary me-4" : 'primary'} onClick={handleShow}>
          {buttonName}
        </Button>
      }

      <Modal
        centered
        size="lg"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <FileDropzone accept={accept}  onFilesChange={handleFilesChange} />
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {files.map(file => (
                  <li key={file.name}>
                    <div style={{ border: '1px solid #EEEEEE' }} className='rounded p-2 my-3 text-black d-flex justify-content-between'>
                      <span>{file.name}</span>
                      <span className='cursor-pointer' onClick={() => handleRemoveFile(file.name)}><FontAwesomeIcon icon={faXmark} /></span>
                    </div>

                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn-soft-primary" variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={handleUpload}
            disabled={isLoading || files.length === 0}
          >
            {files?.length} Dateien Hochladen
            {isLoading && (
              <Spinner
                className="ms-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              ></Spinner>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadMediaModal;
