import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { MediaItem } from '../../interfaces';
import { formatSizeUnits, getImageUrl } from '../../utils/media/utils';

type SelectedMediaPreviewProps = {
  selectedMediaForMultiSelect: MediaItem[];
  handleDeleteMedia: (media: MediaItem) => void;
  colProps?: number;
};

// Function component to display a preview of selected media items
const SelectedMediaPreview: React.FC<SelectedMediaPreviewProps> = ({
  selectedMediaForMultiSelect,
  handleDeleteMedia,
  colProps = 6
}) => {
  return (
    <Row>
      {selectedMediaForMultiSelect.length === 0 ? (
        <Col className="mb-2">
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3 py-3"
          >
            <p className="p-0 m-0">Keine Medien zugewiesen</p>
          </div>
        </Col>
      ) : (
        selectedMediaForMultiSelect.map((media, index) => (
          <Col key={index} lg={colProps} className="mb-2">
            <div className="d-flex justify-content-between align-items-center border rounded p-2 media-picker-preview-wrapper">
              <div className="media-info d-flex align-items-center">
                <img
                  src={getImageUrl(media)} // Generates the URL for the media item's image preview
                  alt={`Preview of ${media.title}`}
                  className="media-picker-preview-image"
                />
                <div>
                  <p className="m-0 mb-1 text-black">
                    {media.title}
                  </p>
                  <span>
                    {media.width > 0 && media.height > 0 && (
                      <>
                        {media.width} x {media.height} -{' '}
                      </>
                    )}
                    {media.size && formatSizeUnits(media.size)}
                  </span>
                </div>
              </div>
              <FontAwesomeIcon
                icon={faX}
                className="cursor-pointer"
                onClick={() => handleDeleteMedia(media)} // Calls the function to delete the selected media item
              />
            </div>
          </Col>
        ))
      )}
    </Row>
  );
};

export default SelectedMediaPreview;
