import React from 'react';

interface SkeletonLoaderProps {
    height?: string;
    width?: string;
    style?: React.CSSProperties;
    className?: string;
}

/**
 * SkeletonLoader Component
 * 
 * This component is a generic skeleton loader that can be used as a placeholder for loading content.
 * It displays a block with a customizable height, width, and style, which gives a visual indication
 * of loading before the actual content is rendered.
 * 
 * Props:
 * - `height`: Sets the height of the skeleton loader (default is "20px").
 * - `width`: Sets the width of the skeleton loader (default is 100% if not specified).
 * - `style`: Additional CSS styles that can be applied to customize the skeleton loader further.
 * - `className`: Additional CSS class names for custom styling.
 * 
 * Example usage:
 * ```tsx
 * <SkeletonLoader height="30px" width="100px" style={{ borderRadius: '4px' }} />
 * ```
 */
const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ height = '20px', width = '', style, className }) => {
    return (
        <div
            className={`skeleton-loader ${className || ''}`}
            style={{
                height,
                width,
                ...style,
            }}
        ></div>
    );
};

export default SkeletonLoader;
