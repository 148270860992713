import React, { useState, useMemo, useEffect } from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import PaginationInfo from '../../table/PaginationInfo';
import DynamicPagination from '../../table/DynamicPagination';
import ComboButtonGroup, { ComboButton } from '../../ComboButtonGroup';
import SearchInput from '../../SearchInput';
import { formatDate, getInitials, groupActivitiesByMonthAndYear } from '../../../utils/utils';
import { ApiClient } from '../../../services/ApiClient';
import { Activity, User } from '../../../interfaces';
import GenericDropdownFilter from '../../filter/GenericDropdownFilter';
import ResetFiltersButton from '../../ResetFilterButton';
import DateRangeDropdownFilter from '../../filter/DateRangeDropdownFilter';
import { ActivityTypeEnum, getEnumValue } from '../../../utils/enum';
import ParticipantInitialsComponent from '../../ParticipantInitialsComponent';
import ActionButtons from './ActionButtons';
import MediaDetails from '../../MediaDetails';
import AddCommentModal from '../modal/AddCommentModal';
import { usePermissions } from '../../../utils/hooks/usePermissions';

interface ActivitiesFilters {
    type: number | null
    pinned: boolean | null
    person: string | null
    startDate: string | null
    endDate: string | null
}

interface ActivitiesResponse {
    page: number;
    itemsPerPage: number;
    amountPages: number;
    amountAllItems: number;
    searchFilters: string[];
    list: Activity[];
}

interface ActivitiesListProps {
    type?: number;
    elementId: number;
    elementClass: string;
    isPinnedSection?: boolean;
    onActivitiesUpdate: () => void;
    refreshKey?: number;
}

const ActivitiesList: React.FC<ActivitiesListProps> = ({ type, elementId, elementClass, isPinnedSection = false, onActivitiesUpdate, refreshKey }) => {
    const [activities, setActivities] = useState<Activity[]>([]);
    const [selectedTab, setSelectedTab] = useState('active');
    const [searchQuery, setSearchQuery] = useState('');
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalEntries, setTotalEntries] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
    const [userIdToTitleMap, setUserIdToTitleMap] = useState<{ [key: number]: string }>({});
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);
    const { userId } = usePermissions();

    const [selectedFilters, setSelectedFilters] = useState<ActivitiesFilters>({
        type: type ?? null,
        pinned: null,
        startDate: null,
        endDate: null,
        person: null
    });

    const mainTabs: ComboButton[] = useMemo(() => [
        { id: 'active', label: 'Aktiv' },
        { id: 'archived', label: 'Archiviert' },
    ], []);

    const fetchActivities = async () => {
        setIsLoading(true);
        let queryParams = `?elementId=${elementId}&elementClass=${elementClass}&page=${currentPage}&limit=${limit}`;

        queryParams += '&sort[field]=timeOfActivity'
        queryParams += `&sort[type]=desc`;

        if (searchQuery) {
            queryParams += `&search=${encodeURIComponent(searchQuery)}&column=activities.info`;
        }

        if (selectedFilters.type?.toString()) {
            queryParams += `&type=${selectedFilters.type?.toString()}`;
        }

        if (selectedFilters.startDate?.toString()) {
            queryParams += `&filter_period[start]=${selectedFilters.startDate?.toString()}`;
        }

        if (selectedFilters.endDate?.toString()) {
            queryParams += `&filter_period[end]=${selectedFilters.endDate?.toString()}`;
        }

        if (selectedFilters.pinned || isPinnedSection) {
            queryParams += `&pinned=true`;
        }

        if (selectedFilters.person?.toString()) {
            queryParams += `&user_id=${selectedFilters.person?.toString()}`;
        }

        if (selectedTab === 'archived') {
            queryParams += `&archived=true`;
        } else {
            queryParams += `&archived=false`;
        }

        try {
            const response = await ApiClient.get(`/activities${queryParams}`);
            const activityResponse = response.data as ActivitiesResponse;
            setActivities(activityResponse.list ?? []);
            setTotalEntries(activityResponse.amountAllItems);
            setTotalPages(activityResponse.amountPages);
            setCurrentPage(activityResponse.page);
            setLimit(activityResponse.itemsPerPage);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUserMap = async () => {
        const userResponse = await ApiClient.get(`/activities/users?elementId=${elementId}&elementClass=${elementClass}`);
        const users = userResponse.data.list as User[];
        const map = users.reduce<{ [key: number]: string }>((acc, user) => {
            acc[user.id] = user.title;
            return acc;
        }, {});
        setUserIdToTitleMap(map);
    };

    useEffect(() => {
        fetchActivities();
        if (!Object.keys(userIdToTitleMap).length) {
            fetchUserMap();
        }
    }, [type, currentPage, limit, searchQuery, selectedTab, selectedFilters, refreshKey]);

    const handleSearch = (data: { query: string, filter: string | undefined }) => {
        if (data.query) {
            setSearchQuery(data.query);
            setCurrentPage(1);
        } else if (searchQuery) {
            resetSearch();
        }
    };

    const resetSearch = () => {
        setSearchQuery('');
        setCurrentPage(1);
    };

    useEffect(() => {
        if (resetSearchInput) {
            setResetSearchInput(false);
        }
    }, [resetSearchInput]);

    const handleActionClick = async (activity: Activity, action: string, isCurrentlyPinned: boolean, isCurrentlyArchived: boolean) => {
        try {
            if (action === 'comment') {
                setSelectedActivity(activity);
                setShowCommentModal(true);
                return;
            } else if (action === 'pin') {
                await ApiClient.put(`/activities/${activity.id}`, { pinned: !isCurrentlyPinned });
            } else if (action === 'archive') {
                await ApiClient.put(`/activities/${activity.id}`, { archived: !isCurrentlyArchived });
            } else if (action === 'delete') {
                await ApiClient.delete(`/activities/${activity.id}`);
            }
            onActivitiesUpdate();
        } catch (error) {
            console.error(`Failed to ${action} activity:`, error);
        }
    };

    const groupedActivities = groupActivitiesByMonthAndYear(activities);

    return (
        <div>
            <>
                {!isPinnedSection && (
                    <Row className="d-flex justify-content-between mb-4">
                        <Col md={6}>
                            {searchQuery && (
                                <div className="d-flex align-items-baseline mb-3">
                                    <h4 className="m-0">Suchergebnisse</h4>
                                    <span className="ms-3 d-flex align-items-baseline">
                                        <Button
                                            className="m-0 p-0 fs-6"
                                            variant="link"
                                            onClick={resetSearch}
                                        >
                                            Suche beenden
                                        </Button>
                                    </span>
                                </div>
                            )}

                            <ComboButtonGroup
                                buttons={mainTabs}
                                selectedCombo={selectedTab}
                                setSelectedCombo={(tab) => {
                                    setSelectedTab(tab as string);
                                    setCurrentPage(1);
                                }}
                                borderRadius="normal"
                            />
                        </Col>
                        <Col md={3}>
                            <SearchInput onSearch={handleSearch} reset={resetSearchInput} />
                        </Col>
                    </Row>
                )}

                {/* Main Content */}
                {!isPinnedSection && (
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-start align-items-center mb-3">
                                <div className="d-flex custom-scrollbar-x horizontal-scroll">
                                    <GenericDropdownFilter
                                        selectedFilter={selectedFilters.type ?? null}
                                        handleFilterChange={(type) => {
                                            setSelectedFilters((filters) => ({ ...filters, type }));
                                            setCurrentPage(1);
                                        }}
                                        filterEnum={ActivityTypeEnum}
                                        titlePlaceholder="Bereich"
                                        isDisabled={!!type}
                                    />
                                    <GenericDropdownFilter
                                        selectedFilter={selectedFilters.pinned === true ? 1 : selectedFilters.pinned === false ? 0 : null}
                                        handleFilterChange={(pinned) => {
                                            setSelectedFilters((filters) => ({
                                                ...filters,
                                                pinned: pinned?.toString() === '1' ? true : pinned?.toString() === '0' ? false : null
                                            }));
                                            setCurrentPage(1);
                                        }}
                                        filterEnum={{ 0: 'Nein', 1: 'Ja' }}
                                        titlePlaceholder="Angepinnt"
                                    />
                                    <GenericDropdownFilter
                                        selectedFilter={selectedFilters.person ?? null}
                                        handleFilterChange={(person) => {
                                            setSelectedFilters((filters) => ({ ...filters, person }));
                                            setCurrentPage(1);
                                        }}
                                        filterEnum={userIdToTitleMap}
                                        titlePlaceholder="Person"
                                    />
                                    <DateRangeDropdownFilter
                                        onDateRangeChange={(startDate: Date | null, endDate: Date | null) => {
                                            setSelectedFilters((filters) => ({
                                                ...filters,
                                                startDate: startDate ? startDate.toISOString() : null,
                                                endDate: endDate ? endDate.toISOString() : null
                                            }));
                                            setCurrentPage(1);
                                        }}
                                        initialStartDate={selectedFilters.startDate ? new Date(selectedFilters.startDate) : null}
                                        initialEndDate={selectedFilters.endDate ? new Date(selectedFilters.endDate) : null}
                                        isDisabled={false}
                                        titlePlaceholder="Zeitraum"
                                    />

                                    {!type?.toString() && (
                                        <div className="sticky-right-reset-filter">
                                            <ResetFiltersButton filters={selectedFilters} setFilters={(newFilters: ActivitiesFilters) => {
                                                setSelectedFilters(newFilters);
                                                setCurrentPage(1);
                                            }} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}

                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                        <Spinner animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <div>
                        {Object.keys(groupedActivities).map((monthYear) => (
                            <div key={monthYear} className="mb-4">
                                <h6 className="d-flex align-items-center text-muted mb-3">
                                    <span className="text-nowrap">{monthYear}</span>
                                    <div className="flex-grow-1 ms-2 horizontal-line"></div>
                                </h6>
                                {groupedActivities[monthYear].map((activity, index) => (
                                    <div key={activity.id} className="d-flex mb-4">
                                        <div className='me-2'>
                                            <ParticipantInitialsComponent size={40} initials={getInitials(activity.user?.title)} smileyLevel={0} />
                                        </div>
                                        <div className="flex-grow-1">
                                            <div className="d-flex flex-column mb-3 text-black">
                                                <div className='d-flex justify-content-between'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='mb-1'>{activity.user?.title} · {getEnumValue(ActivityTypeEnum, activity.type.toString())}</span>
                                                        <span className="text-muted">{formatDate(activity.timeOfActivity, 'd.m.Y, H:i Uhr')}</span>
                                                    </div>
                                                    <div>
                                                        <ActionButtons
                                                            activityId={activity.id}
                                                            isPinned={activity.pinned}
                                                            isArchived={activity.archived}
                                                            isCreator={userId === activity.user?.id}
                                                            createdDate={activity.created}
                                                            onActionClick={(activityId, action) =>
                                                                handleActionClick(activity, action, activity.pinned, activity.archived)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 text-black">
                                                <p className='text-wrap' style={{ wordBreak: 'break-word' }}>{activity.info}</p>
                                                {activity.media.length > 0 && (
                                                    <MediaDetails
                                                        handleDelete={console.log}
                                                        mediaItems={activity.media}
                                                        title={`Notizdokumente von ${activity.user?.title} - ${formatDate(activity.timeOfActivity, 'd.m.Y, H:i Uhr')}`}
                                                        showDropdown={false}
                                                        isGrid
                                                    />
                                                )}

                                                {activity.comments.map(c => (
                                                    <div key={c.id} className='d-flex'>
                                                        <div className='me-2'>
                                                            <ParticipantInitialsComponent
                                                                type='comment'
                                                                size={40}
                                                                initials={getInitials(activity.user?.title)}
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column mb-3 text-black">
                                                            <div className='d-flex justify-content-between'>
                                                                <div className='d-flex flex-column'>
                                                                    <span className='mb-1'>
                                                                        {c.user?.title} ·{' '}
                                                                        <span className="text-muted">
                                                                            {formatDate(c.created, 'd.m.Y, H:i Uhr')}
                                                                        </span>
                                                                    </span>
                                                                    <p
                                                                        className='ms-2 border-radius-comment p-2 bg-grey text-wrap'
                                                                        style={{ wordBreak: 'break-word' }}
                                                                    >
                                                                        {c.info}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>

                                            {index < groupedActivities[monthYear].length - 1 && (
                                                <div className="horizontal-line mt-3"></div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}


                {!isLoading && activities.length === 0 && (
                    <div
                        className="d-flex justify-content-center align-items-center border rounded my-3"
                        style={{ height: '50px' }}
                    >
                        <p className="p-0 m-0">Keine Notizen gefunden</p>
                    </div>
                )}

                <Row>
                    <Col>
                        <PaginationInfo
                            currentPage={currentPage}
                            limit={limit}
                            totalEntries={totalEntries}
                            onLimitChange={(size) => {
                                setLimit(size);
                                setCurrentPage(1);
                            }}
                            pageSizes={[5, 10, 15, 20]}
                        />
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <DynamicPagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Col>
                </Row>

                {showCommentModal && selectedActivity !== null && (
                    <AddCommentModal
                        activity={selectedActivity}
                        onModalClose={() => setShowCommentModal(false)}
                        onSubmitSuccess={onActivitiesUpdate}
                    />
                )}
            </>
        </div>
    );
};

export default ActivitiesList;
