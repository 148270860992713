import React, { useState } from 'react';
import { AccountId, FieldConfig, Location, Partner, PaymentMethods, } from '../../../interfaces';
import Card from '../../bootstrap/card';
import { Button, Col, Row } from 'react-bootstrap';
import StatusDisplay from './StatusDisplay';
import LocationHeaderDropdown from './LocationHeaderDropdown';
import { PermissionsEnum } from '../../../utils/enum';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import AddEditLocationModal from '../modal/AddEditLocationModal';
import { getLocationMainImage } from '../../../utils/media/utils';
import CertificationStatusDropdown from '../../CertificationStatusDropdown';
import SkeletonLoader from '../../SkeletonLoader';
import LocationExportDropdown from './LocationExportDropdown';
import { statusCertificationLocationsColorMap, statusCertificationLocationsIconMap } from '../../filter/iconAndColorMappings';
import { getFieldConfigByResourceName } from '../../../utils/utils';

interface LocationDetailsHeaderProps {
    isLoading?: boolean;
    location?: Location
    onSubmitSuccess: (locationsId: number) => void;
    onAccountsUpdate: () => void;
    onPartnerUpdate: () => void;
    onPaymentMethodsUpdate: () => void;
    handleCertificationStatusUpdate: (certificationStatus: number) => void;
    accounts?: AccountId
    partner?: Partner
    paymentMethods?: PaymentMethods
    fieldConfigs: FieldConfig[]
}

/**
 * LocationDetailsHeader Component
 * 
 * This component displays a detailed header section for a location, including the location's image, 
 * address, status information, and various action buttons. The component can show a loading state 
 * with skeleton loaders while data is being fetched.
 * 
 * Props:
 * - `isLoading`: Boolean indicating whether the data is still loading.
 * - `location`: The `Location` object containing the data to display.
 * - `onSubmitSuccess`: Callback function triggered after a successful form submission in the modal.
 * - `onAccountsUpdate`: Callback for updating account-related data.
 * - `onPartnerUpdate`: Callback for updating partner-related data.
 * - `onPaymentMethodsUpdate`: Callback for updating payment methods data.
 * - `handleCertificationStatusUpdate`: Function to handle status updates for certification.
 * - `accounts`: Account information related to the location.
 * - `partner`: Partner information for the location.
 * - `paymentMethods`: Payment methods associated with the location.
 * - `fieldConfigs`: Configuration data for rendering dynamic fields.
 * 
 * Example usage:
 * ```tsx
 * <LocationDetailsHeader
 *   isLoading={true}
 *   location={locationData}
 *   onSubmitSuccess={handleSuccess}
 *   onAccountsUpdate={updateAccounts}
 *   onPartnerUpdate={updatePartner}
 *   onPaymentMethodsUpdate={updatePaymentMethods}
 *   handleCertificationStatusUpdate={updateCertificationStatus}
 *   accounts={accountData}
 *   partner={partnerData}
 *   paymentMethods={paymentMethodsData}
 *   fieldConfigs={fieldConfigData}
 * />
 * ```
 */
const LocationDetailsHeader: React.FC<LocationDetailsHeaderProps> = ({
    isLoading,
    location,
    onSubmitSuccess,
    fieldConfigs,
    onAccountsUpdate,
    onPartnerUpdate,
    onPaymentMethodsUpdate,
    handleCertificationStatusUpdate,
    accounts,
    partner,
    paymentMethods
}) => {
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const { userHasPermissionByRight } = usePermissions();

    const canViewCertificates = userHasPermissionByRight(PermissionsEnum.LocationsCertificates, 'read');
    const canWriteCertificates = userHasPermissionByRight(PermissionsEnum.LocationsCertificates, 'write');

    const isDataLoaded = !isLoading && location && partner;

    return (
        <>
            <Card>
                <Card.Body>
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-between align-items-start align-items-lg-center flex-wrap mb-3">
                                <div className="d-flex justify-content-center align-items-center">
                                    {isDataLoaded ? (
                                        <img
                                            className="img-fluid w-100 rounded location-header-image"
                                            src={getLocationMainImage(location)}
                                            alt="Location"
                                        />
                                    ) : (
                                        <SkeletonLoader height='80px' width='100px' ></SkeletonLoader>
                                    )}
                                    <div className="d-flex flex-column mb-0 ms-3">
                                        {isDataLoaded ? (
                                            <>
                                                <h3>{`${location?.street} ${location?.streetNr} · ${location?.city}`}</h3>
                                                <StatusDisplay location={location} fieldConfigs={fieldConfigs} partner={partner} />
                                            </>
                                        ) : (
                                            <>
                                                <SkeletonLoader height='30px' width='25vw' className='mb-2'></SkeletonLoader>
                                                <SkeletonLoader width='50vw'></SkeletonLoader>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {
                                    isDataLoaded &&
                                    <div className="d-flex mt-2 mt-xl-0">

                                        {canWriteCertificates && <CertificationStatusDropdown
                                            statusCertification={location?.statusCertification!}
                                            handleCertificationStatusUpdate={handleCertificationStatusUpdate}
                                            statusEnum={getFieldConfigByResourceName(fieldConfigs, 'locations.statusCertification')?.options ?? {}}
                                            iconMap={statusCertificationLocationsIconMap}
                                            colorMap={statusCertificationLocationsColorMap}
                                            className="bg-grey"
                                        />}

                                        {canViewCertificates && <LocationExportDropdown onDownloadClick={() => { }} />}

                                        <LocationHeaderDropdown location={location} onSubmitSuccess={onSubmitSuccess} />
                                        <Button
                                            disabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'write') || !isDataLoaded || !fieldConfigs}
                                            variant="primary"
                                            onClick={() => setShowAddEditModal(true)}
                                        >
                                            Bearbeiten
                                        </Button>
                                    </div>
                                }

                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {showAddEditModal && (
                <AddEditLocationModal
                    modalTitle="Standort bearbeiten"
                    location={location}
                    onSubmitSuccess={onSubmitSuccess}
                    accounts={accounts}
                    onAccountsUpdate={onAccountsUpdate}
                    onPartnerUpdate={onPartnerUpdate}
                    partner={partner}
                    paymentMethods={paymentMethods}
                    onPaymentMethodsUpdate={onPaymentMethodsUpdate}
                    onModalClose={() => setShowAddEditModal(false)}
                    fieldConfigs={fieldConfigs}
                />
            )}
        </>
    );
};

export default LocationDetailsHeader;
