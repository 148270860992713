import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GenericInfosCard from '../GenericInfosCard';

interface MeasuresConceptDetailsProps {
  isLoading?: boolean;
}

const MeasuresConceptDetails: React.FC<MeasuresConceptDetailsProps> = ({
  isLoading,
}) => {
  return (
    <>
      <Row>
        {' '}
        <div
          className="d-flex justify-content-center align-items-center border rounded my-3"
          style={{ height: '50px' }}
        >
          <p className="p-0 m-0">Kein Maßnahmenkonzept gefunden</p>
        </div>
      </Row>
    </>
  );
};

export default MeasuresConceptDetails;
