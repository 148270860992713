import React from 'react';
import { Row, Col } from 'react-bootstrap';

type SkeletonRowsProps = {
  count: number;
};

/**
 * SkeletonRows Component
 * 
 * This component renders a specified number of skeleton row placeholders, each consisting of two 
 * columns that simulate loading content. It is ideal for displaying a series of rows (such as 
 * table rows or form fields) while data is being loaded.
 * 
 * Props:
 * - `count`: The number of skeleton rows to display.
 * 
 * Example usage:
 * ```tsx
 * <SkeletonRows count={5} />
 * ```
 */
const SkeletonRows: React.FC<SkeletonRowsProps> = ({ count }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Row key={index} className="mb-3 align-items-center">
          <Col lg={4}>
            <div className="skeleton-loader" style={{ height: '20px'}}></div>
          </Col>
          <Col>
          <div className="skeleton-loader" style={{ height: '20px' }}></div>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default SkeletonRows;
