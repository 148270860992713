import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

interface PaginationInfoProps {
  currentPage: number;
  limit: number;
  totalEntries: number;
  onLimitChange: (newLimit: number) => void;
  pageSizes?: number[];
}

const PaginationInfo: React.FC<PaginationInfoProps> = ({
  currentPage,
  limit,
  totalEntries,
  onLimitChange,
  pageSizes = [25, 50, 100, 250, 500], // Default to existing page sizes
}) => {

  const startIndex = (currentPage - 1) * limit + 1;
  const endIndex = Math.min(startIndex + limit - 1, totalEntries);

  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      onLimitChange(parseInt(eventKey));
    }
  };

  return (
    <div className="dataTables_info d-flex align-items-center">
      <span>{startIndex}-{endIndex} von {totalEntries} Einträgen</span>
      <span className="mx-2"> | Ergebnisse pro Seite:</span>
      <DropdownButton
        id="dropdown-page-size-button"
        title={`${limit}`}
        onSelect={handleSelect}
        className="mx-1"
        size="sm"
      >
        {pageSizes.map((size) => (
          <Dropdown.Item key={size} eventKey={size.toString()}>
            {size}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

export default PaginationInfo;
