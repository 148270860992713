import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Dropdown, InputGroup, FormControl, Button } from 'react-bootstrap';

interface SelectOption {
  value: string;
  label: string;
}

interface DropdownWithAddOptionProps {
  id: string;
  label: string;
  options: SelectOption[];
  initialValue?: string | null;
  onChange: (selectedOption: string | null) => void;
  onAddOption: (newOption: string) => void;
  placeholder?: string;
  required?: boolean;
}

const DropdownWithAddOption: React.FC<DropdownWithAddOptionProps> = ({
  id,
  label,
  options,
  initialValue = null,
  onChange,
  onAddOption,
  placeholder = 'Bitte auswählen...',
  required = false,
}) => {

  const [selectedLabel, setSelectedLabel] = useState<string>(placeholder);
  const [newOption, setNewOption] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (initialValue) {
      const matchingOption = options.find(option => option.value === initialValue);
      if (matchingOption) {
        setSelectedLabel(matchingOption.label);
      } else {
        setSelectedLabel(placeholder);
      }
    }
  }, [initialValue, options, placeholder]);

  const handleSelect = (option: SelectOption) => {
    setSelectedLabel(option.label);
    setIsOpen(false);
    onChange(option.value);
  };

  const handleAddNewOption = () => {
    if (newOption.trim() !== '') {
      onAddOption(newOption);
      setNewOption('');
      setIsOpen(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddNewOption();
    }
  };

  return (
    <div className='mb-3'>
      <label htmlFor={id} className="form-label text-black">{label}</label>
      <Dropdown id={id} show={isOpen} onToggle={() => setIsOpen(!isOpen)}>
        <Dropdown.Toggle id={id} className="w-100 bg-grey text-black d-flex justify-content-between default-input-border ps-3">
          {selectedLabel}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100 overflow-auto dropdown-menu-max-height">
          {options.map((option, index) => (
            <Dropdown.Item key={index} onClick={() => handleSelect(option)}>
              {option.label}
            </Dropdown.Item>
          ))}

          <div className='horizontal-line my-2'></div>

          <InputGroup className='p-2'>
            <FormControl
              placeholder="Neuen Eintrag hinzufügen"
              value={newOption}
              onChange={(e) => setNewOption(e.target.value)}
              onKeyDown={handleKeyPress}
              className='text-black bg-grey'
            />
            <Button variant="primary" onClick={handleAddNewOption}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </InputGroup>

        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownWithAddOption;
