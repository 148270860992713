import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { PublicationStatus, PublicationStatusEnum } from '../utils/enum';
import { getIconByPublicationStatus, getPublicationStatusColor } from '../utils/products/utils';
import PortalWrapper from './PortalWrapper';

interface PublicationStatusDropdownProps {
  status: PublicationStatus;
  handlePublicationStatusUpdate: (status: PublicationStatus) => void;
  isDisabled?: boolean;
  style: React.CSSProperties;
}

// PublicationStatusDropdown is a dropdown component for displaying and updating the publication status of an item.
// It renders the current status and provides a dropdown list of other available statuses.
// When a new status is selected, it triggers the 'handlePublicationStatusUpdate' callback to update the status.
// The dropdown is disabled if 'isDisabled' is set to true, and it supports custom styles via the 'style' prop.
// The 'PortalWrapper' is used to ensure the dropdown menu is rendered correctly even if nested deeply.
const PublicationStatusDropdown: React.FC<PublicationStatusDropdownProps> = ({
  status,
  handlePublicationStatusUpdate,
  isDisabled,
  style,
}) => {
  return (
    <Dropdown className="ps-2 me-2 rounded" style={style}>
      <Dropdown.Toggle
        disabled={isDisabled}
        variant="link"
        id="dropdown-basic"
        className={`${getPublicationStatusColor(
          status
        )} ps-0`}
      >
        {getIconByPublicationStatus(status)}
        {PublicationStatusEnum[status]}
      </Dropdown.Toggle>
      <PortalWrapper>
        <Dropdown.Menu>
          {Object.entries(PublicationStatusEnum)
            .filter(([statusKey, _]) => Number(statusKey) !== status)
            .map(([statusKey, statusValue]) => (
              <Dropdown.Item
                key={statusKey}
                onClick={() =>
                  handlePublicationStatusUpdate(Number(statusKey) as PublicationStatus)
                }
              >
                {getIconByPublicationStatus(Number(statusKey) as PublicationStatus)}
                {statusValue}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </PortalWrapper>
    </Dropdown>
  );
};

export default PublicationStatusDropdown;
