import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner, Dropdown } from 'react-bootstrap';
import { ApiClient } from '../services/ApiClient';
import { faFileDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosHeaders, AxiosResponse } from 'axios';

interface DownloadZipModalProps {
    modalTitle: string;
    buttonName: string;
    entityIds: number[];
    endpoint: string;
    onSubmit: (message?: string, isError?: boolean) => void;
    isDisabled?: boolean;
    isDropdownItem?: boolean;
    queryParams: string
}

const DownloadZipModal: React.FC<DownloadZipModalProps> = ({
    modalTitle,
    buttonName,
    entityIds,
    endpoint,
    isDisabled,
    isDropdownItem,
    onSubmit,
    queryParams
}) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleShow = () => setShowModal(true);

    const handleDownload = async () => {
        setIsLoading(true);

        try {
            let response: AxiosResponse

            if (entityIds.length === 0) {
                response = await ApiClient.post(`${endpoint}${queryParams}`, {}, {
                    responseType: 'blob',
                })
            } else {
                const ids = entityIds.map(id => `id[]=${id}`).join('&');

                response = await ApiClient.post(`${endpoint}${queryParams}&${ids}`, {}, {
                    responseType: 'blob',
                })
            }

            let fileName = 'download.zip';
            if (response.headers instanceof AxiosHeaders) {
                const suggestedFileName = response.headers.get('X-Suggested-Filename')?.toString();
                if (suggestedFileName) fileName = suggestedFileName;
            }

            const url = window.URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            onSubmit(`Download erfolgreich`, false)
        } catch (error) {
            onSubmit(`Fehler beim herunterladen`, true)
            console.error('Download failed', error);
        } finally {
            setIsLoading(false);
            setShowModal(false);
        }
    };

    useEffect(() => {
        if (showModal) {
            handleDownload();
        }
    }, [showModal]);

    const handleClose = () => {
        if (!isLoading) {
            setShowModal(false);
        }
    };

    return (
        <>
            {isDropdownItem ? (
                <Dropdown.Item
                    disabled={isDisabled}
                    as="div"
                    className={`${isDisabled ? 'disabled-item' : ''}`}
                    onClick={handleShow}
                >
                    <FontAwesomeIcon width={30} icon={faFileDownload} />
                    {buttonName}
                </Dropdown.Item>
            ) : (
                <div className="custom-button-focus">
                    <Button
                        disabled={isDisabled}
                        variant="outline-primary"
                        onClick={handleShow}
                    >
                        {buttonName}
                    </Button>
                </div>
            )}

            <Modal
                centered
                size="lg"
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {isLoading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status" />
                            <p className="mt-3">Die Datei wird vorbereitet, bitte warten...</p>
                        </div>
                    ) : (
                        <p className="text-center">Download abgeschlossen</p>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DownloadZipModal;
