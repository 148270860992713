import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

import {
    statusColorMap,
    statusIconMap,
    statusCertificationLocationsColorMap,
    statusCertificationLocationsIconMap
} from './filter/iconAndColorMappings';
import { getFieldConfigByResourceName } from '../utils/utils';
import { getEnumValue } from '../utils/enum';
import { FieldConfig } from '../interfaces';

interface StatusIndicatorProps {
    type?: 'standard' | 'publication' | 'certification';
    isActive?: boolean;
    status?: number;
    fieldConfigs?: FieldConfig[];
    resourceName?: string;
    activeText?: string;
    inactiveText?: string;
}

/**
 * StatusIndicator Component
 * 
 * This versatile component displays a status icon and label, configurable to handle standard 
 * active/inactive indicators, publication statuses, and certification statuses.
 * 
 * Props:
 * - `type`: Specifies the type of indicator: 'standard', 'publication', or 'certification'.
 * - `isActive`: Boolean for standard type to indicate active/inactive state.
 * - `status`: Numeric status code for publication and certification types.
 * - `fieldConfigs`: Array of `FieldConfig` objects to retrieve display labels (for publication and certification types).
 * - `resourceName`: The resource name to fetch specific field configuration data (for publication and certification).
 * - `activeText`: Custom text when `isActive` is true (defaults to "Aktiv" for standard type).
 * - `inactiveText`: Custom text when `isActive` is false (defaults to "Inaktiv" for standard type).
 * 
 * Example usage:
 * ```tsx
 * // Standard usage
 * <StatusIndicator type="standard" isActive={true} activeText="Enabled" inactiveText="Disabled" />
 * 
 * // Publication status
 * <StatusIndicator type="publication" status={2} fieldConfigs={fieldConfigData} resourceName="locations.status" />
 * 
 * // Certification status
 * <StatusIndicator type="certification" status={1} fieldConfigs={fieldConfigData} resourceName="locations.statusCertification" />
 * ```
 */

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
    type = 'standard',
    isActive = false,
    status,
    fieldConfigs = [],
    resourceName,
    activeText = "Aktiv",
    inactiveText = "Inaktiv"
}) => {
    if (type === 'standard') {
        const icon = isActive ? faCheckCircle : faTimesCircle;
        const colorClass = isActive ? 'text-success' : 'text-danger';
        const statusText = isActive ? activeText : inactiveText;

        return (
            <span className={`${colorClass}`}>
                <FontAwesomeIcon width={15} icon={icon} className="me-2" />
                <span>{statusText}</span>
            </span>
        );
    }

    if (status === undefined || status === null || !resourceName) return null;

    const iconMap = type === 'publication' ? statusIconMap : statusCertificationLocationsIconMap;
    const colorMap = type === 'publication' ? statusColorMap : statusCertificationLocationsColorMap;
    const statusIcon = iconMap[status];
    const statusColor = colorMap[status];
    const fieldConfig = getFieldConfigByResourceName(fieldConfigs, resourceName);
    const statusLabel = getEnumValue(fieldConfig?.options ?? {}, status.toString());

    return (
        <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 ${statusColor}`}>
            <FontAwesomeIcon width={15} icon={statusIcon} className="me-2" />
            <span>{statusLabel}</span>
        </div>
    );
};

export default StatusIndicator;
