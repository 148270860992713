
export const PublicationStatusEnum = {
  1: 'Veröffentlicht',
  2: 'Unveröffentlichte Änderungen',
  0: 'Unveröffentlicht',
  3: 'Archiviert'
};

export type PublicationStatus = keyof typeof PublicationStatusEnum;

export const YesNoMapping = {
  true: 'Ja',
  false: 'Nein',
};

export const ActiveInactiveMapping = {
  0: 'Inaktiv',
  1: 'Aktiv',
};

export const ProductTypeEnum = {
  2: 'Highlightprodukt',
  1: 'Sichtbarkeitsprodukt',
};

export type ProductType = keyof typeof ProductTypeEnum;

export const ExecutionForm = {
  1: 'Vollzeit',
  2: 'Teilzeit',
  3: 'Vollzeit + Teilzeit',
};

export const ExecutionFormMeinNow = {
  1: 'Vollzeit + Teilzeit',
  2: 'Teilzeit',
  3: 'Vollzeit + Teilzeit',
};

export const TeachingForms = {
  1: 'Online',
  2: 'Präsens',
  3: 'Hybrid',
};

export const FormOfExecution = {
  1: 'Einzelmaßnahme',
  2: 'Gruppenmaßnahme',
};

export const TypeOfMeasure = {
  1: 'Maßnahme',
  2: 'Maßnahmenbaustein',
};

export const TypeOfLocationEnum = {
  1: 'Eigener Standort',
  2: 'Partnerstandort',
};

export type TypeOfLocation = keyof typeof TypeOfLocationEnum;

export enum GermanStates {
  bw = 'Baden-Württemberg',
  by = 'Bayern',
  be = 'Berlin',
  bb = 'Brandenburg',
  hb = 'Bremen',
  hh = 'Hamburg',
  he = 'Hessen',
  mv = 'Mecklenburg-Vorpommern',
  ni = 'Niedersachsen',
  nw = 'Nordrhein-Westfalen',
  rp = 'Rheinland-Pfalz',
  sl = 'Saarland',
  sn = 'Sachsen',
  st = 'Sachsen-Anhalt',
  sh = 'Schleswig-Holstein',
  th = 'Thüringen',
}

export enum Countries {
  al = 'Albanien',
  ad = 'Andorra',
  am = 'Armenien',
  at = 'Österreich',
  az = 'Aserbaidschan',
  be = 'Belgien',
  ba = 'Bosnien und Herzegowina',
  bg = 'Bulgarien',
  by = 'Weißrussland',
  ch = 'Schweiz',
  cy = 'Zypern',
  cz = 'Tschechien',
  de = 'Deutschland',
  dk = 'Dänemark',
  ee = 'Estland',
  es = 'Spanien',
  fo = 'Färöer-Inseln',
  fi = 'Finnland',
  fr = 'Frankreich',
  gb = 'Vereinigtes Königreich',
  ge = 'Georgien',
  gi = 'Gibraltar',
  gr = 'Griechenland',
  hu = 'Ungarn',
  hr = 'Kroatien',
  ie = 'Irland',
  is = 'Island',
  it = 'Italien',
  kz = 'Kasachstan',
  xk = 'Kosovo',
  lv = 'Lettland',
  li = 'Liechtenstein',
  lt = 'Litauen',
  lu = 'Luxemburg',
  mk = 'Nordmazedonien',
  mt = 'Malta',
  md = 'Moldawien',
  mc = 'Monaco',
  me = 'Montenegro',
  nl = 'Niederlande',
  no = 'Norwegen',
  pl = 'Polen',
  pt = 'Portugal',
  ro = 'Rumänien',
  ru = 'Russland',
  sm = 'San Marino',
  rs = 'Serbien',
  sk = 'Slowakei',
  si = 'Slowenien',
  se = 'Schweden',
  tr = 'Türkei',
  ua = 'Ukraine',
  va = 'Vatikanstadt',
}

export const IndustryBranch = {
  '1': 'Gewerblich-technischer Bereich',
  '2': 'Kaufmännischer Bereich',
  '3': 'Unternehmensbezogene Dienstleistungen',
  '4': 'Personenbezogene und soziale Dienstleistungen',
} as const;

export const EducationType = {
  '0': 'Keine Zuordnung möglich',
  '100': 'Allgemeinbildung',
  '101': 'Berufliche Grundqualifikation',
  '102': 'Berufsausbildung',
  '103': 'Gesetzlich/gesetzesähnlich geregelte Fortbildung/Qualifizierung',
  '104': 'Fortbildung/Qualifizierung',
  '105': 'Nachholen des Berufsabschlusses',
  '106': 'Rehabilitation',
  '109': 'Umschulung',
} as const;

export const InstitutionType = {
  '0': 'Keine Zuordnung möglich',
  '100': 'Allgemeinbildende Schule/Einrichtung',
  '101': 'Berufsakademie / Duale Hochschule',
  '102': 'Berufsbildende Schule/Einrichtung',
  '103': 'Berufsbildungswerk (R)',
  '104': 'Berufsförderungswerk (R)',
  '105': 'Einrichtung der beruflichen Weiterbildung',
  '106': 'Fachhochschule / Hochschule für angewandte Wissenschaften',
  '107': 'Kunst- und Musikhochschule',
  '108': 'Universität',
  '109': 'vergleichbare Rehabilitationseinrichtung (R)',
  '110': 'med.-berufl. Rehabilitationseinrichtung (R)',
  '111': 'Verwaltungshochschule',
  '112': 'Kirchliche Hochschule',
  '113': 'Private Hochschule',
  '114': 'Hochschule eigenen Typs',
} as const;

export const EducationTypeProductWebsite = {
  avgs: 'AVGS',
  weiterbildung: 'Weiterbildung',
  umschulung: 'Umschulung',
} as const;

export const KategoryProductWebsite = {
  'PC-ITP-1': 'IT und Digitalisierung',
  'PC-MV-1': 'Marketing',
  'PC-P-1': 'Projektmanagement',
} as const;

export enum PermissionsEnum {
  Optimization = 'optimization',
  Products = 'products',
  Certificates = 'certificates',
  Locations = 'locations',
  LocationsImages = 'locations.images',
  LocationsDocuments = 'locations.documents',
  LocationsCertificates = 'locations.certificates',
  LocationsParticipants = 'locations.participants',
  LocationsPayment = 'locations.payment',
  LocationsContactPerson = 'locations.contactperson',
  LocationsProperty = 'locations.property',
  LocationsRentIncrease = 'locations.rentIncrease',
  LocationsContract = 'locations.contract',
  LocationsCosts = 'locations.costs',
  Media = 'media',
  Settings = 'settings',
  Users = 'users',
  Roles = 'roles',
  Bdks = 'bdks',
  Systematics = 'systematics',
  Webhooks = 'webhooks',
  ApiKeys = 'apikeys',
  Tasks = 'tasks',
  Keywords = 'keywords',
  Focuses = 'focuses',
}

export const CompanyEnum = {
  oc: 'OneCareer',
  cn: 'ChapterNext',
};

export type Company = keyof typeof CompanyEnum;

export const MediaPlaceholderImages = {
  pdf: 'https://storage.onecareer.de/placeholder-media-format-pdf.webp',
  word: 'https://storage.onecareer.de/placeholder-media-format-word.webp',
  excel: 'https://storage.onecareer.de/placeholder-media-format-excel.webp',
  music: 'https://storage.onecareer.de/placeholder-media-format-music.webp',
  video: 'https://storage.onecareer.de/placeholder-media-format-video.webp',
};

export const SettingTypeMap = {
  globalProductFields: '1',
  globalLocationFields: '2',
  globalCompanyData: '3',
  webflowSync: '4',
  meinNowAccounts: '5',
  fileNameTemplates: '6',
  locationsPartners: '7',
  counterIdents: '8',
  paymentMethods: '9',
  defaultValuesExcelCertification: '10'
};

export const CertifierEnum = {
  'acadCert': 'acadCert',
};

export const RoleStatusEnum = {
  0: 'Inaktiv',
  1: 'Aktiv',
};

export const ActivityTypeEnum = {
  0: 'Allgemein',
  1: 'Vertragliches',
  2: 'Zahlung',
  3: 'Kosten',
  4: 'Unterlagen'
}

export type RoleStatus = keyof typeof RoleStatusEnum;

export const moduleTranslations: Partial<Record<PermissionsEnum, string>> = {
  [PermissionsEnum.Locations]: 'Standorte',
  [PermissionsEnum.LocationsImages]: 'Standort-Bilder',
  [PermissionsEnum.LocationsDocuments]: 'Standort-Dokumente',
  [PermissionsEnum.LocationsCertificates]: 'Standort-Zertifizierung',
  [PermissionsEnum.LocationsParticipants]: 'Standort-Teilnehmer',
  [PermissionsEnum.LocationsPayment]: 'Standort-Zahlung',
  [PermissionsEnum.LocationsContactPerson]: 'Standprt-Ansprechpartner',
  [PermissionsEnum.LocationsProperty]: 'Standort-Immobilie',
  [PermissionsEnum.LocationsCosts]: 'Standort-Kosten',
  [PermissionsEnum.LocationsContract]: 'Standort-Vertragliches',
  [PermissionsEnum.LocationsRentIncrease]: 'Mieterhöhungen',
  [PermissionsEnum.Products]: 'Produkte',
  [PermissionsEnum.Media]: 'Medien',
  [PermissionsEnum.Bdks]: 'BDKS',
  [PermissionsEnum.Systematics]: 'Systematiken',
  [PermissionsEnum.Settings]: 'Einstellungen',
  [PermissionsEnum.Certificates]: 'Zertifikate',
  [PermissionsEnum.Users]: 'Nutzer Verwaltung',
  [PermissionsEnum.Roles]: 'Rollen Verwaltung',
  [PermissionsEnum.Optimization]: 'Optimierung',
  [PermissionsEnum.Webhooks]: 'Webhooks',
  [PermissionsEnum.ApiKeys]: 'API-Schlüssel',
  [PermissionsEnum.Keywords]: 'Keywords',
  [PermissionsEnum.Focuses]: 'Schwerpunkte',
  [PermissionsEnum.Tasks]: 'CronTasks',
};

export const WebflowSyncModuleEnum = {
  'locations': 'Standorte',
  'products': 'Produkte',
};

export const WebhookModuleEnum = {
  'locations': 'Standorte',
  'products': 'Produkte',
  'certificates': 'Zertifikate',
};


export const certificateLabels = {
  title: 'Titel',
  admission: 'Datum der Zulassung',
  approvalPeriodStart: 'Zulassungszeitraum Start',
  approvalPeriodEnd: 'Zulassungszeitraum Ende',
  certifier: 'Zertifizierer',
  number: 'Zertifikatsnummer',
};

export const locationLabels = {
  title: 'Titel',
  name: 'Name',
  accountId: 'Account',
  city: 'Stadt',
  country: 'Land',
  email: 'E-Mail Adresse',
  fax: 'Fax (Vorwahl + Rufnummer)',
  googleBusiness: 'Google Business Eintrag (URL)',
  zipcode: 'Postleitzahl',
  mobile: 'Mobil / Handy',
  partner: 'Partnername',
  state: 'Bundesland',
  status: 'Status',
  street: 'Straßenname',
  streetNr: 'Hausnummer',
  telephone: 'Festnetz (Vorwahl + Rufnummer)',
  type: 'Standortart',
  contractDateOfTermination: 'Datum der Kündigung',
  contractEnd: 'Mietende',
  contractMinRentalPeriod: 'Mindestmietdauer (in Monaten)',
  contractNoticePeriod: 'Kündigungsfrist (in Monaten)',
  contractRentalStatus: 'Miet-Status',
  contractStart: 'Mietbeginn',
  costsDeposit: 'Kaution (in €)',
  costsFurtherRents: 'Weitere Mieten (zum Xten des Monats)',
  costsMonthlyGross: 'Miete monatlich, Brutto',
  costsMonthlyNet: 'Miete monatlich, Netto (in €)',
  costsOfTermination: 'Kosten bei Kündigung (in €)',
  costsOneTime: 'Einmalige Kosten (in €)',
  costsPaymentFirstRent: 'Bezahlung erste Miete',
  costsPaymentMethod: 'Zahlart',
  costsRentIncrease: 'Mietsteigerung',
  costsTax: 'Umsatzsteuer',
  costsTaxDuty: 'Umsatzsteuerpflichtig',
  landlordContactPerson: 'Ansprechpartner',
  landlordEmail: 'E-Mail',
  landlordIban: 'IBAN',
  landlordIntendedUse: 'Verwendungszweck',
  landlordName: 'Name',
  utilization: 'Standortnutzung',
};

export const searchFilterTranslations: { [key: string]: string } = {
  'bdks.title': 'BDKS',
  'products.descriptionLong': 'Bildungsinhalte',
  'products.keywords': 'Keywords',
  'systematics.title': 'Systematik',
  'products.title': 'Titel',
  title: 'Titel',
  city: 'Stadt',
  lidIdent: 'Lid Ident',
  partner: 'Partnername',
  stateLabel: 'Bundesland',
  street: 'Straße',
  zipcode: 'PLZ',
  description: 'Beschreibung',
  accountId: 'Account',
  all: 'Alle'
};

export const UnterrichtsFormGlobalData = {
  'Blended Learning': 'Blended Learning',
  'Virtuelles Klassenzimmer': 'Virtuelles Klassenzimmer',
  'Combined Learning': 'Combined Learning',
};

export const SettingAreaEnum = {
  'products': 'Produkte',
  'locations': 'Standorte',
  'settings': 'Einstellungen',
};

export type SettingArea = keyof typeof SettingAreaEnum;

export function getEnumValue<T extends Record<string, any>>(
  enumObj: T,
  key: string | boolean | null | undefined
): string {
  if (key === null || key === undefined) {
    return '-';
  }

  const normalizedKey = String(key);

  return normalizedKey in enumObj ? String(enumObj[normalizedKey]) : normalizedKey;
}
