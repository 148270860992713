import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import GenericInfosCard from '../../products/GenericInfosCard';
import { Permissions, Role } from '../../../interfaces';
import SkeletonCard from '../../locations/skeleton/SkeletonCard';
import AddEditRoleModal from '../modal/AddEditRoleModal';
import { PermissionsEnum, RoleStatusEnum, getEnumValue, moduleTranslations } from '../../../utils/enum';
import { getModuleArray } from '../../../utils/utils';
import Card from '../../bootstrap/card';
import { getModuleIcon } from '../../../utils/roles/utils';
import { usePermissions } from '../../../utils/hooks/usePermissions';

interface RoleDetailsProps {
    isLoading?: boolean;
    role?: Role | null;
    onSubmitSuccess: (certificateId: number) => void;
}

const RoleDetails: React.FC<RoleDetailsProps> = ({
    isLoading,
    role,
    onSubmitSuccess
}) => {
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const { userHasPermissionByRight } = usePermissions();

    const rightsTranslations: { [key: string]: string } = {
        read: 'Lesen',
        write: 'Schreiben',
        delete: 'Löschen'
    };

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: role?.title || '-',
            },
            {
                label: 'Status',
                value: getEnumValue(RoleStatusEnum, role?.status.toString()),
            },
        ];
        return info1;
    }

    const moduleList = getModuleArray(role?.modules);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{role?.title}</h4>

                <Button disabled={!userHasPermissionByRight(PermissionsEnum.Roles, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Allgemein"
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    <Card className={`card-block card-stretch card-height`}>
                        <Card.Body>
                            <h5>Rechte</h5>
                            <ul className='list-no-dots'>
                                {Array.isArray(moduleList) && moduleList.length > 0
                                    ? moduleList.map((item, index) => (
                                        <li key={index}>
                                            {getModuleIcon(item.key as keyof Permissions)} {getEnumValue(moduleTranslations, item.key)} <small>({item.rights.map(right => rightsTranslations[right]).join(', ')})</small>
                                        </li>
                                    ))
                                    : <li>Keine Rechte zugewiesen</li>}
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {role && showAddEditModal && <AddEditRoleModal
                modalTitle={`${role.title} bearbeiten`}
                role={role}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            >
            </AddEditRoleModal>
            }
        </>
    );
};

export default RoleDetails;
