import { useState, useCallback, useEffect } from 'react';

type SelectionState<T> = {
  selectedItems: Record<number | string, boolean>;
  isAllSelected: boolean;
  selectedCount: number;
  handleSelectAll: () => void;
  handleDeSelectAll: () => void;
  handleSelectRow: (itemId: number | string, event: React.MouseEvent) => void;
  setSelectedItems: React.Dispatch<React.SetStateAction<Record<string | number, boolean>>>;
};

export const useSelection = <T extends { id: number | string }>(
  items: T[], 
  getId?: (item: T) => number | string
): SelectionState<T> => {
  const [selectedItems, setSelectedItems] = useState<Record<number | string, boolean>>({});
  const [lastClickedIndex, setLastClickedIndex] = useState<number | null>(null);

  const getItemId = useCallback(
    (item: T) => (getId ? getId(item) : item.id),
    [getId]
  );

  useEffect(() => {
    const newSelectedItems = items.reduce((acc: Record<number | string, boolean>, item) => {
      const id = getItemId(item);
      acc[id] = false;
      return acc;
    }, {});

    setSelectedItems(newSelectedItems);
  }, [items, getItemId]);

  const handleSelectAll = useCallback(() => {
    const newSelectedItems = items.reduce<Record<number | string, boolean>>(
      (acc, item) => {
        const id = getItemId(item);
        acc[id] = true
        return acc;
      }, {});
    setSelectedItems(newSelectedItems);
  }, [items, getItemId]);

  const handleDeSelectAll = useCallback(() => {
    const newSelectedItems = Object.keys(selectedItems).reduce((acc: Record<string, boolean>, key) => {
      acc[key] = false;
      return acc;
    }, {});
    setSelectedItems(newSelectedItems);
  }, [selectedItems]);

  const handleSelectRow = useCallback((itemId: number | string, event: React.MouseEvent) => {
    setSelectedItems(prev => {
      const newSelectedItems = { ...prev };
      const currentIndex = items.findIndex(item => getItemId(item) === itemId);
      
      if (event.shiftKey && lastClickedIndex !== null && lastClickedIndex !== currentIndex) {
        const start = Math.min(lastClickedIndex, currentIndex);
        const end = Math.max(lastClickedIndex, currentIndex);

        for (let i = start; i <= end; i++) {
          const id = getItemId(items[i]);
          newSelectedItems[id] = true;
        }
      } else {
        newSelectedItems[itemId] = !prev[itemId];
      }

      setLastClickedIndex(currentIndex);
      return newSelectedItems;
    });
  }, [items, getItemId, lastClickedIndex]);

  const isAllSelected = items.length > 0 && Object.values(selectedItems).every(Boolean);
  const selectedCount = Object.values(selectedItems).filter(value => value).length;

  return { selectedItems, isAllSelected, selectedCount, handleSelectAll, handleDeSelectAll, handleSelectRow, setSelectedItems };
};
