// default layout
import Default from '../layouts/dashboard/default';
import ProtectedRoute from './ProtectedRoute';

// Dashboard
import Index from '../views/dashboard/index';
import Products from '../views/products/products';
import Locations from '../views/locations/locations';
import LocationDetail from '../views/locations/location-detail';
import Settings from '../views/settings/settings';
import SettingDetail from '../views/settings/setting-detail';
import Systematics from '../views/systematics/systematics';
import SystematicDetail from '../views/systematics/systematic-detail';
import Bdks from '../views/bdks/bdksList';
import BdksDetail from '../views/bdks/bdks-detail';
import ProductDetail from '../views/products/product-detail';
import Optimization from '../views/optimization/optimization';
import Media from '../views/media/media';
import Users from '../views/users/users';
import UserDetail from '../views/users/user-detail';
import Certificates from '../views/certificates/certificates';
import CertificateDetail from '../views/certificates/certificate-detail';
import Roles from '../views/roles/roles';
import RoleDetail from '../views/roles/roles-detail';
import Webhooks from '../views/webhooks/webhooks';
import WebhookDetail from '../views/webhooks/webhook-detail';
import ApiKeys from '../views/apikeys/apikeys';
import ApiKeyDetail from '../views/apikeys/apikey-detail';
import Keywords from '../views/keywords/keywords';
import KeywordDetail from '../views/keywords/keywords-detail';
import Focuses from '../views/focuses/focuses'
import FocusDetail from '../views/focuses/focuses-detail';
import Tasks from '../views/tasks/tasks'
import TaskDetail from '../views/tasks/task-detail';
import { Navigate } from 'react-router-dom';

export const DefaultRouter = [
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      {
        path: '/',
        element: <Navigate to="/oc" />,
      },
      {
        path: '/:companyId',
        element: <Default />,
        children: [
          {
            path: '/:companyId/',
            element: <Index />,
          },
          {
            path: '/:companyId/products',
            element: <Products />,
          },
          {
            path: '/:companyId/products/:productId',
            element: <ProductDetail />,
          },
          {
            path: '/:companyId/locations',
            element: <Locations />,
          },
          {
            path: '/:companyId/locations/:locationId',
            element: <LocationDetail />,
          },
          {
            path: '/:companyId/optimization',
            element: <Optimization />,
          },
          {
            path: '/:companyId/media/:folderId?/:mediumId?',
            element: <Media />,
          },
          {
            path: '/:companyId/settings',
            element: <Settings />,
          },
          {
            path: '/:companyId/settings/:settingId',
            element: <SettingDetail />,
          },
          {
            path: '/:companyId/users',
            element: <Users />,
          },
          {
            path: '/:companyId/users/:userId',
            element: <UserDetail />,
          },
          {
            path: '/:companyId/certificates',
            element: <Certificates />,
          },
          {
            path: '/:companyId/certificates/:certificateId',
            element: <CertificateDetail />,
          },
          {
            path: '/:companyId/systematics',
            element: <Systematics />,
          },
          {
            path: '/:companyId/systematics/:systematicId',
            element: <SystematicDetail />,
          },
          {
            path: '/:companyId/bdks',
            element: <Bdks />,
          },
          {
            path: '/:companyId/bdks/:bdksId',
            element: <BdksDetail />,
          },
          {
            path: '/:companyId/roles',
            element: <Roles />,
          },
          {
            path: '/:companyId/roles/:roleId',
            element: <RoleDetail />,
          },
          {
            path: '/:companyId/webhooks',
            element: <Webhooks />,
          },
          {
            path: '/:companyId/webhooks/:webhookId',
            element: <WebhookDetail />,
          },
          {
            path: '/:companyId/apikeys',
            element: <ApiKeys />,
          },
          {
            path: '/:companyId/apikeys/:apikeyId',
            element: <ApiKeyDetail />,
          },
          {
            path: '/:companyId/keywords',
            element: <Keywords />,
          },
          {
            path: '/:companyId/keywords/:keywordId',
            element: <KeywordDetail />,
          },
          {
            path: '/:companyId/focuses',
            element: <Focuses />,
          },
          {
            path: '/:companyId/focuses/:focusId',
            element: <FocusDetail />,
          },
          {
            path: '/:companyId/tasks',
            element: <Tasks />,
          },
          {
            path: '/:companyId/tasks/:taskId',
            element: <TaskDetail />,
          },
        ],
      },
    ],
  },
];
