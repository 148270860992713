import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GenericInfosCard from '../GenericInfosCard';
import { Product } from '../../../interfaces';
import {
  EducationType,
  ExecutionFormMeinNow,
  InstitutionType,
  YesNoMapping,
  getEnumValue,
} from '../../../utils/enum';
import { formatNumber } from '../../../utils/utils';
import SkeletonCard from '../../locations/skeleton/SkeletonCard';

interface KursnetDetailsProps {
  isLoading?: boolean;
  product?: Product;
  showXmlFieldNames?: boolean
}

const KursnetDetails: React.FC<KursnetDetailsProps> = ({
  isLoading,
  product,
  showXmlFieldNames
}) => {

  function getGeneralInfo() {
    const generalInfo = [
      {
        label: showXmlFieldNames ? 'Bezeichnung' : 'Bezeichnung',
        value: product?.npTitle || '-',
      },
      {
        label: showXmlFieldNames ? 'description_long' : 'Bildungsinhalte',
        value: 'siehe Bildungsinhalte links',
      },
      {
        label: showXmlFieldNames ? 'mime_source_angebot_url' : 'Shortlink',
        value: product?.mimeSourceAngebotUrl || '-',
        isLink: true
      },
    ];
    return generalInfo;
  }

  function getTypeInfo() {
    const typeInfo = [
      {
        label: showXmlFieldNames ? 'abschlussart' : 'Abschlussart',
        value: product?.abschlussart || '-',
      },
      {
        label: showXmlFieldNames ? 'education_type' : 'Bildungsart',
        value: getEnumValue(EducationType, product?.educationType),
      },
      {
        label: showXmlFieldNames ? 'institution' : 'Schulart',
        value: getEnumValue(InstitutionType, product?.institution),
      },
      {
        label: showXmlFieldNames ? 'massnahme_art' : 'Art der Maßnahme',
        value: product?.massnahmeArt || '-',
      },
    ];
    return typeInfo;
  }

  function getDegreeInfo() {
    const degreeInfo = [
      {
        label: showXmlFieldNames ? 'degree_title' : 'Abschlussbezeichnung',
        value: product?.degreeTitle || '-',
      },
      {
        label: showXmlFieldNames ? 'abschlussart' : 'Abschlussart',
        value: product?.abschlussart || '-',
      },
      {
        label: showXmlFieldNames ? 'degree_type' : 'Abschlussart 1',
        value: product?.npDegreeType || '-',
      },
      {
        label: showXmlFieldNames ? 'degree_exam_type' : 'Abschlussart 2',
        value: product?.degreeExamType || '-',
      },
    ];
    return degreeInfo;
  }

  function getDurationInfo() {
    const durationInfo = [
      {
        label: showXmlFieldNames ? 'dauer_in_wochen' : 'Dauer in Wochen',
        value: product?.lessonUnitsWeek
          ? formatNumber(product.lessonUnitsWeek)
          : '-',
      },
      {
        label: showXmlFieldNames ? 'zeiteinsatz' : 'Zeiteinsatz (Voll-/Teilzeit)',
        value: getEnumValue(ExecutionFormMeinNow, product?.executionForm),
      },
      {
        label: showXmlFieldNames ? 'flexible_start' : 'Laufender Einstieg',
        value: getEnumValue(YesNoMapping, product?.flexibleStart),
      },
    ];
    return durationInfo;
  }

  function getTopicInfo() {
    const topicInfo = [
      {
        label: showXmlFieldNames ? 'systematik_fvalue' : 'Thema ID',
        value: product?.systematics?.systematikId || '-',
      },
      {
        label: showXmlFieldNames ? 'systematik_fname' : 'Thema über Suchworte',
        value: product?.systematics?.systematikName || '-',
      },
    ];
    return topicInfo;
  }

  function getKeywordsInfo() {
    const keywordsInfo = [
      {
        label: showXmlFieldNames ? 'keywords' : 'Keywords',
        value: product?.keywords || '-',
      },
      // {
      //   label: showXmlFieldNames ? 'keywords' : 'Unterthema: Social Media',
      //   value: product?.keywords || '-',
      // },
      // {
      //   label: showXmlFieldNames ? 'keywords' : 'Bereich: Social Media',
      //   value: product?.keywords || '-',
      // },
    ];
    return keywordsInfo;
  }

  function getContentInfo() {
    const contentInfo = [
      {
        label: showXmlFieldNames ? 'Bildungsinhalte (angepasst)' : 'Bildungsinhalte (angepasst)',
        value: product?.descriptionNewplan || '-',
      },
      {
        label: showXmlFieldNames ? 'target_group' : 'Zielgruppe',
        value: product?.targetGroup || '-',
      },
      {
        label: showXmlFieldNames ? 'requirements' : 'Anforderungen',
        value: product?.requirements || '-',
      },
    ];
    return contentInfo;
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          {isLoading ? (
            <SkeletonCard sectionCount={4} />
          ) : (
            <GenericInfosCard
              title="Allgemein"
              infos={getGeneralInfo()}
            ></GenericInfosCard>
          )}
        </Col>
        <Col lg={6}>
          {isLoading ? (
            <SkeletonCard sectionCount={4} />
          ) : (
            <GenericInfosCard
              title="Art"
              infos={getTypeInfo()}
            ></GenericInfosCard>
          )}
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          {isLoading ? (
            <SkeletonCard sectionCount={4} />
          ) : (
            <GenericInfosCard
              title="Abschluss"
              infos={getDegreeInfo()}
            ></GenericInfosCard>
          )}
        </Col>
        <Col lg={6}>
          {isLoading ? (
            <SkeletonCard sectionCount={4} />
          ) : (
            <GenericInfosCard
              title="Dauer"
              infos={getDurationInfo()}
            ></GenericInfosCard>
          )}
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          {isLoading ? (
            <SkeletonCard sectionCount={4} />
          ) : (
            <GenericInfosCard
              title="Thema"
              infos={getTopicInfo()}
            ></GenericInfosCard>
          )}
        </Col>
        <Col lg={6}>
          {isLoading ? (
            <SkeletonCard sectionCount={4} />
          ) : (
            <GenericInfosCard
              title="Keywords"
              infos={getKeywordsInfo()}
            ></GenericInfosCard>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {isLoading ? (
            <SkeletonCard sectionCount={4} />
          ) : (
            <GenericInfosCard
              title="Inhalte"
              infos={getContentInfo()}
            ></GenericInfosCard>
          )}
        </Col>
      </Row>
    </>
  );
};

export default KursnetDetails;
