import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { PermissionsEnum } from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import { Task } from '../../interfaces';
import { usePermissions } from '../../utils/hooks/usePermissions';
import TaskDetails from '../../components/tasks/detail/TaskDetails';

function TaskDetail() {
  const { taskId } = useParams();
  const [task, setTask] = useState<Task | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();

  /* 
 * useEffect to check permissions and fetch task details when component mounts or taskId changes.
 * If the user lacks permissions, they are redirected to a 404 page.
 */
  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Tasks, 'read');

      if (hasPermission) {
        taskId && fetchTask(taskId, 'today');
      } else {
        navigate('/errors/error404');
      }
    }
  }, [taskId, permissionsLoaded]);


  /* 
 * Fetch task details from the API or use mock data.
 * Optionally includes a timeframe for fetching specific logs.
 */
  const fetchTask = async (taskId: string, timeframe?: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/tasks/${taskId}`);
      const settingResponse = response.data;
      setTask(settingResponse);
      setIsLoading(false);
    } catch (error) { }
  };

  /* 
 * Refetching the task details after an update.
 */
  const handleModalSubmit = () => {
    setTask(null)
    taskId && fetchTask(taskId, 'today');
  };

  /* 
 * Handle changes in the timeframe for displaying task logs.
 */
  const handleTimeframeChange = (timeframe: string) => {
    taskId && fetchTask(taskId, timeframe);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <TaskDetails
                task={task}
                isLoading={isLoading}
                onSubmitSuccess={handleModalSubmit}
                onTimeframeChange={handleTimeframeChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default TaskDetail;
