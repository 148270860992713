import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { faEnvelope, faPhone, faCopy } from '@fortawesome/pro-regular-svg-icons';
import InfoDisplay, { RowConfig } from '../../InfoDisplay';
import { AccountId, FieldConfig, Location } from '../../../interfaces';
import SkeletonRows from '../../SkeletonRows';
import { formatDateWithoutTime, formatEuro, getFieldConfigByResourceName } from '../../../utils/utils';
import { getEnumValue, PermissionsEnum } from '../../../utils/enum';
import StatusIndicator from '../../StatusIndicator';
import { usePermissions } from '../../../utils/hooks/usePermissions';

type LocationOverviewProps = {
    location?: Location;
    fieldConfigs: FieldConfig[]
    accounts?: AccountId
};

/**
 * LocationOverview Component
 * 
 * This component displays a comprehensive overview of location details across several categories, 
 * such as contractual information, contact details, rent information, termination terms, property 
 * information, and relevant links. It uses the `InfoDisplay` component to render each category in 
 * a structured and readable format. While data is loading, `SkeletonRows` placeholders are shown 
 * in place of each category’s content.
 * 
 * Props:
 * - `location`: The `Location` object containing various details to display.
 * - `fieldConfigs`: Array of field configuration objects used to dynamically render certain fields.
 * - `accounts`: Account information associated with the location.
 * 
 * Example usage:
 * ```tsx
 * <LocationOverview location={locationData} fieldConfigs={fieldConfigs} accounts={accountData} />
 * ```
 */
const LocationOverview: React.FC<LocationOverviewProps> = ({ location, fieldConfigs, accounts }) => {
    const { userHasPermissionByRight } = usePermissions();

    const canViewProperty = userHasPermissionByRight(PermissionsEnum.LocationsProperty, 'read');
    const canViewContactPerson = userHasPermissionByRight(PermissionsEnum.LocationsContactPerson, 'read');

    const contractualInfo: RowConfig[] = [
        { label: "Miet-Status", value: <StatusIndicator isActive={!!location?.contractRentalStatus}></StatusIndicator> },
        { label: "Autom. Mietverläng.", value: <StatusIndicator isActive={!!location?.contractAutoRenewal}></StatusIndicator> },
        { label: "Mietbeginn", value: location?.contractStart && location?.contractStart !== '0000-00-00 00:00:00' ? formatDateWithoutTime(location?.contractStart) : '-' },
        { label: "Mindestmietzeit", value: location?.contractMinRentalPeriod ? `${location?.contractMinRentalPeriod} Monate` : '-' },
        { label: "E-Mail (Vermieter)", value: location?.landlordEmail || '-', buttons: [{ type: 'mailto', icon: faEnvelope }, { type: 'copy', icon: faCopy }] },
    ];

    const contactInfo: RowConfig[] = [
        { label: "Name", value: location?.landlordContactPerson || '-', },
        { label: "E-Mail-Adresse", value: location?.email || '-', buttons: [{ type: 'mailto', icon: faEnvelope }, { type: 'copy', icon: faCopy }] },
        { label: "Festnetz Telefonnr.", value: location?.telephone || '-', buttons: [{ type: 'call', icon: faPhone }, { type: 'copy', icon: faCopy }] },
        { label: "Fax", value: location?.fax || '-', buttons: [{ type: 'copy', icon: faCopy }] },
        { label: "Mobile Telefonnr.", value: location?.mobile || '-', buttons: [{ type: 'call', icon: faPhone }, { type: 'copy', icon: faCopy }] },
    ];

    const rentInfo: RowConfig[] = [
        { label: "Miete, monatlich", value: <>{formatEuro(location?.costsMonthlyNet ?? 0)} (Netto) <br></br> {formatEuro(location?.costsMonthlyGross ?? 0)} (Brutto)</> },
        { label: "Umsatzsteuerpflichtig", value: getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'locations.taxDuty')?.options ?? {}, location?.taxDuty) },
    ];

    const terminationInfo: RowConfig[] = [
        { label: "Kündigungsfrist", value: location?.contractNoticePeriod ? `${location?.contractNoticePeriod} Monate` : '-' },
        { label: "Datum der Kündigung", value: location?.contractDateOfTermination && location?.contractDateOfTermination !== '0000-00-00 00:00:00' ? formatDateWithoutTime(location?.contractDateOfTermination) : '-' },
        { label: "Vertragsende", value: location?.contractEnd && location?.contractEnd !== '0000-00-00 00:00:00' ? formatDateWithoutTime(location?.contractEnd) : '-' },
    ];

    const propertyInfo: RowConfig[] = [
        { label: "Fläche", value: location?.area ? `${location?.area} m2` : '-' },
        { label: "Teilnehmer mögl.", value: location?.maxParticipants ? `${location?.maxParticipants}` : '-' },
        { label: "Schlüssel", value: getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'locations.keyType')?.options ?? {}, location?.keyType.toString()) },
        { label: "Offizielle Rettungspl.", value: <StatusIndicator isActive={!!location?.rescuePlan} activeText='Vorhanden' inactiveText='Nicht Vorhanden'></StatusIndicator> },
        { label: "Feuerlöscher", value: <StatusIndicator isActive={!!location?.fireExtinguisher} activeText='Vorhanden' inactiveText='Nicht Vorhanden'></StatusIndicator> },
        { label: "Fluchtwegbeschilder.", value: <StatusIndicator isActive={!!location?.escapeRouteSigns} activeText='Vorhanden' inactiveText='Nicht Vorhanden'></StatusIndicator> },
        { label: "Verbandskasten", value: <StatusIndicator isActive={!!location?.firstAidKit} activeText='Vorhanden' inactiveText='Nicht Vorhanden'></StatusIndicator> },
    ];

    const linksInfo: RowConfig[] = [
        { label: "Google Business URL", value: location?.googleBusiness || '-' },
        { label: "NewPlan Account", value: getEnumValue(accounts ?? {}, location?.accountId) },
    ];

    return (
        <Row>
            <Col md={6} >
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Vertragliches' config={contractualInfo} />
                        )}

                    </Card.Body>
                </Card>
            </Col>

            {canViewContactPerson && <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Ansprechpartner' config={contactInfo} />
                        )}
                    </Card.Body>
                </Card>
            </Col>}

            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Miete' config={rentInfo} />
                        )}
                    </Card.Body>
                </Card>
            </Col>

            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Kündigung' config={terminationInfo} />
                        )}
                    </Card.Body>
                </Card>
            </Col>

            {canViewProperty && <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Immobilie' config={propertyInfo} />
                        )}
                    </Card.Body>
                </Card>
            </Col>}


            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Verknüpfungen' config={linksInfo} />
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default LocationOverview;
