import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Spinner,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import {
  EducationType,
  InstitutionType,
  PermissionsEnum,
} from '../../../utils/enum';
import { Product } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import SelectWithSearch, { SelectOption } from '../../form/SelectWithSearch';
import useCompanyNavigate from '../../../utils/hooks/useCompanyNavigate';
import DeleteModal from '../../DeleteModal';
import ArchiveModal from '../../ArchiveModal';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import Card from '../../bootstrap/card';
import { handleInputChange } from '../../../utils/form/utils';

type AddEditProductPublicationProps = {
  modalTitle: string;
  product?: Product;
  onSubmitSuccess: (productId: number) => void;
  onModalClose: () => void;
};

interface FormValues {
  abschlussart: string;
  institution: string;
  educationType: string;
  massnahmeArt: string;
  descriptionNewplan: string;
  targetGroup: string;
  requirements: string;
  keywords: string
}

const AddEditProductPublicationModal: React.FC<
  AddEditProductPublicationProps
> = ({ modalTitle, onModalClose, product, onSubmitSuccess }) => {
  const companyNavigate = useCompanyNavigate()
  const { userHasPermissionByRight } = usePermissions();
  const { show, message, error, showToast, hideToast } = useToast();
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const { productId } = useParams<{ productId?: string }>();
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState({
    title: product?.title ?? '',
    name: product?.name ?? '',
    teachingForm: product?.teachingForm ?? '',
    abschlussart: product?.abschlussart ?? '',
    actionPlan: product?.actionPlan ?? '',
    admissionThird: product?.admissionThird ?? '',
    bdks: product?.bdks,
    bdks_id: product?.bdks_id ?? null,
    systematics: product?.systematics,
    systematics_id: product?.systematics_id ?? '',
    calculatedCostRate: product?.calculatedCostRate ?? '',
    certShortDescription: product?.certShortDescription ?? '',
    costsPerParticipants: product?.costsPerParticipants ?? '',
    degreeExamType: product?.degreeExamType ?? '',
    degreeTitle: product?.degreeTitle ?? '',
    degreeType: product?.degreeType ?? '',
    descriptionLong: product?.descriptionLong ?? '',
    descriptionNewplan: product?.descriptionNewplan ?? '',
    educationContent: product?.educationContent ?? '',
    educationContentGenerateNew: product?.educationContentGenerateNew ?? '',
    educationPdfFileUrl: product?.educationPdfFileUrl ?? '',
    educationPlan: product?.educationPlan ?? '',
    educationType: product?.educationType ?? '',
    executionForm: product?.executionForm ?? '',
    fieldCheckCertificate: product?.fieldCheckCertificate ?? '',
    fieldCheckEducation: product?.fieldCheckEducation ?? '',
    fieldCheckNewplan: product?.fieldCheckNewplan ?? '',
    flexibleStart: product?.flexibleStart ?? '',
    formOfExecution: product?.formOfExecution ?? '',
    genPdf: product?.genPdf ?? '',
    industryBranch: product?.industryBranch ?? '',
    institution: product?.institution ?? '',
    keywords: product?.keywords ?? '',
    lessonUnits: product?.lessonUnits ?? '',
    lessonUnitsMonth: product?.lessonUnitsMonth ?? '',
    lessonUnitsWeek: product?.lessonUnitsWeek ?? '',
    massnahmeArt: product?.massnahmeArt ?? '',
    mimeSourceAngebotUrl: product?.mimeSourceAngebotUrl ?? '',
    npDegreeType: product?.npDegreeType ?? '',
    npDescription: product?.npDescription ?? '',
    npTitle: product?.npTitle ?? '',
    numberOfParticipants: product?.numberOfParticipants ?? '',
    pidIdent: product?.pidIdent ?? '',
    requirements: product?.requirements ?? '',
    sortGroup: product?.sortGroup ?? '',
    status: product?.status ?? 2,
    statusCertification: product?.statusCertification ?? '',
    statusKursnet: product?.statusKursnet ?? '',
    targetGroup: product?.targetGroup ?? '',
    teachingThird: product?.teachingThird ?? '',
    titleMassnahme: product?.titleMassnahme ?? '',
    type: product?.type ?? '',
    typeOfMeasure: product?.typeOfMeasure ?? '',
    ue: product?.ue ?? '',
    keywordTopic: '',
    keywordSubtopic: '',
    keywordArea: '',
    certificate_id: product?.certificate_id ?? null,
    certificate: product?.certificate
  });

  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        let response;
        if (productId) {
          response = await ApiClient.put(`/products/${productId}`, formValues);
        } else {
          response = await ApiClient.post('/products', formValues);
        }
        const productsId = response.data.id;
        onSubmitSuccess(productsId);
        setFormValues(response.data);
        setInitialFormValues(response.data);
        showToast('Erfolgreich gespeichert', false);
      } catch (error) {
        showToast('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        onModalClose()
        setValidated(false);
      }
    }
  };

  const handleSelectChange = (
    id: string,
    selectedOption: { value: string; label: string } | null
  ) => {
    setFormValues((prev: any) => ({
      ...prev,
      [id]: selectedOption ? selectedOption.value : '',
    }));
  };


  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false,
    placeholder = ''
  ) => {
    const commonProps = {
      value: type !== 'file' ? formValues[id] : '',
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
        handleInputChange(e, setFormValues),
      required: required,
      isInvalid: validated && !formValues[id],
    };
  
    return (
      <Form.Group className="mb-3 w-100" controlId={String(id)}>
        <Form.Label style={{ color: 'black' }}>{label}</Form.Label>
        {type === 'textarea' ? (
          <Form.Control
            as="textarea"
            {...commonProps}
            style={{
              backgroundColor: '#F9F9F9',
              color: 'black',
              height: '150px',
            }}
            placeholder={placeholder}
          />
        ) : (
          <Form.Control
            type={type}
            {...commonProps}
            style={{ backgroundColor: '#F9F9F9', color: 'black' }}
          />
        )}
        <Form.Control.Feedback type="invalid" />
      </Form.Group>
    );
  };

  const createSelectGroup = (
    id: keyof FormValues,
    label: string,
    options: SelectOption[],
    placeholder?: string,
    required = false,
    disabled: boolean = false
  ) => (
    <Form.Group
      style={{ color: 'black' }}
      className="mb-3 w-100"
      controlId={id}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        disabled={disabled}
        value={formValues[id]}
        onChange={(e) => handleInputChange(e, setFormValues)}
        required={required}
        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  const handleDeleteItem = (message: string, isError: boolean) => {
    showToast(message, isError)
    onModalClose()
    companyNavigate('/products')
  }

  return (
    <>
      <Modal
        centered
        show
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button"
                style={{ padding: 0 }}
              >
                <span className="visually-hidden">More options</span>
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                {product && product?.status === 3 && <DeleteModal
                  modalTitle="Auswahl löschen?"
                  buttonName="Löschen"
                  entityIds={[product?.id!]}
                  entityType='products'
                  onSubmit={handleDeleteItem}
                  onDeleteModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'delete')}>
                </DeleteModal>}

                {product && product?.status !== 3 && <ArchiveModal
                  modalTitle="Auswahl archivieren?"
                  buttonName="Archivieren"
                  entityIds={[product?.id!]}
                  entityType='products'
                  onSubmit={() => onSubmitSuccess(product?.id!)}
                  onArchiveModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'delete')}>
                </ArchiveModal>}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submit}>
            <Row>
              <Col lg={12}>
                <Card className='card-block card-stretch card-height'>
                  <Card.Body>
                    <h5>Art</h5>
                    <Row>
                      <Col lg={6}>                    {createFormGroup(
                        'abschlussart',
                        'Abschlussart',
                        'text',
                        true
                      )}
                        {createSelectGroup(
                          'educationType',
                          'Bildungsart',
                          Object.entries(EducationType).map(([value, label]) => ({
                            value,
                            label,
                          })),
                          'Auswählen...',
                          true
                        )}</Col>

                      <Col lg={6}>                    {createSelectGroup(
                        'institution',
                        'Schulart',
                        Object.entries(InstitutionType).map(([value, label]) => ({
                          value,
                          label,
                        })),
                        'Auswählen...',
                        false,
                        false
                      )}

                        {createFormGroup(
                          'massnahmeArt',
                          'Art der Maßnahme',
                          'text',
                          true
                        )}

                        <SelectWithSearch
                          id="systematics_id"
                          label="Systematiken"
                          placeholder="Suche..."
                          apiEndpoint="/systematics"
                          onChange={handleSelectChange}
                          initialValue={formValues?.systematics?.id ? { value: formValues.systematics.id, label: formValues.systematics.title } : null}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card className='card-block card-stretch card-height'>
                  <Card.Body>
                    <h5>Inhalte</h5>
                    {createFormGroup(
                      'descriptionNewplan',
                      'Bildungsinhalte (angepasst)',
                      'textarea',
                      false,
                      'Falls leer, wird der allgemeine Zielgruppen Text verwendet.'
                    )}

                    <p>
                      Mit diesem Feld kann der Bildungsinhalt optional für
                      Newplan angepasst werden. Falls nicht, wird der
                      Bildungsinhalt aus dem Reiter Bildungsinhalte
                      verwendet.
                    </p>

                    {createFormGroup(
                      'targetGroup',
                      'Zielgruppe (angepasst)',
                      'textarea',
                      false,
                      'Falls leer, werden die Bildungsinhalte aus dem Reiter Bildungsinhalte übernommen.'
                    )}
                    <p>
                      Falls leer, wird der allgemeine Zielgruppen Text
                      verwendet.
                    </p>

                    {createFormGroup(
                      'requirements',
                      'Anforderungen (angepasst)',
                      'textarea',
                      false,
                      'Falls leer, wird der allgemeine Anforderungen Text verwendet.'
                    )}
                    <p>
                      Falls leer, wird der allgemeine Anforderungen Text
                      verwendet.
                    </p>

                    {createFormGroup(
                      'keywords',
                      'Keywords',
                      'textarea',
                    )}
                  </Card.Body>
                </Card>
              </Col>

            </Row>
            <Button
              type="submit"
              style={{ display: 'none' }}
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditProductPublicationModal;
