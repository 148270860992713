import React from 'react';
import InfoDisplay, { RowConfig } from '../../InfoDisplay';
import { FieldConfig, Location, Partner } from '../../../interfaces';
import { getEnumValue } from '../../../utils/enum';
import { formatDateWithoutTime, getFieldConfigByResourceName } from '../../../utils/utils';
import SkeletonRows from '../../SkeletonRows';

interface LocationDetailsProps {
    location?: Location;
    partner?: Partner;
    fieldConfigs?: FieldConfig[]
}

/**
 * LocationDetails Component
 * 
 * This component displays detailed information about a location, including fields like title, address, partner, 
 * creation date, and modification date. It uses the `InfoDisplay` component to render each field as a row, 
 * with a loading state that displays a series of skeleton rows when the location data is not yet available.
 * 
 * Props:
 * - `location`: The `Location` object containing the location details to display.
 * - `partner`: The `Partner` object providing partner-related data for the location.
 * 
 * Example usage:
 * ```tsx
 * <LocationDetails location={locationData} partner={partnerData} />
 * ```
 */
const LocationDetails: React.FC<LocationDetailsProps> = ({ location, partner, fieldConfigs }) => {
    const locationDetails: RowConfig[] = [
        {
            label: 'Titel',
            value: <>{location?.street} {location?.streetNr}</>,
        },
        {
            label: 'Büro-Art',
            value: getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'locations.officeType')?.options ?? {}, location?.officeType.toString()),
        },
        {
            label: 'Adresse',
            value: <>{location?.street} {location?.streetNr}, <br /> {location?.zipcode} {location?.city}</>,
        },
        {
            label: 'Partner',
            value: `${getEnumValue(partner ?? {}, location?.partner)}`,
        },
        {
            label: 'Fläche',
            value: location?.area ? `${location?.area} m2` : '-',
        },
        {
            label: 'Teilnehmer',
            value: location?.maxParticipants ? `${location?.maxParticipants} Teilnehmer möglich` : '-',
        },
        {
            label: 'Standort-Ident',
            value: location?.lidIdent ? location?.lidIdent : '-',
        },
        {
            label: 'Finanzen-Ident',
            value: location?.finIdent ? location?.finIdent : '-',
        },
        {
            label: 'Erstellt am',
            value: `${formatDateWithoutTime(location?.created ?? '')}`,
        },
        {
            label: 'Zul. bearb.',
            value: `${formatDateWithoutTime(location?.modified ?? '')}`,
        },
        {
            label: 'Revision',
            value: location?.version,
        },
    ];

    return (
        <>
            {!location ? (
                <SkeletonRows count={9} />
            ) : (
                <InfoDisplay config={locationDetails} />
            )}
        </>
    );
};

export default LocationDetails;
