import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '../services/context/ToastContext';

type ButtonConfig = {
  type: 'copy' | 'mailto' | 'call';
  action?: () => void;
  icon: IconDefinition;
};

export type RowConfig = {
  label: React.ReactNode | string;
  value: React.ReactNode | string;
  buttons?: ButtonConfig[];
};

type InfoDisplayProps = {
  config: RowConfig[];
  title?: string;
};

/**
 * InfoDisplay Component
 * 
 * This component renders a configurable display of information rows, with each row displaying 
 * a label, value, and optional action buttons. It is ideal for presenting structured data where 
 * each field has additional actions such as copying to the clipboard, emailing, or calling.
 * 
 * Props:
 * - `config`: An array of `RowConfig` objects, each defining a label, value, and optional action buttons.
 * - `title`: Optional title displayed above the list of information rows.
 * 
 * RowConfig:
 * - `label`: The label or title of the row, describing the field being displayed.
 * - `value`: The content of the row, showing the value associated with the label.
 * - `buttons`: Optional array of `ButtonConfig` objects that define additional actions (e.g., copy, email, call).
 * 
 * ButtonConfig:
 * - `type`: Specifies the action type (`copy`, `mailto`, or `call`).
 * - `action`: Custom function to handle the button click if the standard types are not used.
 * - `icon`: Icon displayed on the button.
 * 
 * Example usage:
 * ```tsx
 * const config = [
 *   { label: "Email", value: "user@example.com", buttons: [{ type: 'mailto', icon: faEnvelope }] },
 *   { label: "Phone", value: "+1234567890", buttons: [{ type: 'call', icon: faPhone }] },
 *   { label: "Copyable Text", value: "Some text", buttons: [{ type: 'copy', icon: faCopy }] }
 * ];
 * 
 * <InfoDisplay config={config} title="User Information" />
 * ```
 */
const InfoDisplay: React.FC<InfoDisplayProps> = ({ config, title }) => {
  return (
    <div>
      {title && <h5 className="mb-3">{title}</h5>}
      {config.map((fieldConfig, index) => (
        <DetailRow key={index} {...fieldConfig} />
      ))}
    </div>
  );
};

const DetailRow: React.FC<RowConfig> = ({ label, value, buttons }) => {
  const { showToast } = useToast();

  const handleButtonClick = (button: ButtonConfig) => {
    if (button.type === 'copy') {
      navigator.clipboard.writeText(value as string);
      showToast(`In Zwischenablage kopiert`, false);
    } else if (button.type === 'mailto') {
      window.location.href = `mailto:${value}`;
    } else if (button.type === 'call') {
      window.location.href = `tel:${value}`;
    } else if (button.action) {
      button.action();
    }
  };

  return (
    <Row className="mb-3 justify-content-between flex-wrap">
      <Col xs={12} lg={4} className="mb-1 mb-lg-0">
        {label}
      </Col>
      <Col xs={12} lg={6} className="text-black mb-1 mb-lg-0">
        {value}
      </Col>
      <Col xs='auto' lg={2} className="d-flex justify-content-end">
        <div className="d-flex gap-2">
          {buttons &&
            buttons.map((button, index) => (
              <Button
                disabled={value === "" || value === '-'}
                key={index}
                variant="link"
                className="p-0"
                onClick={() => handleButtonClick(button)}
                title={button.type === 'copy' ? 'Kopieren' : button.type === 'mailto' ? 'Email schreiben' : 'Anrufen'}
              >
                <FontAwesomeIcon icon={button.icon} />
              </Button>
            ))}
        </div>
      </Col>
    </Row>
  );
};

export default InfoDisplay;
