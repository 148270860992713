import {
    faCloud,
    faCloudExclamation,
    faCloudSlash,
    faCircle,
    faCircleCheck,
    faCircleDashed,
    faBoxArchive,
    IconDefinition
} from '@fortawesome/pro-light-svg-icons';
import { faHighlighter, faEye, } from '@fortawesome/pro-solid-svg-icons';
import { faFrown, faGrin, faMeh, faSadTear, faSmile } from '@fortawesome/pro-solid-svg-icons';

export const statusCertificationProductsIconMap: { [key: number]: IconDefinition } = {
    0: faCircle,
    1: faCircle,
    2: faCircleDashed,
    3: faCircleCheck,
};

export const statusCertificationProductsColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-danger',
    2: 'text-warning',
    3: 'text-success',
};

export const statusCertificationLocationsIconMap: { [key: number]: IconDefinition } = {
    0: faCircle,
    1: faCircle,
    2: faCircle,
    3: faCircleDashed,
    4: faCircleCheck,
    5: faCircle,
};

export const statusCertificationLocationsColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-danger',
    2: 'text-danger',
    3: 'text-warning',
    4: 'text-success',
    5: 'text-danger'
};

export const statusIconMap: { [key: number]: IconDefinition } = {
    0: faCloudSlash,
    1: faCloud,
    2: faCloudExclamation,
    3: faBoxArchive,
};

export const statusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-success',
    2: 'text-warning',
    3: 'text-muted',
};

export const productTypeIconMap: { [key: number]: IconDefinition } = {
    1: faEye,
    2: faHighlighter,
};

export const productTypeColorMap: { [key: number]: string } = {
    1: 'text-success',
    2: 'text-info',
};

export const smileyMap: Record<number, IconDefinition> = {
    1: faSadTear,
    2: faFrown,
    3: faMeh,
    4: faSmile,
    5: faGrin,
};

export const smileyColorMap: Record<number, string> = {
    1: '#FF6B6B',
    2: '#FFC107',
    3: '#FFEB3B',
    4: '#8BC34A',
    5: '#4CAF50',
};