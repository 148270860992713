import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface RadialBarChartProps {
  value: number;
  value1: number;
  value2: number;
  label1: string
  label2: string
}

const RadialBarChart: React.FC<RadialBarChartProps> = ({ value, value1, value2, label1, label2 }) => {
  const options: ApexOptions = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: 'rgba(0,0,0,0.125)',
          startAngle: -135,
          endAngle: 135,
        },
        hollow: {
          size: '70%',
        },
        dataLabels: {
          total: {
            show: true,
            label: value1.toString(),
            formatter: () => value2.toString(),
            //color: '#1aa053',
            fontSize: '40px',
          },

        },
      },
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      style: {
        fontSize: '16px',
        fontFamily: undefined,

      },
      y: {
        formatter: (val: number) => {
          return `${value1} ${label1}${!label2 ? '' : ', ' + value2 + ' ' + label2}`;
        },
        title: {
          formatter: () => '',
        },
      },
    },
    colors: ['#2e45b9'],
    labels: [value1.toString()],
  };

  const series = [value]; // Dynamic data

  return <Chart options={options} series={series} type="radialBar" height={330} />;
};

export default RadialBarChart;
