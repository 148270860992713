import { useEffect } from 'react';
import useCompanyNavigate from './useCompanyNavigate';
import { useToast } from '../../services/context/ToastContext';

const useErrorHandling = (error: any, fallbackRoute: string) => {
    const companyNavigate = useCompanyNavigate();
    const { showToast } = useToast();

    useEffect(() => {
        if (error) {
            if (error.response && error.response.status === 404) {
                showToast('Element nicht gefunden', true);
                companyNavigate(fallbackRoute);
            } else {
                console.error('An error occurred:', error);
            }
        }
    }, [error, fallbackRoute, companyNavigate, showToast]);
};

export default useErrorHandling;
