import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GenericInfosCard from '../../products/GenericInfosCard';
import { Systematic, } from '../../../interfaces';

import SkeletonCard from '../../locations/skeleton/SkeletonCard';

interface SystematicDetailsProps {
    isLoading?: boolean;
    systematic?: Systematic | null
    onSubmitSuccess: (systematicId: number) => void;
}

const SystematicDetails: React.FC<SystematicDetailsProps> = ({
    isLoading,
    systematic,
    onSubmitSuccess
}) => {
    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: systematic?.title || '-',
            },
            {
                label: 'Systematik ID',
                value: systematic?.systematikId || '-',
            },
            {
                label: 'Systematik Name',
                value: systematic?.systematikName || '-',
            },
            {
                label: 'Kursnet-ID',
                value: systematic?.newPlanId || '-',
            },
            {
                label: 'Sichtbarkeit',
                value: systematic?.visibility || '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Level 0 - ID',
                value: systematic?.level0 || '-',
            },
            {
                label: 'Level 0 - Name',
                value: systematic?.level0Name || '-',
            },
            {
                label: 'Level 1 - ID',
                value: systematic?.level1 || '-',
            },
            {
                label: 'Level 1 - Name',
                value: systematic?.level1Name || '-',
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{systematic?.title}</h4>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default SystematicDetails;
