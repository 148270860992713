import React from 'react';
import { Row, Col } from 'react-bootstrap';
import NestedAccordion, { ContentItem } from '../../NestedAccordion';
import { Product } from '../../../interfaces';
import SkeletonCard from '../../locations/skeleton/SkeletonCard';

interface EducationalContentsProps {
  isLoading?: boolean;
  product?: Product;
}

const EducationalContents: React.FC<EducationalContentsProps> = ({
  isLoading,
  product,
}) => {
  const contentItems = product?.educationContent
    ? ((product.educationContent) as ContentItem[])
    : [];

  const groupItemsIntoRows = (items: ContentItem[]) => {
    const rows = [];
    for (let i = 0; i < items.length; i += 2) {
      rows.push(items.slice(i, i + 2));
    }
    return rows;
  };

  const contentRows = groupItemsIntoRows(contentItems);

  return (
    <>
      {contentRows.length ? (
        contentRows.map((row, rowIndex) => (
          <Row key={rowIndex}>
            {row.map((content: ContentItem, colIndex: number) => (
              <Col className="mt-3" lg={6} key={colIndex}>
                <NestedAccordion contentItem={content} />
              </Col>
            ))}
          </Row>
        ))
      ) : (
        <Row>
          <div
            className="d-flex justify-content-center align-items-center border rounded mt-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Noch keine Bildungsinhalte eingetragen</p>
          </div>
        </Row>
      )}
    </>
  );
};

export default EducationalContents;
