import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GenericInfosCard from '../products/GenericInfosCard';
import { CounterIdents, Setting } from '../../interfaces';
import SkeletonCard from '../locations/skeleton/SkeletonCard';

interface CounterIdentsDetailsProps {
    isLoading?: boolean;
    setting?: Setting | null
    onSubmitSuccess: (settingsId: number) => void;
}

const CounterIdentsDetails: React.FC<CounterIdentsDetailsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const counter = setting?.details as CounterIdents
    function getInfo1() {
        const info1 = [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Letzte Zahl für LidIdent',
                value: counter?.lidIdent || '-',
            },
            {
                label: 'Letzte Zahl für PidIdent',
                value: counter?.pidIdent || '-',
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default CounterIdentsDetails;
