
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export type ComboButtonId = string;

export interface ComboButton {
  id: ComboButtonId;
  label: string;
  icon?: IconDefinition;
  disabled?: boolean;
  disabledText?: string;
  error?: boolean;
  type?: string;
}

interface ComboButtonGroupProps {
  buttons: ComboButton[];
  selectedCombo: ComboButtonId | '';
  setSelectedCombo: (value: ComboButtonId | '') => void;
  borderRadius?: 'pill' | 'normal';
}

/**
 * ComboButtonGroup Component
 * 
 * This component renders a group of radio-style buttons with customizable styling and functionality.
 * Each button in the group can display an icon, a label, and handle different types (such as "success", "abort", etc.)
 * to change its style dynamically. Buttons can also be disabled with a tooltip explaining the reason.
 * 
 * Props:
 * - `buttons`: An array of `ComboButton` objects, each defining the button's id, label, icon, type, 
 *   and optional disabled or error states.
 * - `selectedCombo`: The currently selected button's id.
 * - `setSelectedCombo`: Function to set the selected button's id, updating the state externally.
 * - `borderRadius`: Defines the border radius style for the buttons, either "pill" or "normal".
 * 
 * Example usage:
 * ```tsx
 * <ComboButtonGroup 
 *   buttons={[
 *     { id: 'btn1', label: 'Button 1', type: 'success' },
 *     { id: 'btn2', label: 'Button 2', disabled: true, disabledText: 'Unavailable' },
 *     { id: 'btn3', label: 'Button 3', type: 'abort', error: true }
 *   ]}
 *   selectedCombo="btn1"
 *   setSelectedCombo={(value) => console.log(value)}
 *   borderRadius="pill"
 * />
 * ```
 */
const ComboButtonGroup: React.FC<ComboButtonGroupProps> = ({
  buttons,
  selectedCombo,
  setSelectedCombo,
  borderRadius = 'pill',
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>
  ) => {
    if ('target' in event && event.target instanceof HTMLInputElement) {
      setSelectedCombo(event.target.id);
    }
  };

  const getButtonClass = (id: ComboButtonId, type?: string) => {
    let classes = 'btn mb-2 me-2 btn-sm';
    if (borderRadius === 'pill') {
      classes += ' rounded-pill';
    } else {
      classes += ' rounded';
    }
    if (selectedCombo === id) {
      if (type === 'success') {
        classes += ' custom-success-badge';
      } else if (type === 'abort') {
        classes += ' custom-danger-badge';
      } else {
        classes += ' btn-soft-primary';
      }
    }

    return classes;
  };

  const renderButton = ({ id, label, icon, disabled, disabledText, error, type }: ComboButton) => {
    const button = (
      <React.Fragment key={id}>
        <input
          type="radio"
          className="d-none"
          name="btnradio"
          id={id}
          autoComplete="off"
          onChange={handleChange}
          onClick={handleChange}
          disabled={disabled}
        />
        <label
          style={{ fontSize: '12px' }}
          className={`${getButtonClass(id, type)} mb-2 me-2 btn-sm ${borderRadius === 'pill' ? 'rounded-pill' : 'rounded'
            } ${error && 'text-danger'} ${type === 'success' && 'text-success'} ${type === 'abort' && 'text-danger'} ${type === 'warning' && 'text-primary'}`}
          htmlFor={id}
        >
          {icon && <FontAwesomeIcon icon={icon} className='me-2'></FontAwesomeIcon>}
          {label}
        </label>
      </React.Fragment>
    );

    if (disabled && disabledText) {
      return (
        <OverlayTrigger
          key={id}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-disabled-${id}`}>{disabledText}</Tooltip>
          }
        >
          <span style={{ cursor: 'not-allowed' }}>{button}</span>
        </OverlayTrigger>
      );
    }

    return button;
  };

  return <div className="scrollable-container">{buttons.map((button) => renderButton(button))}</div>;
};

export default ComboButtonGroup;