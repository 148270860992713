import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GenericInfosCard from '../GenericInfosCard';
import { Product } from '../../../interfaces';
import {
  ExecutionForm,
  FormOfExecution,
  IndustryBranch,
  TeachingForms,
  TypeOfMeasure,
  YesNoMapping,
  getEnumValue,
} from '../../../utils/enum';
import { formatEuro, formatNumber } from '../../../utils/utils';
import SkeletonCard from '../../locations/skeleton/SkeletonCard';

interface CertificationDetailsProps {
  isLoading?: boolean;
  product?: Product;
}

const CertificationDetails: React.FC<CertificationDetailsProps> = ({
  isLoading,
  product,
}) => {
  function getGeneralInfo() {
    const generalInfo = [
      { label: 'Titel', value: product?.title || '-' },
      {
        label: 'Inhalte Kurzform',
        value: product?.certShortDescription || '-',
      },
      {
        label: 'Abschluss',
        value: product?.degreeType || '-',
      },
      {
        label: 'Wirtschaftszweig',
        value: getEnumValue(IndustryBranch, product?.industryBranch),
      },
      {
        label: 'Art der Maßnahme',
        value: getEnumValue(TypeOfMeasure, product?.typeOfMeasure),
      },
      {
        label: 'Zertifizierungssystematik',
        value: `${product?.bdks ? (product?.bdks?.bdksId + ' | ' + product?.bdks?.title) : '-'}`
      },
      {
        label: 'Maßnahmen-ID',
        value: product?.measureCode || '-',
      },
      {
        label: 'Bildungsziel gemäß KidB210',
        value: product?.bdks?.title || '-',
      },
    ];
    return generalInfo;
  }

  function getScopeInfo() {
    const generalInfo = [
      {
        label: 'Dauer',
        value: product?.lessonUnits
          ? `${product?.lessonUnits
          } UE, bei ${product?.lessonUnitsDay ? product?.lessonUnitsDay : "-"} UE pro Tag <br/> = ${formatNumber(
            product.lessonUnitsWeek!
          )} Wochen <br/> = ${formatNumber(product.lessonUnitsMonth!)} Monate`
          : '-',
        isMultiline: true,
      },
      {
        label: 'Durchführungsform',
        value: getEnumValue(ExecutionForm, product?.executionForm),
      },
      {
        label: 'Laufender Einstieg',
        value: getEnumValue(YesNoMapping, product?.flexibleStart),
      },
      {
        label: 'Unterrichtsform',
        value: getEnumValue(TeachingForms, product?.teachingForm),
      },
      {
        label: 'Durchführungsform (Maßnahme)',
        value: getEnumValue(FormOfExecution, product?.formOfExecution),
      },
      {
        label: 'Teilnehmeranzahl',
        value: product?.numberOfParticipants || '-',
      },
    ];
    return generalInfo;
  }

  function getCostInfo() {
    const generalInfo = [
      { label: 'B-DKS', value: product?.bdks ? formatEuro(product.bdks.costs) : '-' },
      {
        label: 'Angepasster B-DKS',
        value: product?.calculatedCostRate
          ? formatEuro(product.calculatedCostRate)
          : '-',
      },
      {
        label: 'Kalkulierter Kostensatz',
        value: product?.calculatedCostRate
          ? `${formatEuro(product.calculatedCostRate)} pro UE`
          : '-',
      },
      {
        label: 'Kosten pro Teilnehmer',
        value: product?.costsPerParticipants
          ? formatEuro(product?.costsPerParticipants)
          : '-',
      },
    ];
    return generalInfo;
  }

  function getAdditionalInfo() {
    const generalInfo = [
      {
        label: 'Einsatz von Dritten für Unterricht',
        value: getEnumValue(YesNoMapping, product?.teachingThird),
      },
      {
        label: 'Zulassungen/Berechtigungen Dritter erforderlich',
        value: getEnumValue(YesNoMapping, product?.admissionThird),
      },
    ];
    return generalInfo;
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          {isLoading ? (
            <SkeletonCard sectionCount={4} />
          ) : (
            <GenericInfosCard
              title="Allgemein"
              infos={getGeneralInfo()}
            ></GenericInfosCard>
          )}
        </Col>
        <Col lg={6}>
          {isLoading ? (
            <SkeletonCard sectionCount={4} />
          ) : (
            <GenericInfosCard
              title="Umfang"
              infos={getScopeInfo()}
            ></GenericInfosCard>
          )}
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          {isLoading ? (
            <SkeletonCard sectionCount={4} />
          ) : (
            <GenericInfosCard
              title="Kosten"
              infos={getCostInfo()}
            ></GenericInfosCard>
          )}
        </Col>
        <Col lg={6}>
          {isLoading ? (
            <SkeletonCard sectionCount={4} />
          ) : (
            <GenericInfosCard
              title="Sonstiges"
              infos={getAdditionalInfo()}
            ></GenericInfosCard>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CertificationDetails;
