import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown, Form, Button } from 'react-bootstrap';

interface ExportDropdownProps {
  onDownloadClick: (checkboxStates: {
    suitabilityTable: boolean;
    contract: boolean;
    images: boolean;
  }) => void;
}

/**
 * LocationExportDropdown Component
 * 
 * This component renders a dropdown menu for selecting specific types of documents to download.
 * Users can select from options like "Eignungstabelle," "Vertrag," and "Bilder" by checking the respective checkboxes,
 * and then click "Herunterladen" to initiate the download process for the selected document types.
 * 
 * Props:
 * - `onDownloadClick`: A callback function that is triggered when the "Herunterladen" button is clicked.
 *    It receives an object with the current checkbox states for `suitabilityTable`, `contract`, and `images`, indicating
 *    which document types are selected for download.
 */

const LocationExportDropdown: React.FC<ExportDropdownProps> = ({ onDownloadClick }) => {
  const [suitabilityTableChecked, setSuitabilityTableChecked] = useState(false);
  const [contractChecked, setContractChecked] = useState(false);
  const [imagesChecked, setImagesChecked] = useState(false);

  const handleDownloadClick = () => {
    onDownloadClick({
      suitabilityTable: suitabilityTableChecked,
      contract: contractChecked,
      images: imagesChecked,
    });
  };

  return (
    <Dropdown
      className="me-2 rounded"
    >
      <Dropdown.Toggle
        variant="secondary"
        id="dropdown-basic"
        className="btn-soft-primary dropdown-no-arrow h-100 py-2 px-3"
      >
        <FontAwesomeIcon icon={faDownload} />
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ minWidth: '200px' }}>
        <Dropdown.ItemText>
          <p className="mb-0" style={{ fontSize: '0.7rem' }}>
            DOKUMENTE HERUNTERLADEN
          </p>
        </Dropdown.ItemText>
        <Dropdown.ItemText>
          <Form.Check
            type="checkbox"
            id="checkbox-suitability"
            label="Eignungstabelle"
            checked={suitabilityTableChecked}
            onChange={(e) => setSuitabilityTableChecked(e.target.checked)}
          />
        </Dropdown.ItemText>
        <Dropdown.ItemText>
          <Form.Check
            type="checkbox"
            id="checkbox-contract"
            label="Vertrag"
            checked={contractChecked}
            onChange={(e) => setContractChecked(e.target.checked)}
          />
        </Dropdown.ItemText>
        <Dropdown.ItemText>
          <Form.Check
            type="checkbox"
            id="checkbox-images"
            label="Bilder"
            checked={imagesChecked}
            onChange={(e) => setImagesChecked(e.target.checked)}
          />
        </Dropdown.ItemText>
        <Dropdown.Divider />
        <Dropdown.ItemText>
          <Button disabled variant="primary" onClick={handleDownloadClick}>
            Herunterladen
          </Button>
        </Dropdown.ItemText>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LocationExportDropdown;
