import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import PortalWrapper from './PortalWrapper';

interface CertificationStatusDropdownProps<T extends string | number> {
  statusCertification: T;
  handleCertificationStatusUpdate: (status: T) => void;
  statusEnum: { [key in T]?: string };
  iconMap?: { [key: string]: IconDefinition };
  colorMap?: { [key: string]: string };
  isDisabled?: boolean;
  className?: string;
}

const CertificationStatusDropdown = <T extends string | number>({
  statusCertification,
  handleCertificationStatusUpdate,
  statusEnum,
  iconMap = {},
  colorMap = {},
  isDisabled,
  className,
}: CertificationStatusDropdownProps<T>) => {
  const getIcon = (key: T) =>
    iconMap[key as string] && <FontAwesomeIcon icon={iconMap[key as string]} className={`me-2 ${getColorClass(key)}`} />;
  const getColorClass = (key: T) => colorMap[key as string] || '';

  return (
    <Dropdown className={`ps-2 me-2 rounded ${className || ''}`}>
      <Dropdown.Toggle
        disabled={isDisabled}
        variant="link"
        id="dropdown-certification-basic"
        className={`${getColorClass(statusCertification)} ps-0`}
      >
        {getIcon(statusCertification)}
        {statusEnum[statusCertification]}
      </Dropdown.Toggle>

      <PortalWrapper>
        <Dropdown.Menu>
          {Object.entries(statusEnum).map(([key, value]) => {
            const typedKey = key as T;
            const typedValue = value as string;
            if (typedKey === statusCertification) return null;

            return (
              <Dropdown.Item
                key={key}
                onClick={() => handleCertificationStatusUpdate(typedKey)}
              >
                {getIcon(typedKey)}
                {typedValue}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </PortalWrapper>
    </Dropdown>
  );
};

export default CertificationStatusDropdown;
