import React, { ReactNode, useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { copyToClipboard } from '../../utilities/dom';
import { useToast } from '../../services/context/ToastContext';

type InfoValue = any

interface Info {
  label: string;
  value: InfoValue;
  isMultiline?: boolean;
  isImage?: boolean;
  firstColumnSize?: number
  isLink?: boolean
  isCopyToClipboard?: boolean
}

interface GenericInfosCardProps {
  imageSrc?: string;
  title?: string;
  infos: Info[];
  showHorizontalLine?: boolean;
  cardHeight?: boolean;
  children?: ReactNode;
  firstColumnSize?: number
}

const GenericInfosCard: React.FC<GenericInfosCardProps> = ({
  imageSrc,
  title,
  infos,
  showHorizontalLine = false,
  cardHeight = true,
  children,
  firstColumnSize = 4,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false)
  }, [imageSrc]);

  const imageSkeleton = (
    <div className="skeleton-loader skeleton-image mb-3" style={{ height: '200px' }}></div>
  );

  return (
    <Card className={`card-block card-stretch ${cardHeight && 'card-height'}`}>
      {children}
      <Card.Body>

        {imageSrc && <>
          {!imageLoaded && imageSkeleton}
          <img
            className="img-fluid w-100 mb-3 rounded generic-info-card-image"
            src={imageSrc}
            alt={title || ''}
            style={{ display: imageLoaded ? 'block' : 'none' }}
            onLoad={() => setImageLoaded(true)}
          />
        </>
        }
        {title && <h5 className="mb-3">{title}</h5>}
        {showHorizontalLine && <div className="horizontal-line my-3"></div>}
        {infos.map((info, index) => (
          <DetailRow
            key={index}
            label={info.label}
            value={info.value}
            isMultiline={info.isMultiline}
            isImage={info.isImage}
            firstColumnSize={firstColumnSize}
            isLink={info.isLink}
            isCopyToClipboard={info.isCopyToClipboard}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

interface DetailRowProps extends Info { }

const DetailRow: React.FC<DetailRowProps> = ({
  label,
  value,
  isMultiline = false,
  isImage = false,
  firstColumnSize,
  isLink,
  isCopyToClipboard
}) => {
  const [showFullText, setShowFullText] = useState<boolean>(false);
  const maxLength = 100;
  const { show, message, error, showToast, hideToast } = useToast();

  interface Section {
    heading: string;
    items: string[];
  }

  const parseContent = (content: string): Section[] => {
    // Split content by sections using '##' and handle newlines
    const sections = content.split(/##|\n{2,}/).filter(Boolean);
    
    return sections.map((section) => {
      // Split each section into a heading and items using '#', and also handle newlines within sections
      const parts = section.split('#').filter(Boolean);
      const heading = parts.shift()!.trim(); // Get heading and remove extra spaces
      const items = parts.map(item => item.trim().split('\n').filter(Boolean)); // Split items by newlines and clean up
      return { heading, items: items.flat() }; // Flatten nested arrays in case of multiple newlines
    });
  };

  const handleCopy = (string: string) => {
    copyToClipboard(string);
    showToast(`In Zwischenablage kopiert`, false);
  };

  const renderValue = (value: InfoValue) => {
    if (typeof value === 'string' && value.includes('#')) {
      const content = parseContent(value);
      const firstTwoItems = content.slice(0, 2);
      const marginBottom = content.length === 1 ? 'mb-3' : 'mb-1'

      if (value.length > maxLength && !showFullText) {
        return (
          <div className="text-black">
            {firstTwoItems.map((section, index) => (
              <div key={index} className='mb-3'>
                <p className={`p-0 ${marginBottom}`}>{section.heading}</p>
                {section.items.length > 0 && (
                  <ul className="pb-0 mb-0">
                    {section.items.map((item, itemIndex) => (
                      <li key={itemIndex}>{item}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
            <div className="text-end">
              <Button
                size="sm"
                variant="link p-0 m-0"
                onClick={() => setShowFullText(true)}
              >
                Mehr Anzeigen
              </Button>
            </div>
          </div>
        );
      } else {
        return (
          <div className="text-black">
            {content.map((section, index) => (
              <div key={index} className='mb-3'>
                <p className={`p-0 ${marginBottom}`}>{section.heading}</p>
                {section.items.length > 0 && (
                  <ul className="pb-0 mb-0">
                    {section.items.map((item, itemIndex) => (
                      <li key={itemIndex}>{item}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        );
      }
    } else if (typeof value === 'string' && isMultiline) {
      return (
        <p
          className="text-black text-wrap text-break"
          dangerouslySetInnerHTML={{ __html: value }}
        ></p>
      );
    }
    else if (typeof value === 'string' && isLink) {
      return (
        <a href={value.toString()} target="_blank" rel="noreferrer" className='text-black text-decoration-underline' style={{ color: 'inherit' }}>
          {value}
        </a>
      );
    } else {
      if (
        typeof value === 'string' &&
        value.length > maxLength &&
        !showFullText
      ) {
        return (
          <>
            <p className="text-black text-wrap text-break">
              {value.substring(0, maxLength)}{' '}
              <Button
                size="sm"
                variant="link p-0 m-0 align-baseline"
                onClick={() => setShowFullText(true)}
              >
                Mehr Anzeigen
              </Button>
            </p>
          </>
        );
      } else {
        return <p className="text-black text-wrap text-break">{value}</p>;
      }
    }
  };


  return (
    <Row className="mb-2">
      <Col lg={firstColumnSize}>
        <p className="font-weight-bold">{label}</p>
      </Col>

      {isImage ? (
        <Col>
          <div className='border rounded p-2'>

            <img width='auto' height={70} src={value}></img>
          </div>

        </Col>
      ) : (
        <Col className={isCopyToClipboard ? 'cursor-pointer' : ''} onClick={() => isCopyToClipboard && handleCopy(value)} >{renderValue(value)}</Col>
      )}
    </Row>
  );
};

export default GenericInfosCard;
