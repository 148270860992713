import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { MeinNowAccounts, PartnerNames, Setting } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import Card from '../../bootstrap/card';
import { handleInputChange } from '../../../utils/form/utils';

type PartnerNamesModalProps = {
    modalTitle: string;
    setting?: Setting;
    onSubmitSuccess: (settingsId: number) => void;
    onModalClose: () => void;
};

interface FormValues {
    title: string;
    description: string;
    ident: string;
    details: MeinNowAccounts;
}

const PartnerNamesModal: React.FC<PartnerNamesModalProps> = ({
    modalTitle,
    setting,
    onSubmitSuccess,
    onModalClose
}) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newPartner, setNewPartner] = useState('');

    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const [formValues, setFormValues] = useState<FormValues>({
        title: setting?.title ?? '',
        description: setting?.description ?? '',
        ident: setting?.ident ?? '',
        details: (setting?.details as PartnerNames) ?? {}
    });

    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                const response = await ApiClient.put(
                    `/settings/${setting?.id}`,
                    prepareFormDataForSubmit(formValues)
                );
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    const prepareFormDataForSubmit = (formValues: FormValues) => {
        return {
            title: formValues.title,
            ident: formValues.ident,
            description: formValues.description,
            details: formValues.details
        };
    };

    const handleDetailsChange = (
        id: string,
        value: string
    ) => {
        setFormValues((prev: any) => ({
            ...prev,
            details: {
                ...prev.details,
                [sanitizeInput(id)]: value
            }
        }));
    };

    const sanitizeInput = (input: string): string => {
        return input.toLowerCase().replace(/[^a-z0-9]/g, '_');
    };

    const handleAddPartner = () => {
        if (newPartner.trim() !== '' && !formValues.details.hasOwnProperty(sanitizeInput(newPartner))) {
            setFormValues((prev: any) => ({
                ...prev,
                details: {
                    ...prev.details,
                    [sanitizeInput(newPartner)]: newPartner
                }
            }));
            setNewPartner('');
        } else {
            showToast('Es muss eine eindeutiger Partner angegeben werden', true);
        }
    };

    const handleRemovePartner = (id: string) => {
        setFormValues((prev: any) => {
            const updatedDetails = { ...prev.details };
            delete updatedDetails[id];
            return { ...prev, details: updatedDetails };
        });
    };
    const createFormGroup = (
        id: keyof FormValues,
        label: string,
        type = 'text',
        required = false,
        disabled = false
    ) => (
        <Form.Group className="mb-3 w-100" controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}

            {type === 'textarea' ? (
                <Form.Control
                    as="textarea"
                    rows={20}
                    type={type}
                    value={id === 'details' ? JSON.stringify(formValues[id], null, 2) : formValues[id]}
                    onChange={(e) => handleInputChange(e, setFormValues)}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            ) : (
                <Form.Control
                    type={type}
                    value={id === 'details' ? JSON.stringify(formValues[id], null, 2) : formValues[id]}
                    onChange={(e) => handleInputChange(e, setFormValues)}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            )}

            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        </Form.Group>
    );

    return (
        <>
            <Modal
                centered
                show
                onHide={onModalClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            {' '}
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button"
                                style={{ padding: 0 }}
                            >
                                <span className="visually-hidden">More options</span>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <>

                            <Row>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            <h5>Allgemein</h5>
                                            {createFormGroup('title', 'Titel', 'text', true, true)}
                                            {createFormGroup('description', 'Beschreibung', 'text', false)}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            <h5>Partnernamen</h5>
                                            {Object.keys(formValues.details).map(id => (
                                                <Row key={id} className="mb-3">
                                                    <Col>
                                                        <Form.Control
                                                            type="text"
                                                            value={formValues.details[id]}
                                                            onChange={(e) => handleDetailsChange(id, e.target.value)}
                                                            className="text-black bg-grey"
                                                        />
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => handleRemovePartner(id)}
                                                        >
                                                            <FontAwesomeIcon icon={faXmark} />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Neuen Partner eingeben"
                                                        value={newPartner}
                                                        onChange={(e) => setNewPartner(e.target.value)}
                                                        className="text-black bg-grey"
                                                    />
                                                </Col>
                                                <Col xs="auto">
                                                    <Button variant="secondary" onClick={handleAddPartner}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default PartnerNamesModal;
