import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { GlobalDataCompany, Setting } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import Card from '../../bootstrap/card';
import { handleInputChange } from '../../../utils/form/utils';

type EditGlobalDataCompaniesModalProps = {
    modalTitle: string;
    setting?: Setting;
    onSubmitSuccess: (settingsId: number) => void;
    onModalClose: () => void;
};

interface FormValues {
    title: string;
    description: string;
    ident: string;
    documentCreatorSalutation: string;
    documentCreatorFirstName: string;
    documentCreatorLastName: string;
    documentCreatorPhone: string;
    documentCreatorEmail: string;
    documentCreatorAddressName: string;
    documentCreatorAddressStreet: string;
    documentCreatorAddressZip: string;
    documentCreatorAddressCity: string;
    documentCreatorAddressState: string;
    documentCreatorAddressUrl: string;
    companyId: string;
    supplierKeyword: string;
    supplierName: string;
    supplierAddressName: string;
    supplierAddressStreet: string;
    supplierAddressZip: string;
    supplierAddressCity: string;
    supplierAddressState: string;
    supplierAddressPhone: string;
    supplierAddressEmail: string;
    supplierAddressFax: string;
    supplierAddressUrl: string;
    organizationFormType: string;
    organizationForm: string;
    contactSalutation: string;
    contactFirstName: string;
    contactLastName: string;
    contactPhone: string;
    contactEmail: string;
}

const EditGlobalDataCompaniesModal: React.FC<EditGlobalDataCompaniesModalProps> = ({
    modalTitle,
    setting,
    onSubmitSuccess,
    onModalClose
}) => {
    const globalDataCompany = setting?.details as GlobalDataCompany

    const { show, message, error, showToast, hideToast } = useToast();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const [formValues, setFormValues] = useState({
        title: setting?.title ?? '',
        description: setting?.description ?? '',
        ident: setting?.ident ?? '',
        documentCreatorSalutation: globalDataCompany?.documentCreatorSalutation ?? '',
        documentCreatorFirstName: globalDataCompany?.documentCreatorFirstName ?? '',
        documentCreatorLastName: globalDataCompany?.documentCreatorLastName ?? '',
        documentCreatorPhone: globalDataCompany?.documentCreatorPhone ?? '',
        documentCreatorEmail: globalDataCompany?.documentCreatorEmail ?? '',
        documentCreatorAddressName: globalDataCompany?.documentCreatorAddressName ?? '',
        documentCreatorAddressStreet: globalDataCompany?.documentCreatorAddressStreet ?? '',
        documentCreatorAddressZip: globalDataCompany?.documentCreatorAddressZip ?? '',
        documentCreatorAddressCity: globalDataCompany?.documentCreatorAddressCity ?? '',
        documentCreatorAddressState: globalDataCompany?.documentCreatorAddressState ?? '',
        documentCreatorAddressUrl: globalDataCompany?.documentCreatorAddressUrl ?? '',
        companyId: globalDataCompany?.companyId ?? '',
        supplierKeyword: globalDataCompany?.supplierKeyword ?? '',
        supplierName: globalDataCompany?.supplierName ?? '',
        supplierAddressName: globalDataCompany?.supplierAddressName ?? '',
        supplierAddressStreet: globalDataCompany?.supplierAddressStreet ?? '',
        supplierAddressZip: globalDataCompany?.supplierAddressZip ?? '',
        supplierAddressCity: globalDataCompany?.supplierAddressCity ?? '',
        supplierAddressState: globalDataCompany?.supplierAddressState ?? '',
        supplierAddressPhone: globalDataCompany?.supplierAddressPhone ?? '',
        supplierAddressEmail: globalDataCompany?.supplierAddressEmail ?? '',
        supplierAddressFax: globalDataCompany?.supplierAddressFax ?? '',
        supplierAddressUrl: globalDataCompany?.supplierAddressUrl ?? '',
        organizationFormType: globalDataCompany?.organizationFormType ?? '',
        organizationForm: globalDataCompany?.organizationForm ?? '',
        contactSalutation: globalDataCompany?.contactSalutation ?? '',
        contactFirstName: globalDataCompany?.contactFirstName ?? '',
        contactLastName: globalDataCompany?.contactLastName ?? '',
        contactPhone: globalDataCompany?.contactPhone ?? '',
        contactEmail: globalDataCompany?.contactEmail ?? '',
    });


    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                const response = await ApiClient.put(
                    `/settings/${setting?.id}`,
                    prepareFormDataForSubmit(formValues)
                );
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    const prepareFormDataForSubmit = (formValues: FormValues) => {
        return {
            title: formValues.title,
            ident: formValues.ident,
            description: formValues.description,
            details: {
                documentCreatorSalutation: formValues.documentCreatorSalutation,
                documentCreatorFirstName: formValues.documentCreatorFirstName,
                documentCreatorLastName: formValues.documentCreatorLastName,
                documentCreatorPhone: formValues.documentCreatorPhone,
                documentCreatorEmail: formValues.documentCreatorEmail,
                documentCreatorAddressName: formValues.documentCreatorAddressName,
                documentCreatorAddressStreet: formValues.documentCreatorAddressStreet,
                documentCreatorAddressZip: formValues.documentCreatorAddressZip,
                documentCreatorAddressCity: formValues.documentCreatorAddressCity,
                documentCreatorAddressState: formValues.documentCreatorAddressState,
                documentCreatorAddressUrl: formValues.documentCreatorAddressUrl,
                companyId: formValues.companyId,
                supplierKeyword: formValues.supplierKeyword,
                supplierName: formValues.supplierName,
                supplierAddressName: formValues.supplierAddressName,
                supplierAddressStreet: formValues.supplierAddressStreet,
                supplierAddressZip: formValues.supplierAddressZip,
                supplierAddressCity: formValues.supplierAddressCity,
                supplierAddressState: formValues.supplierAddressState,
                supplierAddressPhone: formValues.supplierAddressPhone,
                supplierAddressEmail: formValues.supplierAddressEmail,
                supplierAddressFax: formValues.supplierAddressFax,
                supplierAddressUrl: formValues.supplierAddressUrl,
                organizationFormType: formValues.organizationFormType,
                organizationForm: formValues.organizationForm,
                contactSalutation: formValues.contactSalutation,
                contactFirstName: formValues.contactFirstName,
                contactLastName: formValues.contactLastName,
                contactPhone: formValues.contactPhone,
                contactEmail: formValues.contactEmail,
            }
        };
    }

    const createFormGroup = (
        id: keyof FormValues,
        label: string,
        type = 'text',
        required = false,
        disabled = false
    ) => (
        <Form.Group className="mb-3 w-100" controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}

            {type === 'textarea' ? (
                <Form.Control
                    as="textarea"
                    rows={5}
                    type={type}
                    value={formValues[id]}
                    onChange={(e) => handleInputChange(e, setFormValues)}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            ) : (
                <Form.Control
                    type={type}
                    value={type !== 'file' ? formValues[id] : ''}
                    onChange={(e) => handleInputChange(e, setFormValues)}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            )}

            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        </Form.Group>
    );

    return (
        <>
            <Modal
                centered
                show
                onHide={onModalClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            {' '}
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button"
                                style={{ padding: 0 }}
                            >
                                <span className="visually-hidden">More options</span>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <>

                            <Row>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            {createFormGroup('title', 'Titel', 'text', true, true)}
                                            {createFormGroup('description', 'Beschreibung', 'text', false)}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            {createFormGroup('organizationFormType', 'Organisation (Formtyp)', 'text', true)}
                                            {createFormGroup('organizationForm', 'Organisation (Form)', 'text', true)}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>


                            <Row>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>

                                            {createFormGroup('companyId', 'Anbieter (ID)', 'text', true)}
                                            {createFormGroup('supplierKeyword', 'Anbieter (Keyword)', 'text', true)}
                                            {createFormGroup('supplierName', 'Anbieter (Name)', 'text', true)}
                                            {createFormGroup('supplierAddressName', 'Anbieter (Anschrift)', 'text', true)}
                                            {createFormGroup('supplierAddressStreet', 'Anbieter (Straße + Hausnummer)', 'text', true)}
                                            {createFormGroup('supplierAddressZip', 'Anbieter (PLZ)', 'text', true)}
                                            {createFormGroup('supplierAddressCity', 'Anbieter (Stadt)', 'text', true)}
                                            {createFormGroup('supplierAddressState', 'Anbieter (Bundesland)', 'text', true)}
                                            {createFormGroup('supplierAddressPhone', 'Anbieter (Telefon)', 'text', true)}
                                            {createFormGroup('supplierAddressEmail', 'Anbieter (E-Mail Adresse)', 'text', true)}
                                            {createFormGroup('supplierAddressFax', 'Anbieter (Faxnummer)', 'text', true)}
                                            {createFormGroup('supplierAddressUrl', 'Anbieter (URL)', 'text', true)}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            {createFormGroup('documentCreatorSalutation', 'Dokumentenersteller (Anrede)', 'text', true)}
                                            {createFormGroup('documentCreatorFirstName', 'Dokumentenersteller (Vorname)', 'text', true)}
                                            {createFormGroup('documentCreatorLastName', 'Dokumentenersteller (Nachname)', 'text', true)}
                                            {createFormGroup('documentCreatorPhone', 'Dokumentenersteller (Telefonnummer)', 'text', true)}
                                            {createFormGroup('documentCreatorEmail', 'Dokumentenersteller (E-Mail Adresse)', 'text', true)}
                                            {createFormGroup('documentCreatorAddressName', 'Dokumentenersteller (Anschrift - Name)', 'text', true)}
                                            {createFormGroup('documentCreatorAddressStreet', 'Dokumentenersteller (Anschrift - Straße + Hausnummer)', 'text', true)}
                                            {createFormGroup('documentCreatorAddressZip', 'Dokumentenersteller (Anschrift - PLZ)', 'text', true)}
                                            {createFormGroup('documentCreatorAddressCity', 'Dokumentenersteller (Anschrift - Stadt)', 'text', true)}
                                            {createFormGroup('documentCreatorAddressState', 'Dokumentenersteller (Anschrift - Bundesland)', 'text', true)}
                                            {createFormGroup('documentCreatorAddressUrl', 'Dokumentenersteller (Anschrift - URL)', 'text', true)}

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            {createFormGroup('contactSalutation', 'Kontakt (Anrede)', 'text', true)}
                                            {createFormGroup('contactFirstName', 'Kontakt (Vorname)', 'text', true)}
                                            {createFormGroup('contactLastName', 'Kontakt (Nachname)', 'text', true)}
                                            {createFormGroup('contactPhone', 'Kontakt (Telefon)', 'text', true)}
                                            {createFormGroup('contactEmail', 'Kontakt (E-Mail Adresse)', 'text', true)}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                        </>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default EditGlobalDataCompaniesModal;
