import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import GenericInfosCard from '../../products/GenericInfosCard';
import { Certificate, } from '../../../interfaces';

import SkeletonCard from '../../locations/skeleton/SkeletonCard';
import { formatDateWithoutTime } from '../../../utils/utils';
import AddEditCertificateModal from '../modal/AddEditCertificateModal';
import { formatSizeUnits, getImageUrl } from '../../../utils/media/utils';
import Card from '../../bootstrap/card';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { PermissionsEnum } from '../../../utils/enum';

interface CertificateDetailsProps {
    isLoading?: boolean;
    certificate?: Certificate | null
    onSubmitSuccess: (certificateId: number) => void;
}

const CertificateDetails: React.FC<CertificateDetailsProps> = ({
    isLoading,
    certificate,
    onSubmitSuccess
}) => {
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const { userHasPermissionByRight } = usePermissions();

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: certificate?.title || '-',
            },
            {
                label: 'Zertifikatsnummer',
                value: certificate?.number || '-',
            },
            {
                label: 'Zertifizierer',
                value: certificate?.certifier || '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Datum der Zulassung',
                value: certificate?.admission ? formatDateWithoutTime(certificate?.admission) : '-',
            },
            {
                label: 'Zulassungszeitraum',
                value: certificate?.approvalPeriodStart && certificate?.approvalPeriodEnd ?
                    `${formatDateWithoutTime(certificate?.approvalPeriodStart)} - ${formatDateWithoutTime(certificate?.approvalPeriodEnd)}`
                    : '-',
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{certificate?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.Certificates, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Allgemein"
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title="Daten"
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <Card>
                        <Card.Body>
                            <h5 className="mb-3">Zertifikatsdatei</h5>
                            {
                                certificate?.media ? <>
                                    <a href={`/${localStorage.getItem('companyId') || 'oc'}/media/${certificate.media.folder_id}/${certificate.media.id}`} target="_blank" rel="noreferrer" className='text-black' style={{ color: 'inherit' }}>
                                        <div className="d-flex justify-content-between align-items-center border rounded p-2 media-picker-preview-wrapper">
                                            <div className="media-info d-flex align-items-center">
                                                <img
                                                    src={getImageUrl(certificate.media)}
                                                    alt={`Preview of ${certificate.media.title}`}
                                                    className='media-picker-preview-image'
                                                />
                                                <div>
                                                    <p className='mb-1 text-black' style={{ margin: 0 }}>{certificate.media.title}</p>
                                                    <span>
                                                        {certificate.media.width > 0 && certificate.media.height > 0 && (
                                                            <>{certificate.media.width} x {certificate.media.height} - </>
                                                        )}
                                                        {certificate.media.size && formatSizeUnits(certificate.media.size)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </>
                                    : '-'
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {certificate && showAddEditModal && <AddEditCertificateModal
                modalTitle={`${certificate.title} bearbeiten`}
                certificate={certificate}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}

            >
            </AddEditCertificateModal>
            }
        </>
    );
};

export default CertificateDetails;
