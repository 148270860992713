import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PortalWrapper from '../PortalWrapper';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { de } from 'date-fns/locale';

registerLocale('de', de);

interface DateRangeDropdownFilterProps {
    onDateRangeChange: (startDate: Date | null, endDate: Date | null) => void;
    isDisabled?: boolean;
    titlePlaceholder?: string;
    initialStartDate?: Date | null;
    initialEndDate?: Date | null;
}

// This component provides a dropdown for selecting a date range (start and end date) using date pickers.
// It displays the selected range in the button and allows clearing/resetting of the dates. 
// The component ensures that the start date cannot be later than the end date. 
// The selected range is passed to the parent via `onDateRangeChange`. 
// German locale is used for date formatting, and the dropdown can be disabled with `isDisabled`.
const DateRangeDropdownFilter: React.FC<DateRangeDropdownFilterProps> = ({
    onDateRangeChange,
    isDisabled,
    titlePlaceholder = 'Zeitraum',
    initialStartDate = null,
    initialEndDate = null,
}) => {
    const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
    const [endDate, setEndDate] = useState<Date | null>(initialEndDate);
    const [tempStartDate, setTempStartDate] = useState<Date | null>(initialStartDate);
    const [tempEndDate, setTempEndDate] = useState<Date | null>(initialEndDate);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false);
    const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false);

    useEffect(() => {
        setStartDate(initialStartDate);
        setEndDate(initialEndDate);
        setTempStartDate(initialStartDate);
        setTempEndDate(initialEndDate);
    }, [initialStartDate, initialEndDate]);

    const handleReset = (event: React.MouseEvent) => {
        event.stopPropagation();
        setTempStartDate(null);
        setTempEndDate(null);
        setStartDate(null)
        setEndDate(null)
        onDateRangeChange(null, null);
        setDropdownOpen(false)
    };

    const handleApply = () => {
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
        onDateRangeChange(tempStartDate, tempEndDate);
        setDropdownOpen(false);
    };

    const handleStartDateChange = (date: Date | null) => {
        if (date && tempEndDate && date > tempEndDate) {
            setTempEndDate(null);
        }
        setTempStartDate(date);
        setIsStartDatePickerOpen(false);
    };

    const handleEndDateChange = (date: Date | null) => {
        if (date && tempStartDate && date < tempStartDate) {
            setTempStartDate(null);
        }
        setTempEndDate(date);
        setIsEndDatePickerOpen(false);
    };

    const formatDateString = (date: Date | null) => {
        return date instanceof Date ? date.toLocaleDateString('de-DE') : '';
    };

    const title = startDate
        ? endDate
            ? `${formatDateString(startDate)} - ${formatDateString(endDate)}`
            : `${formatDateString(startDate)}`
        : titlePlaceholder;

    return (
        <Dropdown className='' show={dropdownOpen} onToggle={setDropdownOpen}>
            <Dropdown.Toggle
                disabled={isDisabled}
                variant="outline-primary"
                id="dropdown-date-range"
                className="custom-dropdown-focus me-2"
                onClick={() => setDropdownOpen(!dropdownOpen)}
            >
                {startDate && (
                    <FontAwesomeIcon icon={faTimes} className="me-2 cursor-pointer" onClick={handleReset} />
                )}
                {title}
            </Dropdown.Toggle>
            <PortalWrapper>
                <Dropdown.Menu>
                    <div className="px-3 py-2">
                        <div className="d-flex flex-column">
                            <label>Startdatum</label>
                            <DatePicker
                                selected={tempStartDate || undefined}
                                onChange={handleStartDateChange}
                                selectsStart
                                startDate={tempStartDate || undefined}
                                endDate={tempEndDate || undefined}
                                isClearable={!!tempStartDate && true}
                                placeholderText="Startdatum auswählen"
                                className="form-control"
                                locale="de"
                                dateFormat="dd.MM.yyyy"
                                showWeekNumbers
                                weekLabel="KW"
                                open={isStartDatePickerOpen}
                                onClickOutside={() => setIsStartDatePickerOpen(false)}
                                onInputClick={() => setIsStartDatePickerOpen(!isStartDatePickerOpen)}
                            />
                        </div>
                        <div className="d-flex flex-column mt-3">
                            <label>Enddatum</label>
                            <DatePicker
                                selected={tempEndDate || undefined}
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={tempStartDate || undefined}
                                endDate={tempEndDate || undefined}
                                minDate={tempStartDate || undefined}
                                isClearable={!!tempEndDate && true}
                                placeholderText="Enddatum auswählen"
                                className="form-control"
                                locale="de"
                                dateFormat="dd.MM.yyyy"
                                weekLabel="KW"
                                showWeekNumbers
                                showDateSelect={false}
                                open={isEndDatePickerOpen}
                                onClickOutside={() => setIsEndDatePickerOpen(false)}
                                onInputClick={() => setIsEndDatePickerOpen(!isEndDatePickerOpen)}
                            />
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <Button
                                size="sm"
                                className="btn btn-soft-primary m-2"
                                onClick={handleApply}
                                disabled={!!((tempStartDate && !tempEndDate) || (!tempStartDate && tempEndDate))}
                            >
                                Anwenden
                            </Button>
                        </div>
                    </div>
                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default DateRangeDropdownFilter;
