import React, { useState } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import ComboButtonGroup from '../ComboButtonGroup';
import AddEditOptimizationModal from './modal/AddEditOptimizationModal';
import CreateProductsWithAIModal from './modal/CreateProductsWithAIModal';

export interface Item {
  id: string;
  title: string;
  sichtbarkeit: string;
  status: 'active' | 'draft';
}

interface OptimizationCardProps {
  items: Item[];
  type: 'topic' | 'area' | 'focus';
  onSelection: (selectedItem: Item) => void;
  selectedItem?: Item;
}

const OptimizationCard: React.FC<OptimizationCardProps> = ({
  items,
  type,
  onSelection,
  selectedItem,
}) => {
  const comboButtons = [
    { id: `all_${type}`, label: 'Alle' },
    { id: `low_${type}`, label: 'Niedrig' },
    { id: `medium_${type}`, label: 'Mittel' },
    { id: `high_${type}`, label: 'Hoch' },
  ];

  const [selectedCombo, setSelectedCombo] = useState(`all_${type}`);

  const handleSelection = (item: Item) => {
    onSelection(item);
  };

  return (
    <>
      <Card className="card-stretch mb-0">
        <Card.Body className="p-0 d-flex flex-column">
          <div className="p-3 d-flex justify-content-between align-items-center">
            <ComboButtonGroup
              buttons={comboButtons}
              selectedCombo={selectedCombo}
              setSelectedCombo={setSelectedCombo}
            />

            <div className="d-flex align-items-center">
              <Button
                variant="secondary"
                className="btn-soft-primary round-button me-2"
                style={{ padding: 0, width: '25px', height: '25px' }}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>

              <AddEditOptimizationModal
                modalType="new"
                onSubmitSuccess={() => {}}
                type="area"
              ></AddEditOptimizationModal>
            </div>
          </div>

          <div style={{ flexGrow: 1, overflowY: 'auto' }}>
          <Table responsive="md" size="md" className="mb-0 sticky-header">
              <thead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
                <tr>
                  <th scope="col">
                    {type === 'topic'
                      ? 'Thema'
                      : type === 'area'
                      ? 'Bereich'
                      : 'Schwerpunkt'}
                  </th>
                  <th scope="col">Sichtbarkeit</th>
                  <th scope="col" className="p-2"></th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr
                    key={index}
                    style={{ cursor: 'pointer' }}
                    className={`${item.id === selectedItem?.id ? 'selected-row' : 'bg-white'}`}
                    onClick={() => handleSelection(item)}
                  >
                    <td className="long-text-column" style={{ maxWidth: '110px' }}>
                      <AddEditOptimizationModal
                        modalType="edit"
                        onSubmitSuccess={() => {}}
                        type={type}
                        buttonName={item.title}
                        optimisationId={item.id}
                      ></AddEditOptimizationModal>
                    </td>
                    <td>{item.sichtbarkeit}</td>
                    <td className="p-2">
                      {type === 'focus' && item.id === selectedItem?.id ? (
                        <CreateProductsWithAIModal onSubmitSuccess={() => {}}></CreateProductsWithAIModal>
                      ) : (
                        <FontAwesomeIcon icon={faChevronRight} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {type === 'area' && items?.length === 0 && (
            <div className="text-center">
              <h5 className="text-center">Bereich auswählen</h5>
              <p>Thema wählen, um Bereich zu sehen</p>
            </div>
          )}

          {type === 'focus' && items?.length === 0 && (
            <div className="text-center">
              <h5 className="text-center">Schwerpunkt auswählen</h5>
              <p>Bereich wählen, um Schwerpunkt zu sehen</p>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default OptimizationCard;
