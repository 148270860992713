import React from 'react';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import JsonViewer from './JsonViewer';
import { LogEntryTask } from '../interfaces';
import { formatDate } from '../utils/utils';
import { getEnumValue, YesNoMapping } from '../utils/enum';

interface TaskLogAccordionProps {
    logs: LogEntryTask[];
}

const TaskLogAccordion: React.FC<TaskLogAccordionProps> = ({ logs }) => {
    return (
        <Accordion>
            {logs.map((entry, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>
                        <div>
                            <h5 className="p-0 m-0 text-primary">{formatDate(entry.createdAt, 'd.m.Y - H:i:s Uhr')}</h5>
                            <Table borderless size="sm" className='m-0 p-0 text-black'>
                                <tbody>
                                    <tr>
                                        <td className="fw-bold p-0">Task ID:</td>
                                        <td className='py-1'>{entry.taskId}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold p-0">Erfolgreich:</td>
                                        <td className='py-1'>{getEnumValue(YesNoMapping, entry.isSuccessfully)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold p-0">Endzeit:</td>
                                        <td className='py-1'>{entry.endedAt ? formatDate(entry.endedAt, 'd.m.Y - H:i:s Uhr') : '-'}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className='pt-0'>
                        <Row>
                            {entry.log &&
                                <Col>
                                    <JsonViewer data={entry.log} />
                                </Col>}
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export default TaskLogAccordion;
