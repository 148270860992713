import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { useParams } from 'react-router-dom';
import { SelectOption } from '../../form/SelectWithSearch';
import { CertifierEnum } from '../../../utils/enum';
import { faWrench } from '@fortawesome/pro-light-svg-icons';
import NestableComponent from '../../NestableComponent';
import { normalizeJsonString } from '../../../utils/utils';
import { handleInputChange } from '../../../utils/form/utils';

type CreateProductsWithAIModalProps = {
    onSubmitSuccess: (settingsId: number) => void;
};

interface FormValues {
    id: string,
    keywords: string,
    title: string
}

const CreateProductsWithAIModal: React.FC<CreateProductsWithAIModalProps> = ({
    onSubmitSuccess,
}) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const [totalUes, setTotalUes] = useState(0);
    const { certificateId } = useParams<{ certificateId?: string }>();
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const [formValues, setFormValues] = useState({
        id: '',
        keywords: '',
        title: ''

    });


    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const handleEducationContentChange = (value: string) => {
        setFormValues((prev: any) => ({ ...prev, educationContent: normalizeJsonString(value) }));
    };


    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                let response;
                if (certificateId) {
                    response = await ApiClient.put(
                        `/certificates/${certificateId}`,
                        formValues
                    );
                } else {
                    response = await ApiClient.post('/certificates', formValues);
                }
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                handleClose();
                setValidated(false);
            }
        }
    };

    const createFormGroup = (
      id: keyof FormValues,
      label: string,
      type = 'text',
      required = false,
      placeholder = ''
    ) => {
      const commonProps = {
        value: type !== 'file' ? formValues[id] : '',
        onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
          handleInputChange(e, setFormValues),
        required: required,
        isInvalid: validated && !formValues[id],
      };
    
      return (
        <Form.Group className="mb-3 w-100" controlId={String(id)}>
          <Form.Label style={{ color: 'black' }}>{label}</Form.Label>
          {type === 'textarea' ? (
            <Form.Control
              as="textarea"
              {...commonProps}
              style={{
                backgroundColor: '#F9F9F9',
                color: 'black',
                height: '150px',
              }}
              placeholder={placeholder}
            />
          ) : (
            <Form.Control
              type={type}
              {...commonProps}
              style={{ backgroundColor: '#F9F9F9', color: 'black' }}
            />
          )}
          <Form.Control.Feedback type="invalid" />
        </Form.Group>
      );
    };
    
    const createSelectGroup = (
        id: keyof FormValues,
        label: string,
        options: SelectOption[],
        placeholder?: string,
        required = false
    ) => (
        <Form.Group
            style={{ color: 'black' }}
            className="mb-3 w-100"
            controlId={id}
        >
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Select
                value={formValues[id]}
                onChange={(e) => handleInputChange(e, setFormValues)}
                required={required}
                style={{ backgroundColor: '#F9F9F9', color: 'black' }}
            >
                {placeholder && <option value="">{placeholder}</option>}
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );

    return (
        <>
            <Button
                variant="secondary"
                className="btn-primary round-button"
                style={{ padding: 0, width: '25px', height: '25px', margin:0 }}
                onClick={handleShow}
            >
                <FontAwesomeIcon icon={faWrench} />
            </Button>

            <Modal
                centered
                size="xl"
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                fullscreen='xl-down'
            >
                <Modal.Header>
                    <Col lg={3}>
                        <div>
                            <Modal.Title>
                                <h4>Entwürfe</h4>
                            </Modal.Title>
                            <span>Entwurf 1 von 5</span>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <Modal.Title>
                                <h4>Entwurf überprüfen</h4>
                            </Modal.Title>
                            <span>Noch nicht freigegeben</span>
                        </div>

                    </Col>
                    <Col lg={3}>
                        <div className="d-flex justify-content-end">
                            <Button className="me-3 btn-soft-primary" variant="secondary" onClick={handleClose}>Schließen</Button>
                            <Button
                                disabled={isLoading}
                                variant="primary"
                                onClick={() => hiddenSubmitButtonRef.current?.click()}
                            >
                                Freigeben
                                {isLoading && (
                                    <Spinner
                                        className="ms-2"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></Spinner>
                                )}
                            </Button>
                        </div>
                    </Col>

                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <>
                            <Row>
                                <Col lg={3}></Col>
                                <Col>
                                    {createFormGroup(
                                        'keywords',
                                        'Keywords',
                                        'textarea',
                                    )}
                                    {createFormGroup('title', 'Titel', 'text', true)}
                                    {createFormGroup(
                                        'keywords',
                                        'Inhalte Kurzform',
                                        'textarea',
                                    )}

                                    <Form.Label className='text-black'>Module</Form.Label>

                                    <NestableComponent
                                        educationContent={[]}
                                        onTotalUesChange={setTotalUes}
                                        onItemsChange={handleEducationContentChange}
                                        cols={12}
                                    ></NestableComponent>
                                </Col>
                                <Col lg={3}>
                                    {createFormGroup('title', 'Pid-Ident', 'text', true)}
                                    {createFormGroup('title', 'Unterrichtseinheiten', 'text', true)}
                                </Col>
                            </Row>
                        </>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default CreateProductsWithAIModal;
