import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUnlock } from "@fortawesome/pro-solid-svg-icons";


export function getTaskStatusIcon(status: boolean) {
    let iconComponent;
    switch (status) {
      case false:
        iconComponent = (
          <FontAwesomeIcon
            className="me-2"
            style={{ color: 'var(--bs-danger)' }}
            icon={faLock}
          />
        );
        break;
      case true:
        iconComponent = (
          <FontAwesomeIcon
            className="me-2"
            style={{ color: 'var(--bs-success)' }}
            icon={faUnlock}
          />
        );
        break;
      default:
        break;
    }
    return iconComponent;
  }